"use strict";

var common = require("../common");
var $ = require("jquery");
var moment = require("moment");




var _class = class {
	constructor(jdp) {
		this.jdp = jdp;
	}

	get_basic_info() {
		return {
			title_sid: "106:please_wait",
			// record_type_plural: ""
		};
	}


	on_layout() {
	}

	async on_load() {
		let url = "/student/package_iec/executePaypalCheckout/" + this.jdp.args.orderNo + "/" + this.jdp.args.paymentId + "/" + this.jdp.args.PayerID;
		let data = await this.jdp.api_user.post_data_promise(url);
		let pu_id = data.puid;

		let redirect = common.build_jdp_url("checkout_package_thank_you", {pu_id: pu_id})
		console.log(redirect);
		window.location = redirect;
	}




};

module.exports = _class;
window.register_jdp((require('path')).basename(__filename, ".js"), _class);