"use strict";

var $ = require("jquery");
var ApiUser = require("../../ApiUser");
var Cookies = require("cookies-js");
var common = require("../../common");

var BootstrapDialog = require("bootstrap3-dialog");

var password_utils = require("../../../../../student-views-common/validation/password");

function ActivatePage(frontend) {
	this.state = {
		frontend: frontend
	};
}

ActivatePage.prototype.load = function(cb) {
	console.log("Loaded");

	var api_user = this.state.frontend.state.api_user;

	var args = common.get_args();
	if (!args) {
		args = {};
	}


    $("[name=username]").val(args.username);        

    let set_field_symbol = (elm, is_ok, err) => {
        if (is_ok) {
            $(elm).parent().find(".field-status").addClass("hide");
            $(elm).parent().find(".field-status-ok").removeClass("hide");
            $(elm).parent().find(".invalid-line").text("");
        } else {
            $(elm).parent().find(".field-status").addClass("hide");

            if (err) {
                $(elm).parent().find(".invalid-line").text(err);
            }
        }
    };

    let is_pw_ok = false;
    let is_confirm_ok = false;
    let is_current_ok = false;

    let maybe_enable_button = () => {
        if (is_pw_ok && is_confirm_ok && (is_current_ok || (args.variant != "first_login"))) {
            $("#change-pw-btn").removeClass("disabled");
        } else {
            $("#change-pw-btn").addClass("disabled");
        }
    }

    $("[name=password]").on("change keyup", function() {
        let is_ok = password_utils.is_password_ok($(this).val());
        set_field_symbol($(this), is_ok, password_utils.password_requirement);
        is_pw_ok = is_ok;
        maybe_enable_button();
    });

    $("[name=password], [name=confirm_password]").on("change keyup", function(){
        if ($("[name=confirm_password]").val() != "") {
            let is_ok = ($("[name=password]").val() == $("[name=confirm_password]").val());
            is_confirm_ok = is_ok;
            set_field_symbol($("[name=confirm_password]"), is_ok, nss("106:pwreset_new_password_mismatch"));
            maybe_enable_button();
        }
    });

    $("#change-pw-btn").click(e => {
        e.preventDefault();
        if ($("#change-pw-btn").hasClass("disabled")) {
            return;
        }

        api_user.post_data(
            "/auth/confirm_password_reset",
            {
                username: args.username,
                activate_uid: args.uid,
                token: args.token,
                new_password: $("[name=password]").val()
            },
            (err, res) => {

                if (err) {

                    BootstrapDialog.show({
                        message: nss(err == "already_activated" ? "pwreset_already_activated" : "106:pwreset_failed_message_activate"),
                        title: nss("106:pwreset_failed_title_activate"),
                        cssClass: "very-high-zindex",
                        type: BootstrapDialog.TYPE_WARN,
                        closable: false,
                        buttons: [
                            {
                                label: nss("106:pwreset_understood"),
                                action: function(dialogItself){
                                    dialogItself.close();
                                    // window.location = "/login";
                                    // window.location = window.location;
                                }
                            }
                        ]
                    });


                    $("#password-reset-btn").removeClass("hide");

                } else {


                    BootstrapDialog.show({
                        message: nss("106:pwreset_done_message_activate"),
                        title: nss("106:pwreset_done_title_activate"),
                        cssClass: "very-high-zindex",
                        type: BootstrapDialog.TYPE_INFO,
                        closable: false,
                        buttons: [
                            {
                                label: nss("106:pwreset_sign_in"),
                                action: function(dialogItself){
                                    dialogItself.close();
                                    window.location = "/login";
                                    // window.location = window.location;
                                }
                            }
                        ]
                    });
                }







            }
        );


    });

    $('#choose-password-modal').modal({ show: true });

	cb();
};

module.exports = ActivatePage;
