"use strict";

var $ = require("jquery");


function CoursesPage(frontend) {
	this.state = {
		frontend: frontend
	};
}

 CoursesPage.prototype.generate_linked_cell = function (text, href, is_expired, add_message_if_is_expired, is_withdrawn, add_message_if_is_withdrawn) {

	let a = $("<a/>").attr("href", href).text(text);
	if (is_expired || is_withdrawn) {
		$(a).addClass("expired-link");
	}
	let td = $("<td/>").append(a)
	if (is_expired && add_message_if_is_expired) {
		let expired = $("<span/>").text(this.state.frontend.get_string("expired")).addClass("expired-note"); 
		$(td).append(expired);
	}

	if (is_withdrawn && add_message_if_is_withdrawn && !is_expired) {
		let withdrawn = $("<span/>").text("(Withdrawn)").addClass("expired-note"); 
		$(td).append(withdrawn);
	}


	return td;
};

CoursesPage.prototype.load = function(cb) {
	var api_user = this.state.frontend.state.api_user;

	api_user.fetch_data("/student/course/get_course_all", null, (err, courses) => {

		console.log(courses);

		
		if (courses.length < 1) {
			$(".msg-no-results").removeClass("hide");
			cb();
			return;
		}

		for (let course of courses) {
			let tr = $("<tr/>");
			let is_expired = course.is_expired;
			let is_withdrawn = course.is_withdrawn;

			var target = "/student/lessons?course=" + course.cou_id + "&course_data=" + encodeURIComponent(course.cou_code);
			if (is_expired) {
				target += "&expired=true";
			}

			$(tr).append(this.generate_linked_cell(fix_html_ents(course.cou_code), target, is_expired, true, is_withdrawn, true));
			$(tr).append(this.generate_linked_cell(fix_html_ents(course.cou_name), target, is_expired, false, is_withdrawn, false));

			$("#courses-table tbody").append(tr);
		}

		cb();
	});
};

module.exports = CoursesPage;