"use strict";

var $ = require("jquery");

var BootstrapDialog = require("bootstrap3-dialog");

var common = require("./common");

var jdp_table = {};

window.register_jdp = function(page_name, _class) {
	// console.log("register_jdp:", page_name);
	let base_page_name = page_name.replace(/.*[\\/]/g, "");
	jdp_table[base_page_name] = _class;
}

// Will be replaced by gulp
_AUTOGENERATE_JDP_PAGE_REQUIRES_

console.log(jdp_table);

module.exports = class JDP {

	constructor (frontend) {
		this.frontend = frontend;
		this.main_elem = $(".jdp-main");
		this.has_loaded = false;

		this.args = common.get_args(true);
		console.log(this.args);
		let page = this.args["page"];

		if (!page) {
			$(".page-title").text("Page not found.");
			$(".jdp-spinner").hide();
			return;
		}

		$("[data-requires-permission=" + page + "]").addClass("active");

		console.log(page);

		let page_class = jdp_table[page];

		if (!page_class) {
			$(".page-title").text("Page not found.");
			$(".jdp-spinner").hide();
			return;
		}


		// this.loading =

		this.handler = new (page_class)(this);
		let basic_info = this.handler.get_basic_info();

		// 
		let title_text = null;

		if (basic_info.title_sid) {
			title_text = this.frontend.get_nss_from_sid(basic_info.title_sid);
		} else {
			title_text = basic_info.title;
		}

		$("title, .page-title").text(title_text);
		if (basic_info.record_type_plural && !basic_info.dont_add_notfound_message) {
			// $(".record-type-plural").text(basic_info.record_type_plural);

			this.main_elem.append(`
				<div class="alert alert-warning msg-no-results">
					<strong>${window.nss("106:no_results")}</strong>
					<br>
					<span>${window.nss("106:generic_none_found", [basic_info.record_type_plural])}</span>
				</div>
			`);


		} else {
			//
		}
		

		$(window).on("resize", _ => {
			// console.log("resize");
			if (this.has_loaded) {
				// console.log("resize2");
				if (this.handler.on_layout) {
					this.handler.on_layout();
				}
			}
		});

	}

	set_title(new_title) {
		$("title, .page-title").text(new_title);
	}

	simple_show_error(e, detail, dont_redirect) {
		BootstrapDialog.show({
			message: (detail || "An error was encountered loading this page."),
			title: "Error",
			cssClass: "very-high-zindex",
			type: BootstrapDialog.TYPE_WARNING,
			closable: false,
			buttons: [
				{
					label: this.frontend.get_string("ok"),
					action: (dialogItself) => {
						dialogItself.close();
						if (!dont_redirect) {
							window.location = window.location;
						}
					}
				},
			]
		});
	}

	simple_show_success(title, msg, reload_on_ok) {
		BootstrapDialog.show({
			message: msg,
			title: title,
			cssClass: "very-high-zindex",
			type: BootstrapDialog.TYPE_SUCCESS,
			closable: false,
			buttons: [
				{
					label: this.frontend.get_string("ok"),
					action: (dialogItself) => {
						dialogItself.close();
						if (reload_on_ok) {
							window.location = window.location;
						}
					}
				},
			]
		});
	}


	load (cb) {
		this.api_user = this.frontend.state.api_user;
		this.get_string = this.frontend.get_string.bind(this.frontend);
		

		(async _=>{

			try {
				let result_from_onload = await this.handler.on_load();
				console.log(`R: ${result_from_onload}`);
				if (this.handler.on_layout) {
					this.handler.on_layout();
				}
				this.has_loaded = true;
			} catch (e) {
				console.log(e);
				this.simple_show_error(e);
			}
			
			$(".jdp-spinner").hide();
		})();

		



	}



};