import React, { useState } from 'react';

export function PaymentDates(props) {
  const [showDates, setShowDates] = useState(false);

  if (showDates) {
    return <React.Fragment>
      <p>{nss("106:ccard_dates")}</p>
      {props.pay_dates.map(d=>
        <p key={d} style={{marginLeft: 5}}>{d}</p>
      )}
    </React.Fragment>
  } else {
    const onClickShowDates = ev => {
      ev.preventDefault();
      setShowDates(true);
    }
    return <p><a href="#" onClick={onClickShowDates}>{nss("106:ccard_clickfordates")}</a></p>;
  }

  
};