import React, { useState, useRef } from 'react';
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {PaymentDates} from "./PaymentDates";

const stripeStyle = {
  base: {
    fontSize: '14px',
  },
};

export const CreditCardForm = props => {
  const stripe = useStripe();
  const elements = useElements();

  const [cardholderName, setCardholderName] = useState("");
  const [cardholderErrorMessage, setCardholderErrorMessage] = useState("");
  const [stripeErrorMessage, setStripeErrorMessage] = useState("");
  const [serverSideErrorMessage, setServerSideErrorMessage] = useState("");
  const [stripeIsFilled, setStripeIsFilled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldSave, setShouldSave] = useState(false);
  const [shouldSaveAuto, setShouldSaveAuto] = useState(false);


  const stripe_setup_res_onetime = useRef();

  const onSubmit = async event => {
    event.preventDefault();
    if (!stripe || cardholderErrorMessage || !stripeIsFilled || isLoading) return;

    if (!cardholderName.trim()) {
      setCardholderErrorMessage(nss("106:ccard_error_cardholder_name"));
      return;
    }

    setIsLoading(true);

    try {
      const cardElement = elements.getElement(CardElement);

      let confirm_opts = {
        payment_method: {
            card: cardElement,
            billing_details: {
                name: cardholderName,
            }
        }
      };

      const one_time = (props.cardFormType == "add_or_onetime") && !shouldSave;
      console.log("one time:");
      console.log(one_time);

      if (!one_time && (props.cardFormType != "replace")) {
        confirm_opts.setup_future_usage = 'off_session';
      }

      if (props.cardFormType == "replace") {
        const stripe_setup_res = await jdp.api_user.post_data_promise(`/student/fees_rewrite/stripe_recur_update_setup`, {});

        const stripe_result = await stripe.confirmCardSetup(stripe_setup_res.client_secret, confirm_opts);
        console.log(stripe_result);

        const card_id = stripe_result.setupIntent.payment_method;
        await jdp.api_user.post_data_promise(`/student/fees_rewrite/stripe_recur_update_done`, {
          card_id: card_id
        });

        window.location.reload();
      } else {
        if (!stripe_setup_res_onetime.current) {
          stripe_setup_res_onetime.current = await jdp.api_user.post_data_promise(`/student/fees_rewrite/stripe_onetime_setup`, {
            amount: parseFloat(props.pay_amount)
          });
        }

        const stripe_result = await stripe.confirmCardPayment(stripe_setup_res_onetime.current.client_secret, confirm_opts);

        const stripeCallSucceeded = !stripe_result.error;

        const onetimeRequestParams = {
            ppo_id: stripe_setup_res_onetime.current.ppo_id,
            save_card: !one_time,
            agree_auto_payment: shouldSaveAuto
        };

        if (stripeCallSucceeded) {
            onetimeRequestParams.payment_intent = stripe_result.paymentIntent;
        } else {
            onetimeRequestParams.error = stripe_result.error;
            // setStripeErrorMessage(stripe_result.error.message);
        }

        let apiResponseSucceeded = false;
        let errorToShow = null;
        try {
          await jdp.api_user.post_data_promise(`/student/fees_rewrite/stripe_onetime_done`, onetimeRequestParams);
          apiResponseSucceeded = true;
        } catch (apiError) {
          console.log(apiError);
          const apiErrorStr = (typeof apiError === "string" ? apiError : "<internal error>");
          errorToShow = stripeCallSucceeded ? apiErrorStr : stripe_result.error.message;
        }


        if (apiResponseSucceeded) {
          setStripeErrorMessage("");
          setServerSideErrorMessage("");
          alert(nss("106:ccard_thank_you_pay"));
          window.location = "/student/fees";
          console.log("have navigated away")
        } else {
          setServerSideErrorMessage(errorToShow || "<unknown error>");
          setStripeErrorMessage("");
            $("#stripe-pay-btn").removeClass("disabled");
        }
      }
    } catch (e) {
      console.log("unknown err:");
      console.log(e);
      setServerSideErrorMessage(nss("106:ccard_unknown_error_with_code", [(typeof e == "string") ? e : JSON.stringify(e)]));
    } finally {
      setIsLoading(false);
    }
  };

  const onStripeChanged = event => {
    if (event.error) {
      setStripeIsFilled(false);
      setStripeErrorMessage(event.error.message);
    } else {
      setStripeIsFilled(true);
      setStripeErrorMessage("");
      setServerSideErrorMessage("");
    }
  }

  const onCardholderNameChanged = event => {
    const name = event.target.value.trim();
    setCardholderName(name);
    if (name) {
      setCardholderErrorMessage("");
    } else {
      setCardholderErrorMessage(nss("106:ccard_error_cardholder_name"));
    }
  }

  return <form style={{maxWidth: 500}} onSubmit={onSubmit}>
      <input
        placeholder={nss("106:ccard_cardholder_name")}
        style={{marginBottom: 10, width: "100%", fontSize: 14, fontWeight: 600, border: "none", paddingLeft: 28, width: "100%", maxWidth: 300}}
        onChange={onCardholderNameChanged}
      />
      <CardElement options={{style: stripeStyle}} onChange={onStripeChanged} />

      {(props.cardFormType == "add_or_onetime") && <div style={{display: "flex", marginTop: 10, }}>
        <div>
          <input type="checkbox" checked={shouldSave} onClick={_=>{ const existingShouldSave = shouldSave; setShouldSave(!existingShouldSave); if (existingShouldSave) { setShouldSaveAuto(false); } }} />
        </div>
        <div style={{marginLeft: 16}}>
          <p style={{marginTop: 2}}>Save this card information with my profile?</p>
          <p>If you enable this option, your payment card information will be stored by <a href="https://stripe.com/" target="_blank">Stripe</a>.</p>
        </div>
      </div>}

      {((props.cardFormType == "add_or_onetime") && props.config.can_enable_autopay) && <div style={{display: "flex", marginTop: 10, }}>
        <div>
          <input type="checkbox" disabled={!shouldSave} checked={shouldSaveAuto} onClick={_=>setShouldSaveAuto(!shouldSaveAuto)} />
        </div>
        <div style={{marginLeft: 16}}>
          <p style={{marginTop: 2}}>Allow automatic monthly payments from this card?</p>
          <PaymentDates pay_dates={props.config.pay_dates}/>
          <p>When you have a saved card, automatic payments can be enabled or cancelled at any time from the "My Credit Card" link in the menu.</p>
        </div>
      </div>}


      <span style={{color: "red", marginTop: 10, whiteSpace: "pre-line"}}>{[stripeErrorMessage||serverSideErrorMessage, cardholderErrorMessage].filter(a=>!!a).join("\n")}</span>
      

      <div style={{display: "flex"}}>
        
        <button
          className="btn green-haze" style={{display: "block", marginTop: 10,}} type="submit"
          disabled={!stripe || cardholderErrorMessage || !stripeIsFilled || isLoading}
        >
          {nss( (props.cardFormType == "replace") ? "106:ccard_btn_save_details" : "106:ccard_btn_pay")}
        </button>

        {isLoading && <div className="jdp-spinner" style={{margin: 0, marginLeft: 20}}>
          <div className="cube1"></div>
          <div className="cube2"></div>
        </div>}


      </div>



    </form>
};