"use strict";

var $ = require("jquery");
var Cookies = require("cookies-js");
var flowplayer = require("../vendor/flowplayer.js");

function _shallow_clone_array(arr) {
	let result = [];
	for (let i in arr) {
		result[i] = arr[i];
	}
	return result;
}

function _exists(thing) {
	return (typeof(thing) != "undefined") && (thing !== null);
}

function _shallow_dict_merge(base, additional) {
	let result = {};
	for (let key in base)       { result[key] = base[key]; }
	for (let key in additional) { result[key] = additional[key]; }
}

function _split_arr_to_arr_of_groups_by_key(flat_arr, key, also_extracts) {
	let results = [];

	if (key === "entries") {
		throw "_split_arr_to_arr_of_groups_by_key: key cannot be 'entries'";
	}

	for (let flat_arr_entry of flat_arr) {
		if (!_exists(flat_arr_entry)) continue;

		let result = results.find(possible => possible[key] == flat_arr_entry[key]);
		if (!result) {
			let new_result = {};
			new_result[key] = flat_arr_entry[key];
			for (let also_extract of also_extracts) {
				new_result[also_extract] = flat_arr_entry[also_extract];
			}
			new_result["entries"] = [];
			results.push(new_result);
			result = new_result;
		}
		result["entries"].push(flat_arr_entry);
	}

	return results;
}

function _sort_array_of_dicts_by_key(arr, key) {
	return arr.sort((a, b) => {
		let x = a[key];
		let y = b[key];
		return ((x < y) ? -1 : ((x > y) ? 1 : 0));
	});
}

function _mutate_arr_to_move_entry_with_key_and_val_to_start(arr, key, expected_val) {
	let i = arr.findIndex(possible => possible[key] == expected_val);
	if (i == -1) {
		console.log("NOTE: _mutate_arr_to_move_entry_with_key_and_val_to_start: not found: " + key);
		return arr;
	} else {
		return [arr[i]].concat(arr.slice(0, i), arr.slice(i+1, arr.length));
	}
}

function _flat_arr_to_sorted_group_by_key_and_bump(flat_arr, key, other_extracts, boost_val) {
	return _mutate_arr_to_move_entry_with_key_and_val_to_start(_sort_array_of_dicts_by_key(_split_arr_to_arr_of_groups_by_key(flat_arr, key, other_extracts), key), key, boost_val);
}


module.exports = {

	split_by_day: function(flat_arr) {
		let grouped = _split_arr_to_arr_of_groups_by_key(flat_arr, "group_by", []);
		return grouped;
	},

	group_videos_list: function(flat_list, current_cou_code) {
		return _flat_arr_to_sorted_group_by_key_and_bump(flat_list, "course_code_from_filename", ["course_name_from_filename"], current_cou_code);
	},

	get_args: function (uri_decode) {
		
		var args_res = {};

		var query = document.location.search;
		if (query && (query.charAt(0) == "?")) {
			var args = query.split("?")[1].split("&");
			for (var arg of args) {
				var [arg_name, arg_val] = arg.split("=");
				if (arg_name && arg_val) {
					args_res[arg_name] = (uri_decode ? decodeURIComponent(arg_val) : arg_val);
				}
			}
		}

		return args_res;
	},

	start_video: function (video) {


		if (video.cookies) {
			for (let cookie_i in video.cookies) {
				Cookies.set(cookie_i, video.cookies[cookie_i], {domain: video.domain});
			}
		}

		// $(".video-player").append($("<video/>").append("<source/>").attr("src", video.sources[0].src));

		flowplayer(".video-player", {
			clip: {
				sources: video.sources
			},
			embed: false
		});

	},

	build_jdp_url(page, args) {
		let args_array = [];
		if (args) {
			for (let key in args) {
				if (key != "page")
					args_array.push(`${key}=${encodeURIComponent(args[key])}`);
			}	
		}
		return `/jdp?page=${page}${args_array.map(o => ("&" + o)).join("")}`;
	},

	parse_css_dict(text) {
		let css_dict = text
			.split("\n")
			.map(s => s.trim())
			.filter(s => s)
			.filter(s => (!s.startsWith("//")))
			.map(s => s.replace(";", ""))
			.map(s => s.split(":").map(s => s.trim()))
			.reduce((acc, cv) => {
				acc[cv[0]] = cv[1];
				return acc;
			}, {})
		;
		return css_dict;
	},

	shallow_clone_of(thing) {
		let result = {};
		for (let key in thing) {
			result[key] = thing[key];
		}
		return result;
	},


	add_elem(tag_name, css, classes, append_to, prepend) {
		let e = $(`<${tag_name}/>`);

		if (css) {
			if (typeof(css) === "string") {
				let css_dict = this.parse_css_dict(css);
				e.css(css_dict);
			} else {
				e.css(css);
			}
		}
		

		if (classes) {
			e.addClass(classes);
		}

		if (append_to) {
			if (prepend) {
				$(append_to).prepend(e);
			} else {
				$(append_to).append(e);
			}
			
		}
		return e;
	},


	add_css(text) {

		let css = document.createElement("style");
		css.type = "text/css";
		css.innerHTML = text.replace(/\/\/.*\n/g, "\n");
		document.body.appendChild(css);

	},


	add_abs_elem(tag_name, css, classes, append_to) {
		let e = this.add_elem(tag_name, css, classes, append_to);
		e.css({
			"position": "absolute"
		});
		return e;
	},

	add_field_group(label_text, control, container, block, prepend) {
		let group = this.add_elem("div", `
			display: ${block ? "block" : "inline-block"};
			margin-${block ? "bottom" : "left"}: 15px;
			position: relative;
		`, "form-group", container, prepend||false);
		this.add_elem("label", null, null, group).text(label_text);
		group.append(control);
		return group;
	},





	wipe_table(table) {

	},

	make_empty_table() {
		return $.parseHTML(`
			<table class="table table-striped table-hover jdp-table">
				<thead><tr></tr></thead>
				<tbody></tbody>
			</table>
		`);
	},

	/*
		col_legend: [
			{
				"label": "Name",
				"field": "name"
			},
			{
				"label": "Amount",
				"field": "total"
			}
		],
		row_data: [
			{
				name: "John Smith",
				total: 1234,
				foo: "bar"
			}
		]
	*/

	build_table(table, _col_legend, row_data, row_id_key, more_opts) {
		let thead = $(table).find("thead").first();
		let thead_tr = $(thead).find("tr").first();
		let tbody = $(table).find("tbody").first();

		if (!more_opts) more_opts = {};

		$(thead_tr).empty();
		$(tbody).empty();

		if (more_opts.should_reverse) {
			row_data = row_data.slice().reverse();
		}

		let col_legend = _shallow_clone_array(_col_legend);

		if (more_opts && more_opts.checkbox_rows) {
			col_legend.unshift({label: "", field: "_checkbox"});
		}

		for (let col of col_legend) {
			let th_label = col.label;
			let th = $("<th/>")
			if (more_opts.sort_by_field && (col.field === more_opts.sort_by_field)) {
				th_label += " ";
				th_label += (more_opts.is_descending ?  "▾" : "▴");
				$(th).click(e => {
					e.preventDefault();
					more_opts.on_sort_order_changed();
				});
				$(th).css("cursor", "default");
			}
			th.text(th_label);


			if (col.mobile_only) {
				$(th).addClass("table-mobile-only")
			}
			$(thead_tr).append(th);
		}
		
		let row_num = 0;
		let row_count = row_data.length;
		for (let datum of row_data) {
			let tr = $("<tr/>");
			if (row_num % 2 == 0) {
				$(tr).addClass("alt-row-bg");
			}

			if (row_id_key) {
				$(tr).attr("data-row-id", datum[row_id_key])
			}



			let link_done = false;
			let cell_num = 0;
			let cell_count = col_legend.length;
			for (let col of col_legend) {
				let td = $("<td/>");
				if (cell_num % 2 != 0) {
					$(td).addClass("alt-cell-bg");
				}

				if (cell_num == (cell_count-1)) {
					$(td).addClass("last-cell");
				}

				if (col.field == "_combined") {
					$(td).addClass("full-width-row");
				}

				if (col.mobile_only) {
					$(td).addClass("table-mobile-only")
				}

				let mobile_label = col.label;
				if (more_opts.checkbox_rows && (col.field == "_checkbox") && more_opts.checkbox_rows_mobile_label) {
					mobile_label = more_opts.checkbox_rows_mobile_label;
				}

				let mobile_title = $("<span/>").addClass("card-view-only").text(mobile_label);
				let span = $("<span/>").addClass("data-span");
				if (col.link) {

					if (datum[col.field] && datum[col.field] != "#") {
						let a = $("<a/>").attr("target", "_blank").attr("href", datum[col.field]).text(
							fix_html_ents(col.link_text_field ? datum[col.link_text_field] : col.link_text)
						);
						$(span).append(a);		
					} else {
						span.text(fix_html_ents(col.link_text_field ? datum[col.link_text_field] : col.link_text));
					}
					if (col.extra_actions_field) {
						let extras = datum[col.extra_actions_field] || [];
						for (let extra of extras) {
							let btn = $("<button/>").addClass("btn-fake-link").text(extra.label).on("click", _=>{
								if (more_opts && more_opts.on_extra_action_callback) {
									more_opts.on_extra_action_callback(col.extra_actions_field, extra.id, extra.payload)
								}
							});
							$(span).append(btn);
						}
					}
				} else if (col.field == "_checkbox") {
					let check = $("<input/>").attr("type", "checkbox");
					if (more_opts.cb_on_check_changed) {
						$(check).on("change", more_opts.cb_on_check_changed);
					}
					$(span).append(check);
				} else if (col.html) {
					span.html(datum[col.field]);
				} else {
					span.text(datum[col.field]);
				}
				
				if (col.nowrap) {
					$(span).css("white-space", "nowrap");
				}

				if (col.pre) {
					$(span).css({
						"white-space": "pre",
						"padding-right": "5px"
					});
				}


				if (col.extra_class) {
					$(span).addClass(col.extra_class);
				}

				if (!link_done && datum._row_link_url) {
					let a = $("<a/>").attr("href", datum._row_link_url);
					$(a).append(span);
					span = a;
					link_done = true;
				}

				$(td).append(mobile_title);
				$(td).append(span);
				$(tr).append(td);
				cell_num++;
			}

			$(tbody).append(tr);
			row_num++;
		}
	},



};