"use strict";
var React = require("react");

var _ = require("lodash");
window._ = _;

var $ = require("jquery");
require("blueimp-file-upload");

var moment = require("moment");

var Field = require("./Field");

function exists(thing) {
  return (thing !== null) && (typeof(thing) != "undefined") && (thing !== "");
}

function is_empty(thing) {
  return (thing === null || typeof(thing)=="undefined" || thing === "");
}

module.exports = class ProfileEditor extends React.Component {
  constructor(props) {
    super(props);

    if (!window.uploaded_file_indices) {
      window.uploaded_file_indices = new Set();
    }

    let values = Object.assign({}, props.profile_config.CurrentValues);

    this.setup_upload_buttons();

    this.state = {
      CurrentValues: values,
      DefaultValues: {},
      validationDirty: [],
      validationFailed: {},
      isSubmitting: false,
      haveEverAttemptedFullValidation: false,
      haveTriedToSubmitWithoutUserType: false,

      // true if any field has been changed since page load-
      // used to decide whether to show warnings
      entireFormIsDirty: false,
      buttonIndicesVisible: {},
      isUploadingFile: false,
    };


  }

  assignDefaults() {
    const config = this.getConfig();
    let defaults = {};

    for (let field in config.fields) {
      const maybeDefault = config.fields[field].default_selection;
      // if (exists(maybeDefault) && !exists(values[field])) {
      if (exists(maybeDefault)) {
        // Integer IDs seem to be served as strings
        const asInt = parseInt(maybeDefault);
        defaults[field] = !isNaN(asInt) ? asInt : maybeDefault;
      }
    }

    this.setState({
      DefaultValues: defaults,
    })
  }

  setup_upload_buttons() {
    this.button_fileupload_inputs = {};

    for (const button of this.props.profile_config.AllButtons) {

      const fileupload_input = $("<input />")
        .attr("type", "file")
        .attr("name", "upload_file")
        .attr("data-url", jdp.frontend.state.api_base + "/student/profile_rewrite/upload_v2_file?index=" + button.Index);

      $(fileupload_input).fileupload({
          dataType: 'json',
          headers: {
            "Authorization": "Token " + Cookies.get("token")
          },
          submit: _ => {
            this.setState({
              isUploadingFile: true,
            });
            return true;
          },
          error: () => {
            jdp.simple_show_error(nss("106:generic_error_occurred"), nss("106:profile_file_upload_error_detail"), true);
            this.setState({
              isUploadingFile: false,
            });
            this.setup_upload_buttons();
          },
          done: (e, data) => {
            let result = data.result;
            if (result && result.status && result.status == "ok") {
              window.uploaded_file_indices.add(button.Index);
              this.setState({
                validationDirty: [...this.state.validationDirty, "button_" + button.Index],
                entireFormIsDirty: true,
              });
              jdp.simple_show_success(nss("106:profile_file_upload_complete_title"), nss("106:profile_file_upload_complete_detail"));
            } else {
              jdp.simple_show_error(nss("106:generic_error_occurred"), nss("106:profile_file_upload_error_detail"), true);
            }

            this.setState({
              isUploadingFile: false,
            });
            this.setup_upload_buttons();
          }
      });

      this.button_fileupload_inputs[button.Index] = fileupload_input;
    }
  }

  validateAll(ignoreDirtyList) {
    console.log("validating...");
    const allConfig = this.getConfig();

    let errors = Object.assign({}, this.state.validationFailed);
    const dirtyList = ignoreDirtyList ? Object.keys(allConfig.fields) : this.state.validationDirty;

    if (ignoreDirtyList || dirtyList.some(d => d.startsWith("button_"))) {
      for (const button of allConfig.buttons) {
        const aButton = this.props.profile_config.AllButtons.find(b => b.Index == button.Index);
        if (aButton) {
          const buttonId = "button_" + button.Index;

          if (button.Required && !aButton.HaveData && !window.uploaded_file_indices.has(button.Index)) {
            errors[buttonId] = nss("106:profile_field_required");
          } else {
            delete errors[buttonId];
          }
        }
      }
    }    
    
    for (const dirty of dirtyList) {
      const config = allConfig.fields[dirty];
      if (!config) continue;

      const value = this.state.CurrentValues[dirty];
      const _default = this.state.DefaultValues[dirty];
      delete errors[dirty];



      const value_is_empty = (() => {
        // For dropdowns, consider the field empty if its current value is not in the list of options
        if (config.type == "DropDown") {
          // Loose equality is intentional here
          if (!config.dropdown_options.find(opt => opt.id == value)) {
            return true;
          }
        }
        
        return is_empty(value);
      })();

      if (config.required && value_is_empty) {
        console.log("might need to mark as required")
        // Very likely need to mark as required BUT ignore if field
        // is dropdown and all possible options are empty
        if (config.type == "DropDown") {
          if (!config.dropdown_options.some(o => !is_empty(o.id))) {
            // Do nothing
          } else {
            if (!is_empty(_default)) {
              // Do nothing as it /shouldn't/ be possible for a field with a default to have no value
              // A value will be assigned from the defaults upon submission
            } else {
              errors[dirty] = nss("106:profile_field_required");
            }
          }
        } else {
          errors[dirty] = nss("106:profile_field_required");
        }
      } else if (!value_is_empty) {
        if (config.type == "Date" && ((value === "E_INVALID_DATE") || (!moment(value).isValid())) ) {
          errors[dirty] = nss("106:profile_field_bad_date");
        } else if (config.validation) {
          for (const validator of config.validation) {
            const ok = RegExp(validator[0]).test(value);
            if (!ok) {
              errors[dirty] = nss(validator[1]);
            }
          }
        }
      }
    }

    this.setState({
      validationDirty: [],
      validationFailed: errors,
    });

    if (ignoreDirtyList) {
      this.setState({
        haveEverAttemptedFullValidation: true,
      });
    }

    return (Object.keys(errors).length == 0);

  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.CurrentValues["StudentParticulars_0_UserType"] != this.state.CurrentValues["StudentParticulars_0_UserType"]) {
      this.setState({
        validationFailed: {},
        validationDirty: Object.keys(this.getConfig().fields),
      });
      this.assignDefaults();
    } else if ((prevState.validationDirty != this.state.validationDirty) && (this.state.validationDirty.length > 0)) {
      this.validateAll();
    }

    const buttons = $(".file-upload-btn").toArray();
    if (buttons.length > 0) {
      let widestButton = 0;
      for (const button of buttons) {
        const w = $(button).width();
        if (w > widestButton) widestButton = w;
      }
      for (const button of buttons) {
        $(button).width(widestButton);
      }
    }
  }

  componentDidMount() {
    this.assignDefaults();
    this.validateAll();
  }

  getConfig(currentValues) {
    const current_ut_val = (currentValues || this.state.CurrentValues)["StudentParticulars_0_UserType"];
    const is_specific_user_type_selected = (this.props.profile_config.UserTypes.findIndex(ut => ut.id == current_ut_val) != -1);
    return is_specific_user_type_selected
      ? (this.props.profile_config.ConfigsByUserType[current_ut_val] || this.props.profile_config.DefaultConfig)
      : this.props.profile_config.DefaultConfig
    ;
  }

  render() {

    const current_ut_val = this.state.CurrentValues["StudentParticulars_0_UserType"];
    let user_types = this.props.profile_config.UserTypes;

    const is_specific_user_type_selected = (user_types.findIndex(ut => ut.id == current_ut_val) != -1);

    if (!is_specific_user_type_selected) {
      user_types = [{id: current_ut_val, name: nss("106:profile_user_type_please_select")}, ...user_types];
    }

    const Config = is_specific_user_type_selected
      ? (this.props.profile_config.ConfigsByUserType[current_ut_val] || this.props.profile_config.DefaultConfig)
      : this.props.profile_config.DefaultConfig
    ;

    console.log(Config);

    const form_has_issues = (this.state.validationDirty.length > 0 || Object.keys(this.state.validationFailed).length > 0);

    return <div id="user_type_div">

      { this.props.profile_config.EditPageDesc && <p style={{
        whiteSpace: "pre-line",
      }}>{this.props.profile_config.EditPageDesc}</p> }

      {this.props.profile_config.AllowChangeUserType &&
        <Field fieldKey={"StudentParticulars_0_UserType"} fieldConfig={{
          type: "DropDown",
          label: nss("106:profile_user_type"),
          required: true,
          dropdown_options: user_types,
        }} error={this.state.haveTriedToSubmitWithoutUserType && nss("106:profile_field_required")} currentValues={this.state.CurrentValues} defaultValues={this.state.DefaultValues} onChange={newVal=>{
          console.log(newVal);
          this.setState({
            CurrentValues: Object.assign({}, this.state.CurrentValues, {"StudentParticulars_0_UserType": newVal}),
            haveTriedToSubmitWithoutUserType: false,
          })
        }} />
      }

      {Config.sections.map((section, section_i) => {
        return <div key={"section_" + section_i}>
          {section.heading && <h3>{section.heading}</h3>}
          <div>
            {section.subsections.map((subsection, subsection_i) => {
              return <div key={"subsection_" + subsection_i}>
                {subsection.subheading && <h4>{subsection.subheading}</h4>}
                <div>
                  {subsection.fields.map(fieldKey => {
                    const fieldConfig = Config.fields[fieldKey];
                    return <Field
                      key={fieldKey}
                      error={this.state.validationFailed[fieldKey]}
                      fieldKey={fieldKey}
                      fieldConfig={fieldConfig}
                      currentValues={this.state.CurrentValues}
                      defaultValues={this.state.DefaultValues}
                      onChange={newVal => {
                        console.log("newVal:");
                        console.log(newVal);
                        this.setState({
                          CurrentValues: Object.assign({}, this.state.CurrentValues, {[fieldKey]: newVal}),
                          validationDirty: [...this.state.validationDirty, fieldKey],
                          entireFormIsDirty: true,
                        });
                        this.props.onMadeDirty()
                      }}
                    />
                  })}
                </div>
              </div>
            })}
          </div>
        </div>
      })}

      {
        Config.buttons.length > 0 &&
        <div key="section_files">
          <h3>{nss("106:profile_files")}</h3>
          {this.state.isUploadingFile
            && <div style={{
              width: 100,
              height: 100,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundImage: `url(/static/img/ajax-loading.gif)`,
            }}></div>
            || <div>
              {Config.buttons.map(button => {
                const buttonId = "button_" + button.Index;
                const err = this.state.validationFailed[buttonId];
                return <div key={buttonId}>
                    {button.Required && <span style={{color: "red", position: "absolute", marginLeft: -4, marginTop: 20 }}>*</span>}
                    <button className="btn green-haze file-upload-btn" style={{
                      marginLeft: 10,
                      marginTop: 10,
                    }} onClick={ev => {
                      this.button_fileupload_inputs[button.Index].click();
                    }}>
                      {button.ButtonLabel}
                    </button>
                    {err && <p style={{color: "red", marginTop: 5, marginLeft: 10,}}>{err}</p>}

                </div>
              })}
            </div>
          }
        </div>
      }

      <hr />

      <button className="btn green-haze" style={{
        marginLeft: 10,
      }} disabled={form_has_issues||this.state.isSubmitting} onClick={async event=>{
        if (form_has_issues||this.state.isSubmitting) return;
        console.log(this.props.profile_config);
        if (!this.validateAll(true)) return;

        const current_ut_val = this.state.CurrentValues["StudentParticulars_0_UserType"];
        const user_types = this.props.profile_config.UserTypes;
        const is_specific_user_type_selected = (user_types.findIndex(ut => ut.id == current_ut_val) != -1);
        if (!is_specific_user_type_selected && user_types.length > 0 && this.props.profile_config.AllowChangeUserType) {
          this.setState({
            haveTriedToSubmitWithoutUserType: true,
          });
          $('html,body').animate({scrollTop: $("#user_type_div").offset().top});
          return;
        }

        this.setState({
          isSubmitting: true,
        });

        try {
          let toSubmit = {};
          for (let field in this.state.CurrentValues) {
            if (exists(this.state.CurrentValues[field])) {
              toSubmit[field] = this.state.CurrentValues[field];
            }
          }

          for (let field in this.state.DefaultValues) {
            if (!exists(toSubmit[field]) && exists(this.state.DefaultValues[field])) {
              toSubmit[field] = this.state.DefaultValues[field];
            }
          }

          console.log(toSubmit);

          await jdp.api_user.post_data_promise(`/student/profile_rewrite/update_profile`, {
            data: toSubmit,
          });

          this.setState({
            isSubmitting: false,
          });

          this.props.onSaveOk();

          jdp.simple_show_success(nss("106:profile_updated_title"), nss("106:profile_updated_detail"), true);
        } catch (e) {
          console.log(e);
          jdp.simple_show_error(e, "", true);
        }
      }}>{this.state.isSubmitting ? nss("106:profile_wait") : nss("103:profile_save")}</button>

      {form_has_issues && <span style={{
        marginLeft: 10,
        color: "red",
      }}>{nss("106:profile_review_issues")}</span>}

      <button style={{
        display: "block",
        background: "none",
        border: "none",
        paddingLeft: 0,
        color: "red",
        marginLeft: 10,
        marginTop: 10,
      }} onClick={event => {
          this.props.onCancel();
      }}>{nss("106:profile_cancel_edit")}</button>


    </div>;
  }
}