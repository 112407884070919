
"use strict";

var $ = require("jquery");

var common = require("./common");
var text = require("./text");

module.exports = {
	render: function(field_name, field_value, field_info) {
		return text.render(field_name, field_value, field_info, true);
	},

	validate: function(field_value, field_info) {
		return text.validate(field_value, field_info);
	},

	get: function(field_dom, field_info) {
		return text.get(field_dom, field_info);
	}
};



