"use strict";

var $ = require("jquery");

module.exports = {};

module.exports.render_label = function(field_info) {
	let label = $("<label/>").addClass("control-label").text(field_info.label + "");
	if (field_info.required) {
		label.addClass("control-label-required");
	}
	return label;
};

module.exports.render_group = function(field_name, field_type, field_value, field_info) {
	let f_g = $("<div/>").addClass("form-group")
		.attr("data-field-name", field_name)
		.attr("data-field-type", field_type)
	;

	if (field_info) {
		if (!field_info.writable) {
			$(f_g).attr("data-disabled", "true");
		}

		if (field_info.required) {
			$(f_g).attr("data-required", "true");
		}
	}


	return f_g;
};

// module.exports.control_class = "col-md-7";
module.exports.control_class = "";