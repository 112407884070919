"use strict";

var common = require("../common");
var $ = require("jquery");
var moment = require("moment");




var _class = class {
	constructor(jdp) {
		this.jdp = jdp;
		console.log(jdp.args.data);
		this.data = JSON.parse(jdp.args.data);
	}

	get_basic_info() {
		return {
			title_sid: "101:title_sessions_thank_you",
			// record_type_plural: ""
		};
	}


	on_layout() {
	}

	async on_load() {
		let data = this.data;
		let url = `/student/package/get_templated_message?type=sessions_thank_you&pu_id=${data.pu_id}&cl_ids=${data.cl_ids.join(",")}`

		let message = await this.jdp.api_user.fetch_data_promise(url, null);
		common.add_elem("p", {}, "", this.jdp.main_elem).html(message.message);
	}




};

module.exports = _class;
window.register_jdp((require('path')).basename(__filename, ".js"), _class);