"use strict";

var $ = require("jquery");

var BootstrapDialog = require("bootstrap3-dialog");

var common = require("../../common");

function fix_html_ents(str) {
  return str.replace(/&amp;/g, "&").replace(/&gt;/g, ">").replace(/&lt;/g, "<");
}

module.exports = class RecordsPage {
  constructor(frontend) {
    this.state = {
      frontend,
      data: null,
    };
  }

  add_form(button, lessons, cl_id) {
    let form = $("<form/>").addClass("file_upload_form");
    form.attr("action", this.state.frontend.state.api_base + "/student/records/upload_file");
    form.attr("method", "post");
    form.attr("enctype", "multipart/form-data");

    if (document.location.host == "portaltest2.jonathanaikennewman.com") {
      form.attr("target", "_asdf");
    }

    function discard_form() {
      $(form).remove();
      $(".records, .page-title").removeClass("hide");
    }

    let cancel_btn = $("<a/>")
      .addClass("btn green-haze")
      .css("margin-top", "10px")
      .attr("href", "#")
      .text(nss("103:btn_cancel_upload"));

    $(cancel_btn).on("click", (ev) => {
      ev.preventDefault();
      ev.stopPropagation();
      discard_form();
    });

    if (!cl_id) {
      form.append(cancel_btn);
    }

    form.append($("<h1/>").text(button.label));

    const redirect_url = cl_id ? `/student/lesson_detail?lesson=${cl_id}` : document.location.href;

    let redirect_field = $("<input/>")
      .attr("type", "hidden")
      .attr("name", "redirect_uri")
      .val(redirect_url);
    form.append(redirect_field);

    let needval_fields = [];

    let submit_btn = $("<input/>")
      .addClass("btn green-haze disabled")
      .css("margin-top", "10px")
      .attr("type", "submit")
      .val(nss("103:btn_upload"));

    const file_field = $("<input/>").attr("type", "file").attr("name", "upload_file");
    const accept_exts = this.state.data.file_opts.file_types;

    const accept_normalized = accept_exts.split(",").map(ext => ext.trim().toLowerCase());

    file_field.attr("accept", accept_exts);
    const form_error_message = $("<p/>").addClass("upload-error status bad");

    const can_submit = () => {

      console.log(file_field);
      console.log(file_field.val())

      const file_val = file_field.val();

      if (!file_val) {
        return false;
      }

      let ext_ok = false;
      for (const accept of accept_normalized) {
        if (file_val.trim().toLowerCase().endsWith(accept)) {
          ext_ok = true;
          break;
        }
      }

      if (!ext_ok && !common.get_args()["DEBUG_NO_CLIENT_SIDE_EXT_CHECK"]) {
        form_error_message.text(nss("records_unsupported_file_type", accept_normalized.join(", ")));
        return false;
      } else {
        form_error_message.text("");
      }

      for (let field of needval_fields) {
        let val = field.val();
        if (field.attr("name") == "lesson") {
          if (!val || val == "dummy") {
            return false;
          }
        } else {
          if (!val) {
            return false;
          }
        }
      }

      return true;
    };

    const maybe_show_or_hide_submit = (_) => {
      if (can_submit()) {
        submit_btn.removeClass("disabled");
      } else {
        submit_btn.addClass("disabled");
      }
    };

    for (let field_name of button.fields) {
      if (!["Lesson", "Title", "Grade", "Remark"].includes(field_name)) continue;
      let field_http_name = field_name.toLowerCase();
      if (field_http_name == "remark") {
        field_http_name = "remarks";
      }
      let field = null;
      if (button.title_list.length > 0 && field_name == "Title") {
        field = $("<select/>")
          .addClass("form-control upload_text_field")
          .attr("name", field_http_name);
        for (const opt of button.title_list) {
          $(field).append($("<option/>").val(opt).text(opt));
        }
      } else if (field_name == "Lesson") {
        if (lessons.length > 0 && _feature_config.features.includes("PorLessonFileUpload")) {
          field = $("<select/>")
          .addClass("form-control upload_text_field")
          .attr("name", field_http_name);
      
          if (cl_id) {
            $(field).attr("readonly", "readonly");
          } else {
            $(field).append($("<option/>").val("dummy").text(nss("records_field_lesson")));
          }
      
          for (const opt of lessons) {
            const name = fix_html_ents(
            `${opt.formatted_date} ${opt.formatted_time} - ${opt.cou_name} (${opt.cou_code})`
            );
            const elem = $("<option/>").val(opt.cl_id).text(name);
            if (cl_id && opt.cl_id == cl_id) {
            $(elem).attr("selected", "selected");
            }
            $(field).append(elem);
          }
        } else {
          continue;
        }
      } else {
        const field_label = this.state.data.file_opts.field_labels[field_name] || nss(`106:records_field_placeholder_${field_name}`);
        field = $("<input/>")
          .addClass("form-control upload_text_field")
          .attr("type", "text")
          .attr("name", field_http_name)
          .attr("placeholder", field_label);
      }
      if (field_http_name != "remarks") {
        needval_fields.push(field);
      }
      form.append(field);
      field.on("change", (_) => {
        maybe_show_or_hide_submit();
      });
    }

    form.append(file_field);

    file_field.on("change", (_) => {
      maybe_show_or_hide_submit();
    });

    $(form).on("submit", (ev) => {
      if ($(submit_btn).hasClass("disabled")) {
        ev.preventDefault();
        ev.stopPropagation();
        return;
      }

      // discard_form();
    });


    form.append(form_error_message);

    form.append(submit_btn);

    // add form to page
    $("#buttons_dummy").after(form);

    // hide the main profile page
    $(".records, .page-title").addClass("hide");
  }

  filter_and_make_table() {
    const data = this.state.data;
    let selected_year = $("#year-control").val();
    let selected_records = selected_year
      ? data.rows.filter((r) => {
          return r.filterable_year == selected_year;
        })
      : data.rows;
    common.build_table($("#records-table"), data.legend, selected_records);

    $(".data-span a").click(function (e) {
      if ($(window).width() <= 1160) return;

      let href = $(this).attr("href");
      let href_split = href.split(".");
      let last_part = href_split[href_split.length - 1].toLowerCase();
      if (["png", "jpg", "jpeg", "gif", "bmp"].indexOf(last_part) == -1) {
        return;
      }

      e.preventDefault();

      BootstrapDialog.show({
        message: $("<div/>")
          .addClass("record-popup-image")
          .css("background-image", "url(" + href + ")"),
        title: $(this).text(),
        cssClass: "very-high-zindex",
        type: BootstrapDialog.TYPE_INFO,
        buttons: [
          {
            label: "Close",
            action: function (dialogItself) {
              dialogItself.close();
            },
          },
        ],
      });
    });
  }

  _get_cl_id() {
    const args = common.get_args();
    const cl_id = args.cl_id;
    if (cl_id) {
      return parseInt(cl_id);
    } else {
      return null;
    }
  }

  async load_async() {
    var api_user = this.state.frontend.state.api_user;
    const cl_id = this._get_cl_id();

    let url = "/student/records/get_all_records";
    if (cl_id) {
      url += `?cl_id=${cl_id}`;
    }

    this.state.data = await api_user.fetch_data_promise(url, null);

    if (!cl_id) {
      if (this.state.data.rows.length < 1) {
        $(".msg-no-results").removeClass("hide");
      }
  
      let years = new Set();
      this.state.data.rows.forEach((r) => {
        years.add(r.filterable_year);
      });
      let today_year = new Date().getFullYear();
  
      for (let year of years) {
        let val = year;
        let opt = $("<option/>").val(val).text(val);
        if (val == today_year) {
          $(opt).attr("selected", "selected");
        }
  
        $("#year-control").append(opt);
      }

      this.filter_and_make_table();
      
      $("#year-control").change(() => {
        this.filter_and_make_table();
      });
    }

    if (!this.state.data.file_opts) {
      return;
    }

    let lessons;
    if (cl_id) {
      const all_lessons = await api_user.fetch_data_promise("/student/course/get_lesson_all?include_sessions=true");
      lessons = all_lessons.filter((l) => l.cl_id == cl_id);
    } else {
      lessons = await api_user.fetch_data_promise("/student/course/get_lesson_all?days_back=4&include_sessions=true");
    }

    const btn = $("<a/>")
      .addClass("btn green-haze")
      .css("margin-top", "10px")
      .attr("href", "#")
      .text(this.state.data.file_opts.label);

    $(btn).on("click", (ev) => {
      ev.preventDefault();
      ev.stopPropagation();
      this.add_form(this.state.data.file_opts, lessons, cl_id);
    });

    $("#upload_buttons").append(btn);

    if (cl_id) {
      $(btn).click();
    }
  }
};
