var moment = require("moment");

var common = require("../../common");
var PastFeeToRowMapper = ["date_human", "type", "receipt_no", "amount", "tax", "amount_with_tax"];

function map_record_to_row(record, mapper_array, transformer) {
	console.log(record);
	var tr = $("<tr/>");
	for (var field of mapper_array) {
		console.log(field);
		var result = transformer ? transformer(field, record[field]) : record[field];
		var cell = $("<td/>").html(result);
		$(tr).append(cell);
	}

	return tr;
}


module.exports = class FeesPastPage {

	constructor (frontend) {
		this.state = {
			frontend: frontend
		};


		let filter_controls_container = common.add_elem("div", {}, "row", $(".invoice-filters"));

		this.year_select = common.add_elem("select", {}, "form-control");
		this.year_select.append($(`<option/>`).val("").text(nss("103:label_all")));
		common.add_field_group(nss("103:label_year"), this.year_select, filter_controls_container);

		this.year_select.change(_=>{
			let val = this.year_select.val();
			document.location = `/student/fees_past${val ? ("?year=" + val) : "?year=all"}`;
		});

	}

	load (cb) {
		var api_user = this.state.frontend.state.api_user;


		let request = api_user.fetch_data_promise("/student/fees/get_past");

		let args = common.get_args();

		request.then(pasts => {

			if (pasts.data_since) {
				$(".page-title").text(`Past Payments after ${pasts.data_since}`);
			}


			if (pasts.rows.length < 1) {
				$(".msg-no-results").removeClass("hide");
				$(".invoice-filters").addClass("hide");
				cb();
				return;
			}

			let years_set = new Set();

			for (let r of pasts.rows) {
				years_set.add(r.date_computer.split("-")[0]);
			}

			let years_array = Array.from(years_set).sort().map(y => {
				return {
					year: y,

				}
			});

			let have_set_selected = false;
			for (let year_i in years_array) {
				let year = years_array[year_i];
				if (year.year == args["year"]) {
					year.selected = true;
					have_set_selected = true;
				}
			}

			if ((!have_set_selected) && (args["year"] != "all") && (years_array.length > 0)) {
				years_array[years_array.length-1].selected = true;
			}

			
			for (let year_i in years_array) {
				let year = years_array[year_i];
				let opt = $(`<option/>`).val(year.year).text(year.year);
				if (year.selected) {
					$(opt).attr("selected", "selected");
				}
				this.year_select.append(opt);
			}

			if (years_array.length > 1) {
				$(".invoice-filters").removeClass("hide");
			}

			let year_val_to_use = this.year_select.find("option:selected").first().val() || "all";

			let usable_rows = pasts.rows.filter(r => {
				if (year_val_to_use && (year_val_to_use != "all")) {
					return (r.date_computer.split("-")[0] == year_val_to_use);
				} else {
					return true;
				}
			});

			for (let record of usable_rows) {
				for (let currency_field of ["amount"]) {
					record[currency_field] = window.currency + record[currency_field].toFixed(2);
				}
			}

			common.build_table($("#fees-past-table"), pasts.legend, usable_rows);


			cb();
		});

	}



};