"use strict";

var $ = require("jquery");
var common = require("./common");
var BootstrapDialog = require("bootstrap3-dialog");

module.exports = class JDP2 {

	constructor (frontend) {
		this.frontend = frontend;
		this.has_loaded = false;

		this.args = common.get_args(true);
		console.log(this.args);
		let page = this.args["page"];

		if (!page) {
			$(".page-title").text("Page not found.");
			$(".jdp-spinner").hide();
			return;
		}

		$("[data-requires-permission=" + page + "]").addClass("active");
	}

	simple_show_error(e, detail, dont_redirect) {
		BootstrapDialog.show({
			message: (detail || e.msg || "An error occurred."),
			title: "Error",
			cssClass: "very-high-zindex",
			type: BootstrapDialog.TYPE_WARNING,
			closable: false,
			buttons: [
				{
					label: this.frontend.get_string("ok"),
					action: (dialogItself) => {
						dialogItself.close();
						if (!dont_redirect) {
							window.location = window.location;
						}
					}
				},
			]
		});
	}

	complex_show_error(e, detail, buttons) {
		BootstrapDialog.show({
			message: (detail || "An error occurred."),
			title: "Error",
			cssClass: "very-high-zindex",
			type: BootstrapDialog.TYPE_WARNING,
			closable: false,
			buttons: buttons.map(btn => ({
				label: btn.label,
				action: (dialogItself) => {
					dialogItself.close();
					btn.action();
				}
			}))
		});
	}


	load (cb) {
		window["_jdp2"] = this;

		// See frontend_entry1.tsx for an explanation.
		if (window["_onPortalReadyForJdp2"]) {
			window["_onPortalReadyForJdp2"]();
		} else {
			window["_portalIsReadyForJdp2"] = true;
		}

		cb();

		setTimeout(() => {
			this.frontend.state.page_header.layout();
		}, 250);
	}
};