
"use strict";

var $ = require("jquery");

var common = require("./common");

let email_validation = require("../../validation/email");

module.exports =  {
	render: function(field_name, field_value, field_info, is_multiline) {


		let f_g = common.render_group(
			field_name,
			is_multiline ? "multiline" : "text",
			field_value,
			field_info
		);

		let label = common.render_label(field_info);
		let field = $(is_multiline? "<textarea/>" : "<input/>")
			.addClass("form-control").addClass(common.control_class)
			.attr("type", "text")
			.val(field_value)
		;



		if (field_info.writable) {
			// $(field).attr("placeholder", field_info.label);
		} else {
			$(field).attr("disabled", "disabled");
		}

		$(f_g).append(label);
		$(f_g).append(field);


		return f_g;



	},

	validate: function(field_value, field_info) {

		if (!field_value || field_value == "") {
			if (field_info.required) {
				return false;
			} else {
				return true;
			}
		}

		if (field_info.is_email_address) {
			if (!email_validation.is_email_valid(field_value)) {
				return false;
			}
		}

		return true;
		
	},

	get: function(field_dom, field_info) {
		return $(field_dom).find(".form-control").first().val();
	}
};