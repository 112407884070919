"use strict";


function _makeAbsElem(tag_name, css) {
	let elem = $("<" + tag_name + "/>");
	elem.css({
		"position": "absolute",
		"box-sizing": "border-box",
	});
	elem.css(css);
	return elem;
}

function _setPos(dom, left, top, width, height) {
	dom.css({
		"left": left,
		"top": top,
	});
	if (typeof(width)!=="undefined") dom.width(width);
	if (typeof(height)!=="undefined") dom.height(height);
}

function _isDesktop() {
	return window.outerWidth > 991;
}

function _isDesktopSidebarVisible() {
	return (!$(".page-sidebar-wrapper").hasClass("hidden")) && _isDesktop();
}

let menuButtonSvg = $.parseHTML(`
<svg
   xmlns:dc="http://purl.org/dc/elements/1.1/"
   xmlns:cc="http://creativecommons.org/ns#"
   xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
   xmlns:svg="http://www.w3.org/2000/svg"
   xmlns="http://www.w3.org/2000/svg"
   id="svg8"
   version="1.1"
   viewBox="0 0 6.8791665 5.8208335"
   height="22"
   width="26">
  <defs
     id="defs2" />
  <metadata
     id="metadata5">
    <rdf:RDF>
      <cc:Work
         rdf:about="">
        <dc:format>image/svg+xml</dc:format>
        <dc:type
           rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
        <dc:title></dc:title>
      </cc:Work>
    </rdf:RDF>
  </metadata>
  <g
     transform="translate(0,-291.17915)"
     id="layer1">
    <rect
       ry="0.59450316"
       y="291.17914"
       x="-6.6613381e-16"
       height="1.3229166"
       width="6.8791666"
       id="rect23"
       style="fill-opacity:1;stroke:none;stroke-width:0.17097601" />
    <rect
       style="fill-opacity:1;stroke:none;stroke-width:0.17097601"
       id="rect25"
       width="6.8791666"
       height="1.3229166"
       x="3.1789142e-08"
       y="293.4281"
       ry="0.59450316" />
    <rect
       ry="0.59450316"
       y="295.67706"
       x="-6.6613381e-16"
       height="1.3229166"
       width="6.8791666"
       id="rect27"
       style="fill-opacity:1;stroke:none;stroke-width:0.17097601" />
  </g>
</svg>

`);

module.exports = class PageHeader {
	constructor() {
		this._dom = null;
		this._setup_done = false;
	}

	_appendDomAndReturn(dom_to_add) {
		this._dom.append(dom_to_add);
		return dom_to_add;
	}

	setup(dom, cb) {
		this._dom = dom;
		this._dom.css("visibility", "hidden");

		this._logout_button = this._appendDomAndReturn(_makeAbsElem("a", {
			"font-size": "17px",
			// "color": "#79869a",
			// "color": "red",
			"width": "18px"
		}));
		this._logout_button.addClass("icon-logout").attr("id", "logout-button");;

		this._username = this._appendDomAndReturn(_makeAbsElem("a", {
			// "font-size": "17px",
			// "color": "#c6cfda",
			// "color": "red",
		}));
		this._username.attr("href", "/student/profile");
		this._username.addClass("data-student-name").attr("id", "title-student-name");


		this._logo = this._appendDomAndReturn(_makeAbsElem("div", {
			"background-size": "contain",
			"background-repeat": "no-repeat",
			"background-position": "center",
		}));


		this._menu_button = this._appendDomAndReturn(_makeAbsElem("div", {

		}));

		this._menu_button.append(menuButtonSvg);
		// $(menuButtonSvg).css("fill", "red");

		this._menu_button.addClass("menu-toggler-v2").attr("id", "sidebar-toggler-in-header");

		
		// iedu
		// let img_path = "http://staging.ieducentre.com/UserUploads/8cc906f41f9b5522d7ed03379a15b581/logo/student_portal/home_logo.PNG?t=636271617692395000";
		// gym
		// let img_path = "https://gymkraft.ieducentre.com/UserUploads/1681e5bbe5d51b6ec85c9ef83f951e19/logo/student_portal/home_logo.PNG?t=636416023588066496";

		// let img_path = "http://www.exclusivelyelectrical.co.uk/sites/default/files/Exclusively%20logos%20wide%2017.jpg";

		let img_path = GLOBAL_LOGO_URL;

		let image = new Image();
		image.name = img_path;
		let self = this;
		image.onload = function(){
			self._logo.css({"background-image": "url(" + img_path + ")"});
			self._logo_dimensions = {width: this.width, height: this.height};
			self._setup_done = true;
			self.layout();
			self._dom.css("visibility", "");

			if (cb) {
				cb();
			}
		};
		image.src = img_path;

		
	}

	layout() {
		if (!this._setup_done) return;
		let w = this._dom.width();
		let h = this._dom.height();

		let v_margin = 10;
		let h_margin = 12;
		let h_username_margin = 24;

		let half_h = h/2;
		let logout_left = w-this._logout_button.width()-h_margin;
		_setPos(this._logout_button,
			logout_left, half_h-(this._logout_button.height()/2),
		);

		let username_left = logout_left-this._username.width() - h_username_margin;
		_setPos(this._username,
			username_left, half_h-(this._username.height()/2),
		);

		let target_logo_height = 50;
		let logo_ar = this._logo_dimensions.width/this._logo_dimensions.height;

		let v_margin_space = h - target_logo_height;
		let logo_top = v_margin_space/2;

		let menu_button_top = half_h-(this._menu_button.height()/2);

		let logo_natural_width = target_logo_height * logo_ar;



		let menu_button_right = null;

		if (_isDesktop()) {
			if (_isDesktopSidebarVisible()) {
				let sidebar_w = $(".page-sidebar-wrapper").width();
				menu_button_right = sidebar_w;
			} else {
				// Not open, but we don't want it to jump when we /do/ open it
				// So calculate where it /will/ be.
				// menu_button_right = Math.round(((w >= 1200) ? 0.1666666667 : 0.25) * w);
				menu_button_right = 250;
			}
		} else {
			menu_button_right = username_left - h_username_margin;
		}


		let menu_button_left = menu_button_right - this._menu_button.width();
		_setPos(this._menu_button,
			menu_button_left, menu_button_top,
		);

		let logo_left = h_margin;
		let logo_constrained_width = menu_button_left - logo_left - h_margin;
		let logo_width = (logo_constrained_width < logo_natural_width) ? logo_constrained_width : logo_natural_width;

		if ((logo_constrained_width > logo_natural_width) && (_isDesktop())) {
			logo_left += (logo_constrained_width - logo_natural_width)/2;
		}

		_setPos(this._logo, Math.floor(logo_left), Math.floor(logo_top), Math.round(logo_width), Math.round(target_logo_height));

	}
};
