"use strict";

var $ = require("jquery");

var field_types = require("../../../../../student-views-common/fields/field_types");

var BootstrapDialog = require("bootstrap3-dialog");

var common = require("../../common");

const SECTION_TEMPLATE = `
	
	<div class="register-form-section"">
		<h3 class="section-header">FOOFOO</h3>


		<div class="section-sets-container"></div>

		<a href="javascript:;" class="btn green-haze add-btn hide">
			Add
		</a>

	</div>
`;

const SET_TEMPLATE = `
	<div class="section-form-fields-set">

		<h4 class="set-heading"></h4> 

		<div class="section-form-fields" class="">
		</div>

	</div>
`;


module.exports = class RegisterStudentPage {

	constructor (frontend) {
		this.state = {
			frontend: frontend
		};
	}

	load (cb) {
		var api_user = this.state.frontend.state.api_user;

		let get_string = this.state.frontend.get_string.bind(this.state.frontend);

		let redir_loc = common.get_args()["redirect_to_url"];
		let redir_url = redir_loc ?  (document.location.origin + decodeURIComponent(redir_loc)) : "/video/course_search";


		console.log(redir_url);


		api_user.fetch_data_promise("/video/courses/check_is_student", null)
		.catch(e => {})
		.then(is_student => {
			if (is_student == "yes") {

				if (document.location.host != "localhost:8080") {
					$("#register-student-form").addClass("hide");
				}

				BootstrapDialog.show({
					message: get_string("already_student"),
					title: get_string("already_student_header"),
					cssClass: "very-high-zindex",
					type: BootstrapDialog.TYPE_SUCCESS,
					buttons: [

						{
							label: get_string("proceed"),
							action: function(dialogItself){
								dialogItself.close();
								if (document.location.host != "localhost:8080") {
									window.location = redir_url;
								}
								
							}
						}
					],

					onhide: () => {
						
						if (document.location.host != "localhost:8080") {
							window.location = redir_url;
						}
						
					}
				});


			}
		});


		let add_set = (set, section_info, section_dom) => {


			let set_dom = $($.parseHTML(SET_TEMPLATE));

			set_dom.find(".set-heading").text(set.Heading);



			let fields_to_add = [];

			for (let field_name in section_info.fields) {

				// let field_value = res.fields_data[field_name];
				let field_info = section_info.fields[field_name];
				// let field_value = "";
				let field_value = field_info.value || undefined;

				if (!field_info.hide) {
					let to_add = field_types[field_info.type].render(field_name, field_value, field_info);

					// $(to_add).find(".form-control").on("change select keyup blur", function(){
					// 	let val_for_validation = field_types[field_info.type].get(to_add, field_info);
					// 	let validation_result = field_types[field_info.type].validate(val_for_validation, field_info);
					// 	console.log("V: " + validation_result);
					// });

					$(to_add).find(".form-control").on("change select keyup blur", function(){
						let val_for_validation = field_types[field_info.type].get(to_add, field_info);
						let validation_result = field_types[field_info.type].validate(val_for_validation, field_info);
						if (validation_result) {
							$(to_add).find(".error-popup").remove();
							$(to_add).find(".form-control").removeClass("field-error");
						}
						console.log("V: " + validation_result);
					});




					fields_to_add.push(to_add);

				}
				
			}

			set_dom.find(".section-form-fields").append(fields_to_add);
			section_dom.find(".section-sets-container").append(set_dom);


		};



		let add_section = (section_info, section_id) => {

			if (!section_info) {
				return;
			}

			let section_dom = $($.parseHTML(SECTION_TEMPLATE));
			section_dom.attr("data-section-name", section_id);

			section_dom.find(".section-header").text(section_info.header);

			let sets_to_add = section_info.fixed_multiple_instances || [{Heading: ""}];




			for (let set of sets_to_add) {

				add_set(set, section_info, section_dom);
			
			}

			console.log(section_info.dynamic_multiple);




			$(".register-container").append(section_dom);

			if (section_info.dynamic_multiple) {
				section_dom.find(".add-btn").removeClass("hide").click(e => {
					e.preventDefault();
					add_set({Heading: ""}, section_info, section_dom);
					this.state.frontend.fill_height(".page-sidebar, #quick-access");
				});
			}



		};

		let add_parent = (container_dom, parent_info) => {

		};

		api_user.fetch_data_promise("/video/courses/get_student_reg_fields", null).then(res => {

			console.log(res);

			this.state.fields_info = res;



			// fill_section($("#student-info"), res.student);
			add_section(res.student, "student");
			add_section(res.parent, "parent");
			add_section(res.school, "school");
			add_section(res.result, "result");
			add_section(res.other, "other");



			$("#btn-register").click(e => {
				e.preventDefault();

				let collected_data = {};
				let errors = [];
				$(".error-popup").remove();

				$(".register-form-section").each((_, section) => {
					let section_name = $(section).attr("data-section-name");
					let section_data = [];

					$(section).find(".section-form-fields-set").each((_, set) => {


						let to_update = {};

						$(set).find(".form-group").not("[data-disabled]").each((_, field) => {
							let field_name = $(field).attr("data-field-name");
							let field_type = $(field).attr("data-field-type");
							let field_required = $(field).attr("data-required") || false;

							let field_info = this.state.fields_info[section_name].fields[field_name];
							console.log(field_name + ":");
							console.log(field_info);

							let field_value = field_types[field_type].get(field, field_info);
							console.log(field_value);

							if (field_info.is_email_address && field_info.required) {
								if (!field_value || field_value == "") {
									errors.push({
										dom: field,
										error: get_string("field_required")
									});
								} else {
									let validation_result = field_types[field_type].validate(field_value, field_info);

									if (!validation_result) {
										errors.push({
											dom: field,
											error: get_string("email_invalid")
										});
									} else {
										to_update[field_name] = field_value;
									}
								}


							} else {

								let validation_result = field_types[field_type].validate(field_value, field_info);

								if (!validation_result) {
									errors.push({
										dom: field,
										error: get_string("field_required")
									});
								} else {
									to_update[field_name] = field_value;
								}


							}



							

							
						});

						section_data.push(to_update);


					});

					collected_data[$(section).attr("data-section-name")] = section_data;







				});


				console.log(collected_data);

				let create_error = (error) => {
					let div = $("<div/>").addClass("error-popup").text(error);
					return div;
				};

				if (errors.length > 0) {
					for (let error of errors) {
						let form_controls = $(error.dom).find(".form-control");
						$(form_controls).addClass("field-error");
						$(form_controls).first().parent().append(create_error(error.error));
					}

					$('html, body').animate({
						scrollTop: $(errors[0].dom).offset().top - $(".page-header").height()
					}, 200);

					return;
				}


				api_user.post_data_promise("/video/courses/do_student_registration",
					collected_data
				).then(res => {
					BootstrapDialog.show({
						message: get_string("registration_success"),
						title: get_string("thank_you"),
						cssClass: "very-high-zindex",
						type: BootstrapDialog.TYPE_SUCCESS,
						buttons: [

							{
								label: get_string("proceed"),
								action: function(dialogItself){
									dialogItself.close();
									window.location = redir_url;
								}
							}
						]
					});
				}).catch(e => {
					console.log("FAILED: " + e);
					BootstrapDialog.show({
						message: get_string("internal_error"),
						title: get_string("registration_fail"),
						cssClass: "very-high-zindex",
						type: BootstrapDialog.TYPE_WARNING,
						buttons: [

							{
								label: get_string("close"),
								action: function(dialogItself){
									dialogItself.close();
									// window.location = redir_url;
								}
							}
						]
					});
				});
				this.state.frontend.fill_height(".page-sidebar, #quick-access");


			});
			
			cb();
		});
	}



};