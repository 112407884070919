"use strict";

var $ = require("jquery");
var common = require("../../common");
var moment = require("moment");
var Cookies = require("cookies-js");
const base64url = require('base64-url');

function WatchMyVideoPage(frontend) {
	this.state = {
		frontend: frontend
	};
}

WatchMyVideoPage.prototype.load = function(cb) {

	var args = common.get_args();

	let course_lesson_id = args["course_lesson"];
	let module_lesson_id = args["module_lesson"];

	let id = undefined;
	let type = undefined;

	let course_video_data = null;

	let selected_video = null;

	if (args["course_video_data"]) {
		type = "course";
		course_video_data = JSON.parse(base64url.decode(args["course_video_data"]));
		selected_video = course_video_data.flat[course_video_data.index_into_flat];
		console.log(selected_video);
		id = selected_video.cl_id;

	} else if (typeof course_lesson_id != "undefined") {
		id = course_lesson_id;
		type = "course";
	} else 	if (typeof module_lesson_id != "undefined") {
		id = module_lesson_id;
		type = "module";
	} else {
		console.log("Error: Invalid type");
		window.location = "/jdp?page=my_videos";
	}


	var api_user = this.state.frontend.state.api_user;

	let maybe_fid = (args.file_id ? ("&file_id=" + args.file_id) : "");

	if (selected_video) {
		maybe_fid = selected_video.f_id;
	}

	// if (maybe_fid) {

	// }
	Cookies.set("last_video_id", `${id}-${maybe_fid||"MODULE"}`);


	if (args.context) {
		let context = JSON.parse(decodeURIComponent(args.context));
		console.log(context);

		// TODO: Cleanup duplication here

		api_user.fetch_data("/student/my_videos/get_my_videos_all", null, (err, videos) => {
			let the_product = videos.product_types.filter(t => t.id == context.level2_type)[0].products.filter(p => p.id == context.level2_id)[0];
			console.log("the_product");
			console.log(the_product);

			let encountered_ids = new Set();

			let the_product_contents = the_product.contents.filter(c => parseInt(c.month.split(',')[0]) == context.level3_index).filter(c => {
				let _id = `${c.id}-${c.file_id}`;
				if (encountered_ids.has(_id)) return false;
				encountered_ids.add(_id); return true;
			});

			the_product_contents.sort((a,b) => {
				return a.sort_by.localeCompare(b.sort_by);
			});

			let grouped = common.group_videos_list(the_product_contents, the_product.cou_code);

			let recombobulated = [].concat.apply([], (grouped.map(thing => thing.entries)));
			console.log("recombobulated:");
			console.log(recombobulated);

			let this_video_i = recombobulated.findIndex(v => {
				return (v.id == args.course_lesson) && (v.file_id == args.file_id)
			});

			console.log(recombobulated[this_video_i]);

			let this_video = recombobulated[this_video_i];

			$(".page-title").text("Now Playing: " + fix_html_ents(this_video.display_name));


			let back_target = `/student/my_videos?mode=level3&level2_type=${context.level2_type}&level2_id=${context.level2_id}&level3_index=${context.level3_index}`;

			let back = $("<a/>").attr("href", back_target).addClass("back-video-button").text("Back to List").css({
				// "float": "left",
				// "margin-top": "6px",
				// // "width": "100%",
				// "text-align": "left",
				// "margin-left": "9px",
			});

			$("#prevnext").append(back);



			if (this_video_i > 0) {

				let prev_video = recombobulated[this_video_i-1];

				let target = `/student/watch_my_video?course_lesson=${prev_video.id}&file_id=${prev_video.file_id}&context=${args.context}`;

				let prev = $("<a/>").attr("href", target).addClass("prev-video-button").text("Previous" /*+ prev_video.display_name*/).css({
					// "float": "left",
					// "margin-top": "6px",
					// // "width": "100%",
					// "text-align": "left"
				});

				$("#prevnext").append(prev);

			}

			if (this_video_i < (recombobulated.length-1)) {

				let next_video = recombobulated[this_video_i+1];

				let target = `/student/watch_my_video?course_lesson=${next_video.id}&file_id=${next_video.file_id}&context=${args.context}`;

				let next = $("<a/>").attr("href", target).addClass("next-video-button").text("Next Video: " + fix_html_ents(next_video.display_name)).css({
					// "float": "right",
					// "margin-top": "6px",
					// // "width": "100%",
					// "text-align": "right"
				});

				$("#prevnext").append(next);

			}








		});
	} else if (selected_video) {

		$(".page-title").text("Now Playing: " + selected_video.name);


		let back_target = course_video_data.return_url;

		let back = $("<a/>").attr("href", back_target).addClass("back-video-button").text("Back to List").css({
			// "float": "left",
			// "margin-top": "6px",
			// // "width": "100%",
			// "text-align": "left",
			// "margin-left": "9px",
		});

		$("#prevnext").append(back);

		let base_watch_url = "/student/watch_my_video?course_video_data=";

		if (course_video_data.index_into_flat > 0) {

			// let prev_video = course_video_data.flat[course_video_data.index_into_flat-1];

			let data = {
				flat: course_video_data.flat,
				index_into_flat: course_video_data.index_into_flat-1,
				return_url: course_video_data.return_url
			};
			let b64 = base64url.encode(JSON.stringify(data));
			let url = base_watch_url + b64;


			let prev = $("<a/>").attr("href", url).addClass("prev-video-button").text("Previous" /*+ prev_video.display_name*/).css({
				// "float": "left",
				// "margin-top": "6px",
				// // "width": "100%",
				// "text-align": "left"
			});

			$("#prevnext").append(prev);

		}

		if (course_video_data.index_into_flat < (course_video_data.flat.length-1)) {

			let data = {
				flat: course_video_data.flat,
				index_into_flat: course_video_data.index_into_flat+1,
				return_url: course_video_data.return_url
			};
			let b64 = base64url.encode(JSON.stringify(data));
			let url = base_watch_url + b64;

			let next = $("<a/>").attr("href", url).addClass("next-video-button").text("Next Video: " + course_video_data.flat[course_video_data.index_into_flat+1].name  ).css({
				// "float": "right",
				// "margin-top": "6px",
				// // "width": "100%",
				// "text-align": "right"
			});

			$("#prevnext").append(next);

		}



	}

	let short_url = "#" + id + "," + (maybe_fid||"");


	const api_url = (type == "course")
	? "/student/my_videos_rewrite/get_my_video_one_rewrite?type="+ type + "&id=" + id + "&file_id=" + maybe_fid + "&page_url=" + encodeURIComponent(short_url)
	: "/student/my_videos/get_my_video_one/"+ type + "/" + id + "?file_id=" + maybe_fid + "&page_url=" + encodeURIComponent(short_url);

	api_user.fetch_data(api_url, null, (err, video) => {

		if (err) {
			console.log(err);
			if (err.indexOf("not_enrolled") != -1) {
				$(".page-title").text("Cannot access video");
				$(".video-description").text("You do not have the rights to access this video.");
		
			} else if (err.indexOf("not_found") != -1) {
				$(".page-title").text("Not Found");
				$(".video-description").text("Video not found.");
			} else {
				$(".page-title").text("Video Not Available");
				$(".video-description").text("This lesson's video is not yet available. Please check back later.");
			}
		} else {


			console.log(video);

			$(".video-title").text(video.name);
			$(".video-description").html(video.description);

			common.start_video(video);

			if (video.learning_resources && video.learning_resources.length > 0) {
				$("#attachments-header").removeClass("hide");
				video.learning_resources.forEach(attachment => {
					let url =
						this.state.frontend.state.api_base +
						"/video/modules/download_learning_resource?lrid="
						+ attachment.lrid
						+ "&fid="
						+ attachment.fid
					;

					$(".attachments").append(
						$("<a/>")
							.addClass("attachment")
							.attr({"href": url})
							.text(attachment.name)
					);
				});

			} else if (video.attachments && video.attachments.length > 0) {
				$("#attachments-header").removeClass("hide");
				video.attachments.forEach(attachment => {
					let url = (type == "course")
					? this.state.frontend.state.api_base + "/student/course/download_course_attachment?clid=" + id + "&file_id=" + attachment.id
					: this.state.frontend.state.api_base + "/student/course/download_attachment?id=" + attachment.id;
					$(".attachments").append($("<a/>").addClass("attachment").attr({"href": url, /*"target": "_blank"*/}).text(attachment.name));
				});

			}

			let refresh_comments = (comments) => {

				$(".video-comments").empty();

				// comments = comments.sort((a,b) => (new Date(a.date)).getTime() < (new Date(b.date)).getTime());
				if ($("[name=sort-comments-by]").val() != "oldest") {
					comments = [].concat(comments).reverse();
				}

				comments.forEach(comment => {

					let comment_dom = $($.parseHTML(`
						<div class="comment">
							<!--<div class="comment-photo"></div>-->
							<div class="comment-content">
								<div class="comment-author"></div>
								<div class="comment-date"></div>
								<div class="comment-body"></div>
							</div>
						</div>
					`));

					comment_dom.find(".comment-author").text(comment.author);
					comment_dom.find(".comment-body").text(comment.body);
					comment_dom.find(".comment-date").text(moment(comment.date).fromNow());


					comment_dom.find(".comment-content").css(
						"background-image",
						comment.author_id
							? (
								"url("
								+ window["_uploads_base"]
								+ "/photos/user"
								+ comment.author_id
								+ ".jpg)"
							) : ""
					);


					$(".video-comments").append(comment_dom);

				});

				if (comments.length < 1) {
					$(".video-comments").append(`
						<div class="comment">
							<div class="comment-photo"></div>
							<div class="comment-content">
								<div class="comment-author">No comments yet</div>
								<div class="comment-body">Be the first to add a comment</div>
							</div>
						</div>
					`);
				}


			};

			let comments = [];

			let do_fetch_comments = () => {

				console.log("Fetching");


				api_user.fetch_data("/student/my_videos/get_comments?type=" + type + "&id=" + id + maybe_fid, {a: "b"}, (err, comments2) => {

					console.log(err);
					console.log(comments2);

					if (err || (comments2 === null)) {
						// return;
						
					} else {
						$(".comments-area").removeClass("hide");
						comments = comments2;
						refresh_comments(comments2);
					}
					
					this.state.frontend.fill_height(".page-sidebar, #quick-access");






				});





			};

			$("[name=sort-comments-by]").change(_ => {
				refresh_comments(comments);
			});


			let toggle_btn = () => {
			 	if ($("#my-comment").val().trim() != "") {
			 		$("#add-comment-btn").removeClass("disabled");
			 	} else {
			 		$("#add-comment-btn").addClass("disabled");
			 	}
			};

			toggle_btn();

			$("#my-comment").on("change keyup", () => {
				toggle_btn();
			});



			$("#add-comment-btn").click(e => {
				e.preventDefault();

				if ($("#add-comment-btn").hasClass("disabled")) {
					return;
				}



				api_user.post_data("/student/my_videos/add_comment", {
					type: type,
					id: id,
					review: $("#my-comment").val(),
					file_id: args.file_id || undefined
				}, (err, res) => {
					if (err) {

						alert("Unknown error.");

					} else {
						console.log("Added comment");

						do_fetch_comments();
						$("#my-comment").val("");

						console.log(res);
					}
				});





			});




			do_fetch_comments();

			


			this.state.frontend.fill_height(".page-sidebar, #quick-access");



		}



		cb();
	});



};

module.exports = WatchMyVideoPage;