"use strict";

var common = require("../common");
var $ = require("jquery");

import React, { useState } from 'react';
var ReactDOM = require("react-dom");

import {CardDetailsTable} from "./ccard_parts/CardDetailsTable";
import {CreditCardForm} from "./ccard_parts/CreditCardForm";
import {PaymentDates} from "./ccard_parts/PaymentDates";

var Cookies = require("cookies-js");

import {CardElement, Elements, ElementsConsumer, useStripe, useElements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

function _set_title(new_title) {
  $("title, .page-title").text(new_title);
}


function StripeLogo() {
  return <img style={{width: 150, marginTop: 10}} src="/static/img/stripe_logo_cropped.png" alt="Powered by Stripe" />
}

class CreditCardPage extends React.Component {


  _calculateInitialCardFormType() {
    if (this.props.config.card_saved) {
      return "replace";
    } else {
      if (this.props.page_mode == "pay") {
        return "add_or_onetime";
      } else {
        return "add";
      }
    }
  }

  _shouldGoDirectlyToCardForm() {
    return (this.props.page_mode == "pay") && (!this.props.config.card_saved);
  }

  constructor(props) {
    super(props);
    this.state = {
      card_auto_state: props.config.card_saved_auto,
      card_auto_dirty: false,
      page: this._shouldGoDirectlyToCardForm() ? "card_form" : "overview",
      cardFormType: this._calculateInitialCardFormType(),
      isLoading: false,
    }
  }

  componentDidMount() {
    this._mountOrUpdate();
    // console.log(this.props.stripe);
  }

  componentWillUnmount() {

  }

  async _doWithLoading(block) {
    this.setState({
      isLoading: true,
    });

    try {
      await block();
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  async _uiOnClickPayWithSavedCard() {
    await this._doWithLoading(async _ => {
      try {
        await jdp.api_user.post_data_promise(`/student/fees_rewrite/stripe_recur_pay`, {});
        alert(nss("106:ccard_thank_you_pay"));
        window.location.href = "/student/fees";
      } catch(e) {
        console.log("saved card err:");
        console.log(e);
        alert(nss("106:ccard_unknown_error"));
      }
    });
  }

  async _uiOnClickDeleteCard() {
    if (confirm("Are you sure you want to delete your saved card?")) {
      await this._doWithLoading(async _ => {
        await jdp.api_user.post_data_promise(`/student/fees_rewrite/stripe_recur_delete`, {});
        alert("Your saved card has been deleted.");
        if (jdp.args.return) {
          window.location.href = jdp.args.return;
        } else {
          window.location.reload();
        }
      });
    }

  }

  async _uiOnClickUpdateCardAutoSetting() {
    await this._doWithLoading(async _ => {
      await jdp.api_user.post_data_promise(`/student/fees_rewrite/stripe_recur_set_auto`, {
        auto_payment: this.state.card_auto_state
      });
      window.location.reload();
    });
  }



  _renderAddCardButton() {
    return <button className="btn green-haze" onClick={_=>{
      this.setState({
        cardFormType: "add",
        page: "card_form",
      });
    }}>{nss("106:ccard_btn_add")}</button>;
  }

  _renderPayWithSavedCard() {
    return <React.Fragment>
      <CardDetailsTable saved={this.props.config.saved} />

      {(this.props.page_mode == "pay") && <div>
        <h2>{window.currency + parseFloat(this.props.pay_amount).toFixed(2)}</h2>
      </div>}

      <button className="btn green-haze" disabled={this.state.isLoading} onClick={_=>{
        this._uiOnClickPayWithSavedCard();
      }}>{nss("106:ccard_btn_pay_saved", [window.currency + parseFloat(this.props.pay_amount).toFixed(2)])}</button>

      {this.state.isLoading && <div className="jdp-spinner" style={{margin: 0, marginTop: 20, marginBottom: 40}}>
        <div className="cube1"></div>
        <div className="cube2"></div>
      </div>}

      {!this.state.isLoading && <a
        style={{display: "block", marginTop: 10}}
        href={"/jdp?page=credit_card&return=" + encodeURIComponent(document.location.href) }>
        {nss("106:ccard_btn_manage_saved")}
      </a>}

      <StripeLogo />

    </React.Fragment>
  }


  _renderOverview() {
    if (!this.props.config.card_saved) {
      return <div>
        <p>{nss("106:ccard_note_no_card")}</p>
        {null && this._renderAddCardButton()}
      </div>
    }

    return <div>
      <CardDetailsTable saved={this.props.config.saved} />

      <div style={{display: "flex"}}>
        <button className="btn green-haze" style={{display: "block", marginTop: 10, marginLeft: 0}} onClick={_=>{
          this.setState({
            cardFormType: "replace",
            page: "card_form",
          });
        }}>{nss("106:ccard_btn_replace")}</button>

        {jdp.args.return && <button className="btn green-haze" style={{display: "block", marginTop: 10, marginLeft: 20}} onClick={_=>{
          window.location.href = jdp.args.return;
        }}>{nss("106:ccard_btn_return")}</button>}

      </div>



      <button className="btn green-haze" style={{display: "block", marginTop: 10, marginLeft: 0}} onClick={_=>{
        this._uiOnClickDeleteCard();
      }}>{nss("106:ccard_btn_delete")}</button>

      <div style={{display: "flex", marginTop: 10, }}>
        <div>
          <input type="checkbox" checked={this.state.card_auto_state} onChange={_=>{
            this.setState({
              card_auto_state: !this.state.card_auto_state,
              card_auto_dirty: true,
            });
          }} />
        </div>
        <div style={{marginLeft: 10}}>
            
          <p>Use this card to make automatic monthly payments?</p>
          <PaymentDates pay_dates={this.props.config.pay_dates}/>
          <p>If this option is enabled, your payment card information will be stored by <a target="_blank" href="https://stripe.com/">Stripe</a>.</p>
          <p>Automatic payments can be cancelled at any time.</p>

          {(false && !this.state.card_auto_state && !this.state.card_auto_dirty) && <button className="btn green-haze" style={{display: "block", marginTop: 10, marginLeft: 0}} onClick={_=>{
            this.setState({
              card_auto_state: true,
              card_auto_dirty: true,
            });
          }}>{nss("106:ccard_btn_grantauto")}</button>}

          {(false && this.state.card_auto_state && !this.state.card_auto_dirty) && <button className="btn green-haze" style={{display: "block", marginTop: 10, marginLeft: 0}} onClick={_=>{
            this.setState({
              card_auto_state: false,
              card_auto_dirty: true,
            });
          }}>{nss("106:ccard_btn_revokeauto")}</button>}

          {(this.state.card_auto_dirty) && <button className="btn green-haze" style={{display: "block", marginTop: 10, marginLeft: 0}} onClick={_=>{
            this._uiOnClickUpdateCardAutoSetting();
          }}>{nss("106:ccard_btn_save")}</button>}


        </div>
      </div>

    </div>;
  }

  _renderCardForm() {
    return <div>

      {(this.props.page_mode == "pay") && <h2>{window.currency + parseFloat(this.props.pay_amount).toFixed(2)}</h2>}
      


      {((this.props.page_mode != "pay") && (this.state.cardFormType == "add")) && <div>
        <h2>{nss("106:ccard_btn_add")}</h2>
      </div>}

      {((this.props.page_mode != "pay") && (this.state.cardFormType == "replace")) && <div>
        <h2>{nss("106:ccard_btn_replace")}</h2>
      </div>}


      <CreditCardForm config={this.props.config} cardFormType={this.state.cardFormType} pay_amount={this.props.pay_amount} />


      <StripeLogo />



    </div>
  }

  render() {
    if (!this.props.stripe || !this.props.elements) return null;
    if (!this.props.config.enabled) return null;

    if (this.state.page == "card_form") {
      return this._renderCardForm();
    } else if (this.props.page_mode == "pay") {
      return this._renderPayWithSavedCard();
    } else {
      return this._renderOverview();
    }

    
  }

  _mountOrUpdate() {
    _set_title(
        (this.props.page_mode == "pay")
      ? nss("101:title_pay_credit_card")
      : nss("101:title_payment_settings"))
    ;
  }

  componentDidUpdate(prevProps, prevState) {
    this._mountOrUpdate();
  }
}

var _class = class {
  constructor(jdp) {
    this.jdp = jdp;
  }


  get_basic_info() {
    return {
    };
  }

  async on_load() {
    window.jdp = this.jdp;
    $(".page-container").css("min-height", "calc(100vh - 60px)");
    _set_title("");

    const fees = this.jdp.args.is_pay_flow ? (await jdp.api_user.fetch_data_promise(`/student/fees_rewrite/get_not_paid_full`, null)) : null;
    console.log(fees);

    if (this.jdp.args.is_pay_flow && !fees.total_amount) {
      window.location = "/student/fees";
      return;
    }

    const stripe_config = await jdp.api_user.fetch_data_promise(`/student/fees_rewrite/stripe_get_config`, null);
    console.log(stripe_config);

    // const dates = await jdp.api_user.unauthed_absolute_fetch_promise(stripe_config.pay_dates_link + "?return_json=true", null);
    // console.log("dates:");
    // console.log(dates);

    

    const stripePromise = loadStripe(stripe_config.pk);
    await stripePromise;

    const page = <Elements stripe={stripePromise}>
      <ElementsConsumer>
        {consumerProps => (
          <CreditCardPage
            config={stripe_config}
            page_mode={this.jdp.args.is_pay_flow ? "pay" : "manage"}
            pay_amount={this.jdp.args.is_pay_flow ? fees.total_amount : undefined}
            elements={consumerProps.elements}
            stripe={consumerProps.stripe}
          />
        )}
      </ElementsConsumer>
    </Elements>

    ReactDOM.render(page, this.jdp.main_elem[0]);
  }
};

module.exports = _class;
window.register_jdp((require('path')).basename(__filename, ".jsx"), _class);