"use strict";
var React = require("react");
var DatePicker = require("./DatePicker");

module.exports = class DateField extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {

    return <React.Fragment>
      <DatePicker
        className="form-control form-control-inline"
        defaultDate={this.props.value}
        dateChange={date => {
          if (typeof(date) == "undefined") return this.props.onChange(null);
          console.log(date);
          // if (!(date instanceof Date && !isNaN(date))) return;
          this.props.onChange(moment(date).format("YYYY-MM-DD"));
        }}
      />      
      {this.props.error && <p style={{color: "red", marginTop: 5,}}>{this.props.error}</p>}
    </React.Fragment>;


    // return <select id={this.props.fieldKey} value={this.props.value} className="form-control" onChange={ev => this.props.onChange(ev.target.value)}>
    //   {show_empty && <option key={this.props.fieldKey + "_default"} value={this.props.value}></option> }
    //   {this.props.dropdown_options.map(o => <option key={o.id} value={o.id}>{o.name}</option> )}
    // </select>
  }
}