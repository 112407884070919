"use strict";

var common = require("../common");
var $ = require("jquery");
var moment = require("moment");
if (!window.moment) {
	window.moment = moment;
}

require("bootstrap-datepicker");
var BootstrapDialog = require("bootstrap3-dialog");

function add_component_dropdown(_parent) {
	return common.add_elem("select", `
		width: auto;
		display: inline;
	`, "form-control", _parent);
}

class DatePickerControl {
	constructor(with_date) {
		if (!with_date) with_date = moment();
		this.main_elem = common.add_elem("input", `
			padding-top: 6px;
			padding-bottom: 6px;
			padding-left: 6px;
			width: auto;
			border-radius: 3px;
			border: solid 1px #e0e0e0;
			`, "").val(with_date.format("DD MMM YYYY"));
		this.main_elem.datepicker({
			format: "dd M yy",
			// startDate: with_date.toDate(),
			// container: ".jdp-main"
		});
	}

	get_elem() {
		return this.main_elem;
	}
}

class DateControl {
	constructor(with_date) {
		this.main_elem = common.add_elem("div", ``, "");
		this.day_select = add_component_dropdown(this.main_elem);
		this.month_select = add_component_dropdown(this.main_elem).css({"margin-left": "5px"});
		this.year_select = add_component_dropdown(this.main_elem).css({"margin-left": "5px"});

		this.day_select.change(this.on_change.bind(this));
		this.month_select.change(this.on_change.bind(this));
		this.year_select.change(this.on_change.bind(this));

		if (!with_date) {with_date = moment();}

		// this.date = with_date;
		this.set_date(with_date);

		


		// let years = [];
		// for (let y = with_date.year() - num_years_before; y <= with_date.year() + num_years_future; y++) {
		// 	let year_opt = $("<option/>").val(y).text(`${y}`);
		// 	// if (y == with_date.year()) {
		// 	// 	year_opt.attr("selected", "selected");
		// 	// }
		// 	this.year_select.append(year_opt);
		// }





		// let fill_m



		// this.on_update_date();
	}

	fill_years(selected_year, from, to) {
		this.year_select.empty();

		for (let y = from; y <= to; y++) {
			let year_opt = $("<option/>").val(y).text(`${y}`);
			if (y == selected_year) {
				year_opt.attr("selected", "selected");
			}
			this.year_select.append(year_opt);
		}
	}

	fill_months(selected_month_0i) {
		this.month_select.empty();

		let months_of_year = moment.monthsShort();
		for (let m = 0; m < 12; m++) {
			let text = months_of_year[m];
			let month_opt = $("<option/>").val(m).text(text);
			if (m == selected_month_0i) {
				month_opt.attr("selected", "selected");
			}
			this.month_select.append(month_opt);
		}
	}

	fill_days(selected_day_1i, days_in_month) {
		this.day_select.empty();

		for (let d = 1; d <= days_in_month; d++) {
			let text = `${d}`;
			let day_opt = $("<option/>").val(d).text(text);
			if (d == selected_day_1i) {
				day_opt.attr("selected", "selected");
			}
			this.day_select.append(day_opt);
		}
	}

	on_change() {
		this.set_date_from_components(this.year_select.val(), this.month_select.val(), this.day_select.val());
	}

	set_date_from_components(year, month_0i, day_1i) {
		console.log(`${year} ${month_0i} ${day_1i}`)
		let new_moment = moment([year, month_0i, 1]);
		console.log(new_moment.format());
		if (day_1i > new_moment.daysInMonth()) {
			day_1i = new_moment.daysInMonth();
		}
		new_moment.date(day_1i);
		this.set_date(new_moment);
	}

	set_date(date) {
		this.date = date;
		this.on_update_date();
	}

	get_moment() {
		return this.date;
	}

	on_update_date() {
		let num_years_before = 2;
		let num_years_future = 2;
		let y = this.date.year();
		this.fill_years(y, y - num_years_before, y + num_years_future )
		this.fill_months(this.date.month());
		this.fill_days(this.date.date(), this.date.daysInMonth());

		console.log(`New date: ${this.date.format()}`);

	}

	get_elem() {
		return this.main_elem;
	}
}

class TimeControl {
	constructor(hours, mins, is_pm) {
		this.main_elem = common.add_elem("div", ``, "");
		this.hours_select = add_component_dropdown(this.main_elem);
		this.mins_select = add_component_dropdown(this.main_elem).css({"margin-left": "5px"});
		this.mer_select = add_component_dropdown(this.main_elem).css({"margin-left": "5px"});

		this.hours_select.change(this.on_change.bind(this));
		this.mins_select.change(this.on_change.bind(this));
		this.mer_select.change(this.on_change.bind(this));

		// if (!with_date) {with_date = moment();}

		// // this.date = with_date;



		this.set_time(hours, mins, is_pm);

				


		// let years = [];
		// for (let y = with_date.year() - num_years_before; y <= with_date.year() + num_years_future; y++) {
		// 	let year_opt = $("<option/>").val(y).text(`${y}`);
		// 	// if (y == with_date.year()) {
		// 	// 	year_opt.attr("selected", "selected");
		// 	// }
		// 	this.year_select.append(year_opt);
		// }





		// let fill_m



		// this.on_update_();
	}

	fill_hours(hour) {
		this.hours_select.empty();

		for (let h = 1; h <= 12; h++) {
			let _h = h;
			// if (h == 0) _h = 12;
			// let text = ("0"+_h).substr(-2);
			let text = ""+_h;
			let hour_opt = $("<option/>").val(_h).text(text);
			if (_h == hour) {
				hour_opt.attr("selected", "selected");
			}
			this.hours_select.append(hour_opt);
		}
	}

	fill_mins(mins) {
		this.mins_select.empty();

		for (let m of ["00", "15", "30", "45"]) {
			let mins_opt = $("<option/>").val(m).text(m);
			if (parseInt(m) == mins) {
				mins_opt.attr("selected", "selected");
			}
			this.mins_select.append(mins_opt);
		}
	}

	fill_mer(is_pm) {
		this.mer_select.empty();

		for (let m of ["AM", "PM"]) {
			let mer_opt = $("<option/>").val(m).text(m);
			if ((is_pm && m=="PM") || (!is_pm && m=="AM")) {
				mer_opt.attr("selected", "selected");
			}
			this.mer_select.append(mer_opt);
		}
	}

	on_change() {
		this.set_time(parseInt(this.hours_select.val()), parseInt(this.mins_select.val()), this.mer_select.val()=="PM");
	}

	set_time(hours, mins, pm) {
		this.time = {hours: hours, mins: mins, pm: pm};
		this.on_update_time();
	}

	get_string() {
		let h_24 = -1;
		if (this.time.pm) {
			if (this.time.hours < 12) {
				h_24 = this.time.hours + 12;
			} else {
				h_24 = this.time.hours;
			}
		} else {
			if (this.time.hours == 12) {
				h_24 = 0;
			} else {
				h_24 = this.time.hours;
			}
			
		}

		let time_string = `${("0"+h_24).substr(-2)}:${("0"+this.time.mins).substr(-2)}`;
		return time_string;
	}

	on_update_time() {
		this.fill_hours(this.time.hours)
		this.fill_mins(this.time.mins);
		this.fill_mer(this.time.pm);

		// console.log(`New time: ${time_string}`);
	}

	get_elem() {
		return this.main_elem;
	}
}


class DateRangeControl {
	constructor(from, to) {
		this.main_elem = common.add_elem("div", ``, "");

		// this.from = new DateControl(from);
		this.from = new DatePickerControl(from);
		this.main_elem.append(this.from.get_elem().css("display", "inline-block"));

		this.main_elem.append($("<span/>").text("to").css({"margin-left": "5px", "margin-right": "5px"}));

		// this.to = new DateControl(to);
		this.to = new DatePickerControl(to);
		this.main_elem.append(this.to.get_elem().css("display", "inline-block"));
	}

	get_elem() {
		return this.main_elem;
	}

	get_selections() {
		return {
			from: moment($(this.from.get_elem()).val(), "DD MMM YYYY").clone().startOf("day"),
			to:   moment($(this.to.get_elem()).val(), "DD MMM YYYY").clone().endOf("day")
		};
	}
}

class TimeRangeControl {
	constructor(fromh, fromm, fromp, toh, tom, top) {
		this.main_elem = common.add_elem("div", ``, "");

		this.from = new TimeControl(fromh, fromm, fromp);
		this.main_elem.append(this.from.get_elem().css("display", "inline-block"));

		this.main_elem.append($("<span/>").text("to").css({"margin-left": "5px", "margin-right": "5px"}));

		this.to = new TimeControl(toh, tom, top);
		this.main_elem.append(this.to.get_elem().css("display", "inline-block"));
	}

	get_elem() {
		return this.main_elem;
	}

	get_selections() {
		return {
			from: this.from.get_string(),
			to:   this.to.get_string()
		};
	}
}

function exists(thing) {
	return (thing !== null) && (typeof thing != "undefined");
}


var _class = class {
	constructor(jdp) {
		this.jdp = jdp;
		this.frontend = this.jdp.frontend;
		// this.packages = [];
		console.log("book_sessions");
		$(".msg-no-results").remove(); // workaround

		// For debugging
		window.get_selected_rows = this.get_selected_rows.bind(this);

	}

	get_basic_info() {
		return {
			title_sid: "101:title_book_sessions",
			record_type_plural: window.nss("106:record_type_plural_lessons"),
			dont_add_notfound_message: true,
		};
	}

	clear_table() {
		$(this.table).find("tbody tr").remove();
	}

	get_selected_rows() {
		let boxes = $(this.table).find("tbody tr td span input");
		console.log(boxes);
		let selected = [];
		for (let box of boxes) {
			if ($(box).is(":checked")) {
				let _id = parseInt($(box).parent().parent().parent().attr("data-row-id"));
				selected.push(_id);
			}
		}

		return selected;
	}

	fill_table() {

		this.clear_table();

		console.log(this.avail_sessions);

		if (!this.avail_sessions) {
			this.avail_sessions = {legend: [], rows: []};
		}

		let session_data = this.avail_sessions.rows;

		if (!this.assignSameBranch) {
			let selected = this.branch_select.val();
			session_data = session_data.filter(sd => {
				return (sd.b_id == selected);
			});
		}

		if (session_data.length < 1) {
			$(".msg-no-results").removeClass("hide");
			return;
		} else {
			$(".msg-no-results").addClass("hide");
		}

		common.build_table(this.table, this.avail_sessions.legend, session_data, "cl_id", {
			checkbox_rows: true,
			checkbox_rows_mobile_label: "Book this lesson?",
			cb_on_check_changed: _ => {
				let checked = this.get_selected_rows();
				if (checked.length > 0) {
					this.book_button.removeClass("disabled");
				} else {
					this.book_button.addClass("disabled");
				}
			}
		});

		let rows = $(this.table).find("tbody tr");
		for (let row of rows) {
			// let first_col = $(row).find("td").first().find(".data-span");
			// let first_col_contents = first_col.html();
			// let a = $("<a/>").attr("href", common.build_jdp_url("confirm_sessions", {cl_id: $(row).attr("data-row-id"), pu_id: this.selected_puid, data: JSON.stringify(session_data.find(sd => (sd.cl_id==$(row).attr("data-row-id"))))}));
			// a.html(first_col_contents);
			// $(first_col).empty().append(a);
		}





	}

	on_layout() {

	}

	async do_booking() {
		let session_data = this.avail_sessions.rows.filter(r => r.Vacancy);
		let selected = this.get_selected_rows();

		let datas = [];

		for (let session_clid of selected) {
			let _data = session_data.find(sd => (sd.cl_id==session_clid));
			datas.push(_data);
		}

		// let _package = this.packages.find(p=>p.pu_id==this.selected_puid);
		// let rn_link = _package.Receipt_No_Link;
		// if (rn_link.startsWith("/")) rn_link = rn_link.substr(1);
		// let receipt_url = window.location.protocol + "//" + window.location.host + "/" + rn_link;
		let receipt_url = window.location.protocol + "//" + window.location.host + "/student/fees_past_receipt?mode=request";



		let _datas = JSON.stringify(datas);
		let url = common.build_jdp_url("confirm_sessions", {
			pu_id: this.selected_puid,
			receipt_url: receipt_url,
			datas: _datas
		});
		window.location = url;
		console.log(url);
		console.log(datas);

	}

	async do_search() {

		this.clear_table();

		let dates = this.prefer_date_range.get_selections();
		let times = this.prefer_time_range.get_selections();
		this.selected_puid = this.package_select.val();

		let url = `/student/package_iec/get_available_lessons_for_package/${this.selected_puid}?sdate=${dates.from.format("YYYY-MM-DD")}&edate=${dates.to.format("YYYY-MM-DD")}&stime=${times.from}&etime=${times.to}`;

		try {
			this.avail_sessions = await this.jdp.api_user.fetch_data_promise(url, null);
		} catch (e) {
			console.log(e);
			if ((typeof(e) == "string") && (e.indexOf("package_not_allow_online_purchase") != -1)) {
				console.log(e);
				this.avail_sessions = {rows: []};
			} else {
				console.log(e);
				throw e;
			}
		}

		this.fill_table();
		this.search_button.removeClass("disabled");
		$(".jdp-spinner").hide();
	}

	async _update_branches() {
		console.log("_update_branches");
		if (this.assignSameBranch) return;
		this.branch_select_group.show();
		let puid = this.package_select.val();
		console.log(puid);
		let _package = this.packages.find(p=>p.pu_id==puid);

		this.branch_select.empty();
		let have_selected_any_branch = false;

		let branches = await this.jdp.api_user.fetch_data_promise("/student/package_iec/get_branches_for_package/" + _package.pk_id, null);
		console.log(branches);

		for (let branch of branches) {
			let option = $("<option/>");
			option.val(branch.id);
			option.text(branch.name);
			if (branch.id==_package.b_id) {
				option.attr("selected", "selected");
				have_selected_any_branch = true;
			}
			this.branch_select.append(option);
		}

		if (!have_selected_any_branch) {
			$(this.branch_select.find("option:enabled").first()).attr("selected", "selected");
		}

		if (branches.length > 1) {
			this.branch_select.prop("disabled", false);
		} else {
			this.branch_select.prop("disabled", true);
		}
	}

	async on_load() {



		this.table = common.make_empty_table();
		$(this.table).css({
			"margin-top": "15px"
		});

		let controls_container = common.add_elem("div", ``, "", this.jdp.main_elem);

		this.package_select = common.add_elem("select", `width: auto;`, "form-control");
		common.add_field_group(window.nss("103:session_select_package"), this.package_select, controls_container, true);

		this.branch_select = common.add_elem("select", `width: auto;`, "form-control");
		this.branch_select_group = common.add_field_group(window.nss("103:session_select_branch"), this.branch_select, controls_container, true);
		
		let show_branch_select = false;
		this.branch_select_group.hide();

		let _packages = await this.jdp.api_user.fetch_data_promise("/student/package_iec/get_my_packages", null);

		if (!_packages.other.assignSameBranch) {
			show_branch_select = true;
			this.branch_select_group.show();
		} else {
			this.assignSameBranch = true;
		}

		let packages = _packages.rows.filter(p => p.Credits_Balance);
		if (packages.length < 1) {
			window.location = "/jdp?page=buy_packages";
			return;
		}
		this.packages = packages;

		let search_enabled = true;

		if (packages.length < 1) {
			this.package_select.attr("disabled", true);
			this.package_select.append($("<option/>").text(window.nss("106:book_sessions_no_packages")));
			search_enabled = false;
		}

		let index_first_allowed_package = -1;
		let have_selected_any = false;

		for (let _package_i in packages) {
			let _package = packages[_package_i];
			let formatted_name = window.nss("104:book_session_package_name", [_package.Package_Name, _package.Date_Issued, _package.Credits_Balance])

			let option = $("<option/>");
			option.val(_package.pu_id);
			option.text(formatted_name);

			if (index_first_allowed_package == -1) {
				index_first_allowed_package = _package_i;
			}

			if (this.jdp.args.pu_id && (this.jdp.args.pu_id == _package.pu_id)) {
				option.attr("selected", "selected")
				have_selected_any = true;
			}

			this.package_select.append(option);
		}

		if (show_branch_select) {
			await this._update_branches();

		} else {
			this.branch_select_group.hide();
		}

		this.package_select.on("change", async _ => {
			try {
				await this._update_branches();
			} catch (e) {
				console.log(e);
				alert("Error fetching branches.");
			}
		});
		

		console.log(index_first_allowed_package);

		if (index_first_allowed_package == -1) {
			console.log("problem!");
			BootstrapDialog.show({
				message: ("You have no packages available to make bookings."),
				title: "Error",
				cssClass: "very-high-zindex",
				type: BootstrapDialog.TYPE_WARNING,
				closable: false,
				buttons: [
					{
						label: this.frontend.get_string("ok"),
						action: (dialogItself) => {
							dialogItself.close();


							let features = this.jdp.frontend.state.feature_config.features;
							console.log(features);
							if (features.includes("BuyPackage")) {
								window.location = "/jdp?page=buy_packages";
							} if (features.includes("OnlinePackage")) {
								window.location = "/jdp?page=my_packages";
							} else {
								window.location = "/student/profile";
							}
						}
					},
				]
			});
			return;
		} else if (!have_selected_any) {
			$(this.package_select.find("option:enabled").first()).attr("selected", "selected");
		}

		let now = moment();
		let earliest = now.clone();//.subtract(3, "days").startOf("day");
		let latest = now.clone().add(10, "days").endOf("day");
		this.prefer_date_range = new DateRangeControl(earliest, latest);
		common.add_field_group(window.nss("103:label_preferred_dates"), this.prefer_date_range.get_elem(), controls_container, true);

		this.prefer_time_range = new TimeRangeControl(9, "00", false, 4, "00", true);
		common.add_field_group(window.nss("103:label_preferred_times"), this.prefer_time_range.get_elem(), controls_container, true);

		this.search_button = $("<a/>").text(window.nss("103:btn_search")).addClass("btn green-haze").attr("href", "#");
		this.search_button.click(e => {
			e.preventDefault();
			this.search_button.addClass("disabled");
			$(".jdp-spinner").show();
			this.do_search();
		});
		this.jdp.main_elem.append(this.search_button);

		this.jdp.main_elem.append(this.table);

		this.book_button = $("<a/>").text(window.nss("103:btn_book")).addClass("btn green-haze disabled").attr("href", "#");
		this.book_button.click(e => {
			e.preventDefault();
			this.book_button.addClass("disabled");
			$(".jdp-spinner").show();
			this.do_booking();
		});

		this.jdp.main_elem.append(`
			<div class="alert alert-warning msg-no-results">
				<strong>${window.nss("106:no_results")}</strong>
				<br>
				<span>${window.nss("106:book_sessions_none_found")}</span>
			</div>
		`);

		this.jdp.main_elem.append(this.book_button);

		console.log("loaded");

		this.search_button.addClass("disabled");
		if (search_enabled) {
			$(".jdp-spinner").show();
			await this.do_search();
		} else {

		}
	}
};

module.exports = _class;
window.register_jdp((require('path')).basename(__filename, ".js"), _class);