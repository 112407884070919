"use strict";

var $ = require("jquery");
var ApiUser = require("../../ApiUser");
var Cookies = require("cookies-js");
var common = require("../../common");

function BuyTermsPage(frontend) {
	this.state = {
		frontend: frontend
	};
}


BuyTermsPage.prototype.load = function(cb) {
	var api_user = this.state.frontend.state.api_user;

	$("#quick-access").hide();
	
	api_user.fetch_data("/auth/terms?template=buy", null, function(err, res) {
		console.log(res);
		$(".content").append(res);
		cb();
	});


};

module.exports = BuyTermsPage;
