"use strict";

var common = require("../common");
var $ = require("jquery");

var React = require("react");
var ReactDOM = require("react-dom");

var ProfileBasic  = require("./profile_parts/ProfileBasic");
var ProfileEditor = require("./profile_parts/ProfileEditor");

var Cookies = require("cookies-js");

class ProfilePage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isEditMode: false,
			editModeDirty: false,
		}

		if (!Cookies.get("photo_cachebust")) {
			Cookies.set("photo_cachebust", Date.now(), {expires: new Date("9999-09-09")});
		}
	}

	componentDidMount() {
		this.ev_listener = window.addEventListener("popstate", ev => {
			if (!this.state.isEditMode) {
				history.back();
				return;
			}

			const msg = nss("106:profile_cancel_verify");
			if ((!msg) || (msg == "profile_cancel_verify") || !this.state.editModeDirty) {
				this.setState({
					isEditMode: false,
					editModeDirty: false,
				});
				return;
			}

			const sure = confirm(msg);
			if (!sure) {
				history.pushState({"page": "edit"}, "");
				return;
			}

			this.setState({
				isEditMode: false,
				editModeDirty: false,
			});
		});
	}

	componentWillUnmount() {
		window.removeEventListener("popstate", this.ev_listener);
	}

	render() {
		if (this.state.isEditMode) {
			return <ProfileEditor profile_config={this.props.profile_config} onMadeDirty={ev => {
				this.setState({
					editModeDirty: true,
				});
			}} onSaveOk={ev => {
				this.setState({
					editModeDirty: false,
				});
			}} onCancel={ev => {
				history.back();		
			}} />
		} else {
			return <ProfileBasic profile_config={this.props.profile_config} onClickEdit={ev => {
        history.pushState({"page": "edit"}, "");

				this.setState({
					isEditMode: true,
				});
			}} />
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.isEditMode != this.state.isEditMode) {
			jdp.set_title(this.state.isEditMode ? nss("101:Profile Details") : nss("101:Profile"));
		}
	}
}

var _class = class {
	constructor(jdp) {
		this.jdp = jdp;
	}

	get_basic_info() {
		return {
			title_sid: "101:Profile",
		};
	}

	async on_load() {
		window.jdp = this.jdp;
		$(".page-container").css("min-height", "calc(100vh - 60px)")

		const profile_config = await jdp.api_user.fetch_data_promise(`/student/profile_rewrite/get_profile_config`, null);
		profile_config.ShowThisOnProfilePage = new Set(profile_config.ShowThisOnProfilePage);
		console.log(profile_config);

		ReactDOM.render(<ProfilePage profile_config={profile_config} />, this.jdp.main_elem[0]);
	}
};

module.exports = _class;
window.register_jdp((require('path')).basename(__filename, ".jsx"), _class);