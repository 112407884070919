"use strict";
var React = require("react");
var $ = require("jquery");
require("blueimp-file-upload");


module.exports = class ProfileBasic extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isUploadingPhoto: false,
    };

    this.fileupload_input = $("<input />")
      .attr("type", "file")
      .attr("name", "upload_photo")
      .attr("data-url", jdp.frontend.state.api_base + "/student/profile/set_photo");

    $(this.fileupload_input).fileupload({
        dataType: 'json',
        headers: {
          "Authorization": "Token " + Cookies.get("token")
        },
        submit: _ => {
          this.setState({
            isUploadingPhoto: true,
          });
          return true;
        },
        done: (e, data) => {
          let result = data.result;
          if (result && result.status && result.status == "ok") {
            Cookies.set("photo_cachebust", Date.now(),  {expires: new Date("9999-09-09")});
            window.location = window.location;
          } else {
            alert(nss("106:profile_photo_error"));
            window.location = window.location;
          }

          this.setState({
            isUploadingPhoto: false,
          });
        }
    });


  }


  getConfig() {
    const current_ut_val = this.props.profile_config.CurrentValues["StudentParticulars_0_UserType"];
    const is_specific_user_type_selected = (this.props.profile_config.UserTypes.findIndex(ut => ut.id == current_ut_val) != -1);
    return is_specific_user_type_selected
      ? (this.props.profile_config.ConfigsByUserType[current_ut_val] || this.props.profile_config.DefaultConfig)
      : this.props.profile_config.DefaultConfig
    ;
  }

  render() {
    const fieldsConfig = this.getConfig();
    const fieldValues = this.props.profile_config.CurrentValues;
    const toShow = this.props.profile_config.ShowThisOnProfilePage

    const photo = this.state.isUploadingPhoto
    ? "/static/img/ajax-loading.gif"
    : (this.props.profile_config.PhotoPath
      ? (this.props.profile_config.PhotoPath + "?cachebust=" + Cookies.get("photo_cachebust"))
      : "/static/img/default-avatar.png"
    );

    return <div>
      <div style={{
        display: "flex",
        flexDirection: "row",
        height: 100,
      }}>
        {toShow.has("Picture") && <div onClick={_ => {
          if (toShow.has("ChangePicture")) {
            this.fileupload_input.click();
          }
        }} style={{
          width: 100,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${photo})`,
        }}>
        </div> }

        <div style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "flex-start",
          marginLeft: 10,
          fontSize: 14,
        }}>
          <span style={{ color: "#1caf9a", fontWeight: "bold", fontSize: "16px" }}>
            {fieldValues["StudentParticulars_0_StudentName"]}
          </span>
          
          { toShow.has("UserID") &&
            <span>{nss("106:profile_user_id", [fieldValues["StudentParticulars_0_UserID"]])}</span>
          }
          
          { toShow.has("StudentID") &&
            <span>{nss("106:profile_student_id", [fieldValues["StudentParticulars_0_StudentID"]])}</span>
          }

          { toShow.has("ChangePicture") &&
            <button onClick={ev => {
              this.fileupload_input.click();
            }} style={{
              background: "none",
              border: "none",
              paddingLeft: 0,
              color: "#64ACEF",
              fontWeight: "bold",
            }}>{nss("106:profile_change_picture")}</button>
          }
        </div>
      </div>
      <div style={{
        marginTop: 20,
      }}>
        { toShow.has("ResetPassword") &&
          <button onClick={ev=>{
            window.location = "/request_password_reset?mode=reset&userid=" + fieldValues["StudentParticulars_0_UserID"];
          }} className="btn green-haze">{nss("106:profile_reset_password")}</button>
        }

        { toShow.has("EditMyDetails") &&
          <button onClick={ev=>{
            this.props.onClickEdit();
          }} className="btn green-haze">{nss("106:profile_edit_details")}</button>
        }
      </div>
    </div>
  }
}

