"use strict";

var $ = require("jquery");

var BootstrapDialog = require("bootstrap3-dialog");

var common = require("../../common");


module.exports = class CommsPage {

	constructor (frontend) {
		this.state = {
			frontend: frontend,
			categories: []
		};
	}

	_show_form_error(error_message) {
		BootstrapDialog.show({
			message: error_message,
			title: "Error",
			cssClass: "very-high-zindex",
			type: BootstrapDialog.TYPE_WARNING,
			buttons: [
				{
					label: "Close",
					action: function(dialogItself){
						dialogItself.close();
						// window.location = "/student/fees_past";
					}
				}
			]
		});
	}

	load (cb) {
		var api_user = this.state.frontend.state.api_user;
		let get_string = this.state.frontend.get_string.bind(this.state.frontend);

		let data = null;

		let reverse_sort = false;
		let filter_and_make_table = null;

		const on_click_action = (record) => {
			if (record.unread == 0) return;
			api_user.post_data_promise("/student/comms/mark_as_read",
				{
					commId: record.id,
				}
			).then(res => {
				window.location = window.location;
			}).catch(e => {
				console.log("FAILED: " + e);
				this._show_form_error("There was an unknown error when marking this communication as read.");
			});
		};

		filter_and_make_table = () => {
			let selected_year = $("#year-control").val();
			let selected_records = selected_year ? data.rows.filter(r => { return r.filterable_year == selected_year }) : data.rows.map(r => r);
			if (reverse_sort) {
				selected_records.reverse();
			}
			for (const record of selected_records) {
				const isUnread = record.unread == 1;
				const imgUrl = isUnread ? "/static/img/env_closed.svg" : "/static/img/env_open.svg";
				const imgClass = isUnread ? "img-unread" : "";
				const img = $("<img/>").addClass(imgClass).attr("src", imgUrl);
				if (isUnread) {
					$(img).attr("title", "Mark as read");
				}
				const a = $("<a/>").addClass("message-read-link").append(img).on("click", ev => {
					ev.preventDefault();
					on_click_action(record);
				});
				if (!isUnread) {
					$(a).css("cursor", "default");
				}
				record["action"] = a;

				if (isUnread) {
					record["unread_flag"] = $("<span />").text("⬤").addClass("comm-unread-flag");
				}
			}
			common.build_table($("#comms-table"), data.legend, selected_records);
			let date_th = $("#comms-table > thead > tr > th:nth-child(1)");
			date_th.text("");
			let sort_link = $("<a/>").text("Date").attr("href", "#").css({"color": "white", "text-decoration": "underline"});
			sort_link.text(reverse_sort ? "Date ▴" : "Date ▾");
			sort_link.click(e => {
				e.preventDefault();
				reverse_sort = !reverse_sort;
				// data.rows.reverse();
				sort_link.text(reverse_sort ? "Date ▴" : "Date ▾");
				filter_and_make_table();
			});
			date_th.append(sort_link);
		};


		api_user.fetch_data_promise("/student/comms/get_all_comms", null)
		.catch(e => {})
		.then(_comms => {
			console.log(_comms.comms);
			data = _comms.comms;
			this.state.categories = _comms.categories;

			for (let cat of this.state.categories) {
				let opt = $("<option/>").val(cat.id).text(cat.name);
				$("#category-control").append(opt);
			}

			

			$("#add-comm").removeClass("disabled");

			$("#add-comm").click(e => {
				e.preventDefault();
				console.log("add comm");

				$("#filter-controls,#comms-table,#list-title,.msg-no-results").addClass("hide");
				$("#submit-form,#submit-title").removeClass("hide");

			});

			$("#cancel-btn").click(e => {
				$("#filter-controls,#comms-table,#list-title").removeClass("hide");
				$("#submit-form,#submit-title").addClass("hide");
			});

			// $("#category-control, #details-control").on("change", _ => {
			// 	const enabled = ($("#category-control").val() !== "NULL") && $("#details-control").val();
			// 	if (enabled) {
			// 		$("#submit-btn").removeProp("disabled")
			// 	} else {
			// 		$("#submit-btn").prop("disabled", "disabled")
			// 	}
			// });

			$("#submit-form").submit(e => {
				e.preventDefault();
				console.log("submit");

				let selected_cat = $("#category-control").val();
				console.log(selected_cat);

				if (selected_cat === "NULL") {
					this._show_form_error("You must select a category.");
					return;
				}

				let details = $("#details-control").val();
				if (!details) {
					this._show_form_error("You must enter some details.");
					return;
				}

				if ($("#submit-btn").prop("disabled")) {
					return;
				}

				$("#submit-btn").prop("disabled", "disabled");

				api_user.post_data_promise("/student/comms/do_submit_message",
					{
						category: selected_cat,
						details: details
					}
				).then(res => {
					$("#submit-btn").removeProp("disabled");
					window.location = window.location;
				}).catch(e => {
					$("#submit-btn").removeProp("disabled");
					console.log("FAILED: " + e);
					this._show_form_error("There was an unknown error submitting your message. Please try again later.");
				});



			});

			if (data.rows.length < 1) {
				$(".msg-no-results").removeClass("hide");
				cb();
				return;
			}

			let years = new Set();
			data.rows.forEach(r => {
				// r._row_link_url =;
				years.add(r.filterable_year);
			});
			let today_year = (new Date()).getFullYear();

			for (let year of years) {
				let val = year;
				let opt = $("<option/>").val(val).text(val);
				if (val == today_year) {
					$(opt).attr("selected", "selected");
				}

				$("#year-control").append(opt);
			}

			filter_and_make_table();

			$("#year-control").change(filter_and_make_table);

			cb();
		});	


	}



};