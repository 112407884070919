"use strict";

var common = require("../common");
var $ = require("jquery");
var moment = require("moment");


var GLOBAL_selected_page = 0;

var _class = class {
	constructor(jdp) {
		this.jdp = jdp;
		this.packages = [];
		console.log("buy_packages");
		this.results_per_page = 10;

		let filter_controls_container = common.add_elem("div", `
			display: flex;
		`, "row", this.jdp.main_elem);

		this.sort_select = common.add_elem("select", {}, "form-control");

		let sort_map = {
			"name":       nss("104:label_buy_packages_name"),
			"validity":   nss("104:label_buy_packages_validity"),
			"credits":    nss("104:label_buy_packages_credits"),
			"price":      nss("104:label_buy_packages_price"),
		};

		for (let sort_key in sort_map) {
			let option = $("<option/>");
			option.val(sort_key);
			option.text(sort_map[sort_key]);
			if (this.jdp.args.sort && (this.jdp.args.sort == sort_key)) {
				option.attr("selected", "selected")
			}
			this.sort_select.append(option);
		}

		this.sort_select.change(_=>{
			this.fill_table();
		});


		// for (let foo of ["test1", "test2"]) {
		// 	this.year_select.append($(`<option/>`).text(foo));
		// }
		let sort_group = common.add_field_group(nss("103:label_buy_packages_sort_by"), this.sort_select, filter_controls_container);

		this.search_input = common.add_elem("input", "width: 150px; margin-right: 40px;", "form-control");
		this.search_input.val(this.jdp.args.search || "")
		let search_group = common.add_field_group(nss("103:label_search"), this.search_input, filter_controls_container);
		$(search_group).css("margin-right", "43px");


		let search_btn = common.add_elem("div", `
			width: 34px;
			height: 34px;
			position: absolute;
			right: -38px;
			bottom: 0px;
			border-radius: 4px;
			font-size: 28px;
			line-height: 24px;
			text-align: center;
		`, "bg-color-btn", search_group);

		search_btn.append($("<i/>").addClass("fa fa-search").css({"color": "white", "font-size": "20px"}));


		let on_search_clicked = _=>{
			// let opts = [];
			// let sort = this.sort_select.val();
			// if (year) {
			// 	opts.push(`sort=${sort}`);
			// }
			// let search = search_input.val();
			// if (search) {
			// 	opts.push(`search=${encodeURIComponent(search)}`);
			// }
			// let url = `/jdp?page=buy_packages${opts.map(o => ("&" + o)).join("")}`;
			// window.location = url;

			this.fill_table();
		};

		search_btn.click(_=>{
			on_search_clicked();
		});

		this.search_input.on("keypress", e => {
			if (e.which === 13) {
				on_search_clicked();
			}
		});

		this.packages_container_dom = common.add_elem("div", {}, "", this.jdp.main_elem);

		this.paginators = [
			common.add_elem("div", `
				// border: 1px solid green;
				margin-left: auto;
				margin-right: 15px;
				display: flex;
				align-items: flex-end;
				margin-bottom: 15px;
			`, "", filter_controls_container)
			,

			common.add_elem("div", `
				display: flex;
				align-items: flex-end;
				justify-content: flex-end;
			`, "", this.jdp.main_elem)

		];




	}

	get_basic_info() {
		return {
			title_sid: "101:title_buy_packages",
			record_type_plural: "packages"
		};
	}

	find_in_keys(thing, keyword, in_keys) {
		let keyword_lower = keyword.toLowerCase();
		for (let in_key of in_keys) {
			if (thing[in_key].toLowerCase().indexOf(keyword_lower) != -1) {
				return true;
			}
		}

		return false;
	}


	split_to_groups(things, of_size_n) {
		let results = [];
		let n_groups = Math.ceil(things.length/of_size_n);
		for (let group_i = 0; group_i < n_groups; group_i++) {
			let group = things.slice(of_size_n * group_i, of_size_n * group_i + of_size_n);
			results.push(group);
		}
		return results;
	}



	fill_table(to_page) {

		if (!to_page) to_page = 0;

		// $(this.table).find("tbody tr").remove();
		this.packages_container_dom.empty();

		let sort_by = this.sort_select.val();
		console.log(sort_by);

		let package_rows_to_use = this.packages.rows.filter(r => {
			let search = this.search_input.val();
			if (search && (!this.find_in_keys(r, search, ["name", "desc"]))) return false;
			return true;
		}).sort((a,b) => {
			let val_a = a[sort_by];
			let val_b = b[sort_by];
			if ((typeof val_a == "number") && (typeof val_b == "number")) {
				return ((val_a < val_b) ? -1 : ((val_a > val_b) ? 1 : 0));
			} else {
				return val_a.localeCompare(val_b);
			}
		});

		for (let paginator of this.paginators) {
			$(paginator).empty();
		}

		if (package_rows_to_use.length < 1) {
			$(".msg-no-results").removeClass("hide");
			return;
		} else {
			$(".msg-no-results").addClass("hide");
		}






		// common.build_table(this.table, this.packages.legend, package_rows_to_use, "id");

		// // Other pages use link info supplied over the API's legend.
		// // But none of the fields are guranteed to be there, so instead
		// // substitute the contents of the "first" (leftmost) column with a link.

		// let rows = $(this.table).find("tbody tr");
		// for (let row of rows) {
		// 	let first_col = $(row).find("td").first().find(".data-span");
		// 	let first_col_contents = first_col.html();
		// 	let a = $("<a/>").attr("href", common.build_jdp_url("checkout_package", {pk_id: $(row).attr("data-row-id")}));
		// 	a.html(first_col_contents);
		// 	$(first_col).empty().append(a);
		// 	console.log(first_col_contents);
		// }

		let packages_split = this.split_to_groups(package_rows_to_use, this.results_per_page);

		// let base_page_link = common.build_jdp_url("buy_packages");

		for (let paginator of this.paginators) {
			for (let n = 0; n < packages_split.length; n++) {
				// let page_link = $("<a/>").text(""+n);//.attr("href", base_page_link + "&page=" + n);


				let page_link = common.add_elem("a", `
					margin-left: 4px;
					font-weight: bold;
					background-color: #bbe6e2;
					padding: 4px;
					padding-left: 6px;
					padding-right: 6px;
					border-radius: 7px;
					color: #505050;
				`);
				page_link.text(""+(n+1));
				if (n == GLOBAL_selected_page) {
					page_link.css("background-color", "#b0c7c5");
					page_link.css("color", "white");
					page_link.css("text-decoration", "underline");
				}

				page_link.click(e => {
					e.preventDefault();
					GLOBAL_selected_page = n;
					this.fill_table(n);
				});
				$(paginator).append(page_link);
			}
		}


		// for (let package_set_i in packages_split) {



		// let package_set = packages_split[package_set_i];
		let package_set = packages_split[to_page];

		let page_dom = common.add_elem("div", ``);

		for (let _package of package_set) {
			let dom = common.add_elem("div", `
				margin-bottom: 10px;
				border: 1px solid #e5e5e5;
				padding-left: 5px;
				padding-right: 5px;
				border-radius: 4px;
			`, "package-dom");

			let top_divider = common.add_elem("div", `
				display: flex;
			`);

			    

			let target = common.build_jdp_url("checkout_package", {pk_id: _package.id});
			let name = $.parseHTML(`<h4><a href=${target}><strong>${_package.name}</strong></a></h4>`);
			let price = $.parseHTML(`<a href=${target}><h4 style="font-weight: 600; font-size: 18pt; color: black; text-decoration: underline;"><span><span style="font-weight: 100;">${window.currency}</span>${_package.price}</span></h4></a>`);

			$(name).css(common.parse_css_dict(`
				// width: 50%;
			`));
			$(price).css(common.parse_css_dict(`
				margin-left: auto;
				text-align: right;
				font-size: 18pt;
				text-decoration: none;
				font-weight: 600;
			`));

			top_divider.append(name);
			top_divider.append(price);

			dom.append(top_divider);
			dom.append($("<p/>").text(_package.desc));
			dom.append(nss("104:label_buy_packages_validity_template", [_package.validity]));
			dom.append(`<p><strong>${nss("104:label_buy_packages_num_credits")}:</strong> ${_package.credits}</p>`);





			// this.packages_container_dom.append(dom);
			page_dom.append(dom);
		}
		this.packages_container_dom.append(page_dom);


		// }





	}

	on_layout() {
		// let filter_container_width = this.filter_controls_container.width();

	}

	async on_load() {

		this.packages = await this.jdp.api_user.fetch_data_promise("/student/package_iec/get_all_available_packages", null);
		console.log(this.packages);

		// this.table = common.make_empty_table();
		this.fill_table();
		// this.jdp.main_elem.append(this.table);
	}

	async test1() {
		return "42";
	}

	async test2() {
		return await this.test1();
	}


};

module.exports = _class;
window.register_jdp((require('path')).basename(__filename, ".js"), _class);