"use strict";

var $ = require("jquery");

var moment = require("moment");
var common = require("../../common");

var BootstrapDialog = require("bootstrap3-dialog");


// https://stackoverflow.com/a/30832210
function _downloadBlob(blob, filename) {
  if (window.navigator.msSaveOrOpenBlob) { // IE10+
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else { // Others
    let a = document.createElement("a");
    let url = URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(function() {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);  
    }, 0); 
  }
}

function _postTo(target, jsonData) {
  let form = $("<form/>");
  $(form).css("display", "none");
  $(form).attr("method", "POST");
  $(form).attr("action", target);
  $(form).append($("<input>").attr("name", "jsonData").val(JSON.stringify(jsonData)));
  $(document.body).append(form);
  $(form).submit();
}


module.exports = class FeesPastReceiptPage {

	constructor (frontend) {
		this.state = {
			frontend: frontend
		};
	}

	load (cb) {
		let api_user = this.state.frontend.state.api_user;
		let args = common.get_args();

		let has_finished = false;

		let request = api_user.post_data_promise(args["x-use-ioffice-pdf"] ? "/student/fees/request_past_receipt_2" : "/student/fees/request_past_receipt_3", {
			p_id: args.id,
			r_no: decodeURIComponent(args.no)
		});

		request.catch(e => {
			has_finished = true;
			$(".generating").addClass("hide");
			BootstrapDialog.show({
				message: "There was an error requesting a receipt for this payment. Please try again later, or contact an administrator if necessary.\n\nYour receipt number: " + args.no + "\nOur internal payment reference: " + args.id,
				title: "Error",
				cssClass: "very-high-zindex",
				type: BootstrapDialog.TYPE_WARNING,
				buttons: [

					{
						label: "Close",
						action: function(dialogItself){
							dialogItself.close();
							window.location = "/student/fees_past";
						}
					}
				]
			});
		});

		request.then(result => {
			has_finished = true;
			console.log("result:");
			console.log(result);

			$(".generating").addClass("hide");

			if (args["x-use-ioffice-pdf"]) {
			  let request_url = "https://pdf.iofficecentre.com/make_pdf";

			  if (args["x-test-use-local-pdf"]) {
			    request_url = "http://localhost:15146/make_pdf";
			  }

			  request_url += ( "?serve_as_filename=" + encodeURIComponent("receipt" + ".pdf") );

			  
			  if (args["x-test-dont-cache-pdf"]) {
			    request_url += "&bypass_cache=true";
			  }

			  let data_to_post = {
			    pages: [
			    	{
			    		// height: 891,
			    		content: result,
			    		fit_to_a4: false,
			    		guess_height: true
			    	}
			    ]
			  };

			  _postTo(request_url, data_to_post);

		    $.ajax({
		      type: "POST",
		      url: request_url,
		      data: {
		        jsonData: JSON.stringify(data_to_post)
		      },
		      success: (pdf_data) => {
		        console.log(pdf_data);
		        _downloadBlob(pdf_data, "receipt" + ".pdf");
		        // $scope.misc.is_loading = false;
		      },
		      error: (pdf_err) => {
		        console.log(pdf_err);
		        alert("Could not download file.");
		        // $scope.misc.is_loading = false;
		      },
		      dataType: "binary",
		      processData: true
		    });
			} else {
				window.location = result.url;
			}

			







		});

		setTimeout(_ => {
			// 300ms after the request starts and hasn't yet finished, show the waiting text
			if (!has_finished) {
				$(".generating").removeClass("hide");
			}
			
		}, 300);

	}



};