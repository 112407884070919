"use strict";

var $ = require("jquery");
var ApiUser = require("../../ApiUser");
var Cookies = require("cookies-js");
var common = require("../../common");

var password_utils = require("../../../../../student-views-common/validation/password");
var email_utils = require("../../../../../student-views-common/validation/email");
var other_utils = require("../../../../../student-views-common/validation/other");

function CreateAccountPage(frontend) {
	this.state = {
		frontend: frontend
	};
}

function fail_with_error(name, err) {
	if (name) {
		$("[name=" + name + "]").parent().find(".invalid-line").text(err);
	}
	// alert(err);

	$(".form-actions").removeClass("hide");
}

var errors = {
	strength: `Your password is not strong enough. Please choose a password which is:

	* At least 8 characters long
	* Contains at least one uppercase letter
	* Contains at least one number`,

	mismatch: "The Confirm Password field does not match the password you selected. Please enter the same password in both fields.",
	terms: "You must accept the terms and conditions to create an account."
};

CreateAccountPage.prototype.is_userid_ok = function(userid, cb) {
	var api_user = this.state.frontend.state.api_user;

	if ((!userid) || (userid.length < 3)) {
		cb(false);
	} else if (userid.indexOf(" ") != -1) {
		cb(false, "Spaces are not allowed in User IDs.")
	} else {
		let e_id = encodeURIComponent(userid);

		api_user.fetch_data("/auth/get_is_userid_available?id=" + e_id, null, function(err, res) {
			let is_ok = (!err) && (res) && (res == "available");
			cb(is_ok);
		});
	}

};

CreateAccountPage.prototype.load = function(cb) {
	var me = this;
	var api_user = this.state.frontend.state.api_user;
	console.log("Loaded");
	cb();

	let set_field_symbol = (elm, is_ok, err) => {
		if (is_ok) {
			$(elm).parent().find(".field-status").addClass("hide");
			$(elm).parent().find(".field-status-ok").removeClass("hide");
			$(elm).parent().find(".invalid-line").text("");
		} else {
			$(elm).parent().find(".field-status").addClass("hide");

			if (err) {
				$(elm).parent().find(".invalid-line").text(err);
			}
			// $(elm).parent().find(".field-status-bad").removeClass("hide");
		}
	};

	$("[name=password]").on("change", function(){
		let is_ok = password_utils.is_password_ok($(this).val());
		set_field_symbol($(this), is_ok, password_utils.password_requirement);
	});

	$("[name=chrome_autofill_bypass]").on("change", function(){
		let is_ok = email_utils.is_email_valid($(this).val());
		set_field_symbol($(this), is_ok, email_utils.email_error);
	});

	// $("[name=name]").on("change keyup", function(){
	// 	let is_ok = other_utils.is_name_valid($(this).val());
	// 	set_field_symbol($(this), is_ok, other_utils.name_error);
	// });


	$("[name=password], [name=confirm_password]").on("change", function(){
		if ($("[name=confirm_password]").val() != "") {
			let is_ok = ($("[name=password]").val() == $("[name=confirm_password]").val());
			set_field_symbol($("[name=confirm_password]"), is_ok, errors.mismatch);
		}

	});

	let id_last_checked_time = 0;

	$("[name=username]").on("keyup", function() {
		$(this).parent().find(".field-status").addClass("hide");
		$(this).parent().find(".field-status-unknown").removeClass("hide");
	});

	$("[name=username]").on("change", function() {
		window.setTimeout(() => {
			let check_time = Date.now();
			me.is_userid_ok($(this).val(), (is_ok, extra_error) => {
				if (check_time > id_last_checked_time) {
					set_field_symbol($(this), is_ok, (extra_error || "Username taken or invalid."));
					id_last_checked_time = check_time;
				}
				
			});
		},  20);
	});

	$("#generate-id").click(e => {
		e.preventDefault();

		api_user.fetch_data("/auth/generate_userid", {cachebust: Date.now()}, function(err, res) {
			let is_ok = (!err) && (res);
			if (is_ok) {
				$("[name=username]").val(res);
				set_field_symbol($("[name=username]"), true);
			} else {
				fail_with_error("Sorry, an error occurred. Could not generate an ID.");
			}
		});

	});

	$(".create-account-form").submit(e => {
		e.preventDefault();

		$(".form-actions").addClass("hide");

		if (!password_utils.is_password_ok($("[name=password]").val())) {
			fail_with_error("password", password_utils.password_requirement);
		} else if ($("[name=password]").val() != $("[name=confirm_password]").val()) {
			fail_with_error("confirm_password", errors.mismatch);
		} else if (!email_utils.is_email_valid($("[name=chrome_autofill_bypass]").val())) {
			fail_with_error("chrome_autofill_bypass", email_utils.email_error);
		} else if (!$("#terms_ok").is(":checked")) {
			fail_with_error("terms_ok", errors.terms);
		} else {
			// One last friendly check that it isn't taken
			this.is_userid_ok($("[name=username]").val(), (ok, extra_error) => {
				if (!ok) {
					fail_with_error("username", extra_error || "Sorry, that user ID is invalid or already in use. Please try another.");
				} else {
					let data = {};

					$(".create-account-form [name]").each((_, field) => {
						data[$(field).attr("name")] = $(field).val();
					});

					console.log(data);

					api_user.post_data("/auth/register_student", data, (err, res) => {
						console.log("E: " + err);
						console.log("R: " + res);
						if (err) {
							fail_with_error(null, "Sorry, an unknown error occurred when trying to register.")
						} else {
							$(".create-account-form").addClass("hide");
							$("#thank-you").removeClass("hide");

						}
					});
				}
			});
		}

	});

};

module.exports = CreateAccountPage;
