"use strict";

var $ = require("jquery");
var common = require("../../common");
var Cookies = require("cookies-js");

var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

function div_with_class(cls) {
	return $("<div/>").addClass(cls);
}

var DataGrouper = (function() {
    var has = function(obj, target) {
        return _.any(obj, function(value) {
            return _.isEqual(value, target);
        });
    };

    var keys = function(data, names) {
        return _.reduce(data, function(memo, item) {
            var key = _.pick(item, names);
            if (!has(memo, key)) {
                memo.push(key);
            }
            return memo;
        }, []);
    };

    var group = function(data, names) {
        var stems = keys(data, names);
        return _.map(stems, function(stem) {
            return {
                key: stem,
                vals:_.map(_.where(data, stem), function(item) {
                    return _.omit(item, names);
                })
            };
        });
    };

    group.register = function(name, converter) {
        return group[name] = function(data, names) {
            return _.map(group(data, names), converter);
        };
    };

    return group;
}());

function group_by(arr, prop) {
	let results = {};

	for (let e of arr) {
		let the_val = e[prop];
		if (typeof results[the_val] == "undefined") {
			results[the_val]  = [];
		}
		results[the_val].push(e);
	}

	return results;



}


module.exports = class MyVideosPage {
	constructor(frontend) {
		this.state = {
			frontend: frontend
		};
	}

	load(cb) {

		document.location = "/jdp?page=my_videos";
		return;

		let args = common.get_args();
		this.args = args;
		this.state.mode = args.mode || "level1";
		let api_user = this.state.frontend.state.api_user;

		api_user.fetch_data("/student/my_videos/get_my_videos_all", null, (err, videos) => {

			console.log(videos);

			if (this.state.mode == "level1") {

				this.add_product_types(videos.product_types);

			} else if (this.state.mode == "level2") {

				let the_product = videos.product_types.filter(t => t.id == args.level2_type)[0].products.filter(p => p.id == args.level2_id)[0];
				console.log(the_product);

				if (!the_product) {
					$(".msg-no-results").removeClass("hide");
					let cref = args["course_ref"];
					if (cref && cref != "") {
						let errmsg = "There are no available videos for this course: " + decodeURIComponent(cref);
						$(".msg-no-results > span").text(errmsg);
					}
					return;
				}

				let product_info_div = $("<div/>");

				for (let info of the_product.info) {
					let to_append = $("<span/>").addClass("product-info").text(info);
					product_info_div.append(to_append);
				}

				$(".page-content").append(product_info_div);

				let product_contents_div = div_with_class("product-contents").css("margin-top", "20px");


				let grouped = group_by(the_product.contents, "month");
				let num_groups = 0;
				for (let _ in grouped) { num_groups++; }
				console.log(num_groups);

				if (false) { // if (num_groups < 2 ){//|| the_product.contents.length < (videos.dont_group_videos_if_total_under)) {
					// If there are few enough, just render them now- done
					if (args.level2_type == "course") {
						the_product.contents = the_product.contents.reverse();
					}

					the_product.contents.sort((a,b) => {
						return a.sort_by.localeCompare(b.sort_by);
					});
					
					for (let content of the_product.contents) {
						this.add_product_content(content, product_contents_div, null);
					}
				} else {
					// More than that
					
					if (args.level2_type == "module") {

						for (let content of the_product.contents) {
							this.add_product_content(content, product_contents_div, null);
						}

						// for (let content of the_product.contents) {
						// 	let target = "?mode=level3&level2_type=module" + args.level2_type + "&level2_id=" + args.level2_id + "&level3_index=" + month_i;
						// }

					} else {
						for (let grouped_i in grouped) {
							let grouped_i_split = grouped_i.split(",");
							let month_i = parseInt(grouped_i_split[0]);
							let month_name = grouped_i_split[1];


							let target = "?mode=level3&level2_type=" + args.level2_type + "&level2_id=" + args.level2_id + "&level3_index=" + month_i;

							product_contents_div.append($("<a/>").addClass("month videos-product videos-product-with-icon").attr("href", target).text(month_name));
							product_contents_div.append($("<br/>"));
						}
					}



				}



				$(".page-content").append(product_contents_div);


				// this.add_product_types(videos.product_types);

			} else if (this.state.mode == "level3") {


				let the_product = videos.product_types.filter(t => t.id == args.level2_type)[0].products.filter(p => p.id == args.level2_id)[0];
				console.log(the_product);

				let product_info_div = $("<div/>");

				for (let info of the_product.info) {
					let to_append = $("<span/>").addClass("product-info").text(info);
					product_info_div.append(to_append);
				}

				$(".page-content").append(product_info_div);

				$(".page-content").append($("<h4/>").text(months[args.level3_index]));

				let product_contents_div = div_with_class("product-contents").css("margin-top", "10px");

				let encountered_ids = new Set();

				let the_product_contents = the_product.contents.filter(c => parseInt(c.month.split(',')[0]) == args.level3_index).filter(c => {
					let _id = `${c.id}-${c.file_id}`;
					if (encountered_ids.has(_id)) return false;
					encountered_ids.add(_id); return true;
				});

				the_product_contents.sort((a,b) => {
					return a.sort_by.localeCompare(b.sort_by);
				});

				// if (args.level2_type == "course") {
				// 	the_product_contents = the_product_contents.reverse();
				// }



				if (args.level2_type === "course") {
					let params = {
						level2_type: args.level2_type,
						level2_id: args.level2_id,
						level3_index: args.level3_index
					};
					this.video_context = JSON.stringify(params);
				}
				

				let should_do_grouped = (args.level2_type === "course");

				if (should_do_grouped) {
					let grouped = common.group_videos_list(the_product_contents, the_product.cou_code);

					// if (grouped.length > 0) {
					// 	let first_group = grouped.shift();
					// 	this.add_product_content(first_group.entries, product_contents_div, null);


						let skip_one = false;
						let other_done = false;

						if (grouped.length > 0) {
							if (grouped[0].course_code_from_filename == the_product.cou_code) {
								skip_one = true;
							}
						}

						if (grouped.length > 0) {
							
							for (let group of grouped) {
								// $(product_contents_div).append($("<h5/>").text("asdf"));

								if (skip_one) {
									skip_one = false;
								} else {
									if (!other_done) {
										other_done = true;
										$(product_contents_div).append($("<h4/>").text("From Other Courses").css("margin-top", "34px").css("margin-bottom", "0px"));
									}
									
								}


								if (group.course_code_from_filename != the_product.cou_code) {

									let code_to_display = group.course_code_from_filename;
									if (code_to_display == "zzzz-") code_to_display = "-";
									product_contents_div.append($("<span/>").addClass("product-info product-info-sub2").css("margin-top", "16px").text("Course code: " + code_to_display));
									product_contents_div.append($("<span/>").addClass("product-info product-info-sub2").text("Course name: " + group.course_name_from_filename));
								}


								for (let thing of group.entries) {
									thing.group_by = thing.sort_by.split(".")[0];
								}



								let grouped_by_date = common.split_by_day(group.entries);



								

								for (let group2 of grouped_by_date) {
									group2.entries.push("_BLANK_LINE_");
								}

								let recombobulated = [].concat.apply([], (grouped_by_date.map(thing => thing.entries)));
								if ((recombobulated.length > 1) && (recombobulated[recombobulated.length-1] == "_BLANK_LINE_")) {
									recombobulated.pop();
								}
								console.log("recombobulated:");
								console.log(recombobulated);



								for (let entry of recombobulated) {

									this.add_product_content(entry, product_contents_div, null);
								}
								
							}

						}


					//}



					console.log(grouped);
				} else {
					console.log(the_product_contents);
					for (let content of the_product_contents) {
						this.add_product_content(content, product_contents_div, null);
					}
				}

				
				



				$(".page-content").append(product_contents_div);

				// console.log(the_product_contents);


			} 

			

			cb();
		});
	}


	add_product_types(product_types) {

		let total_contents = 0;
		for (let product_type of product_types) {
			for (let product of product_type.products) {
				total_contents += product.contents.length;
			}
		}

		if (total_contents > 0) {
			for (let product_type of product_types) {

				if (product_type.products.length < 1) continue;

				let products_section_container_div = div_with_class("products-section-container");
				products_section_container_div.append($("<h1/>").text(product_type.name));

				let products_section_div = div_with_class("products-section");
				let videos_product_container_div = div_with_class("videos-product-container")

				this.add_products(product_type.products, videos_product_container_div);

				products_section_div.append(videos_product_container_div);
				products_section_container_div.append(products_section_div);
				$(".page-content").append(products_section_container_div);
			}
		} else {
			$(".msg-no-results").removeClass("hide");
		}


	}

	add_products(products, container) {

		for (let product of products) {


			let should_add_level1_video_links = (this.state.mode == "level1");


			let videos_product_div = div_with_class("videos-product");

			if (should_add_level1_video_links) {
				let target = "?mode=level2&level2_type=" + product.type + "&level2_id=" + product.id;
				videos_product_div = $("<a/>").attr("href", target).addClass("videos-product");
			}

			if (should_add_level1_video_links) {
				$(videos_product_div).addClass("videos-product-with-icon");
			}

			for (let info of product.info) {
				let to_append = $("<span/>").addClass("product-info").text(info);
				if (should_add_level1_video_links) {

					

					// to_append = $("<a/>").attr("href", target).append(to_append);
				}
				videos_product_div.append(to_append);
			}

			let product_contents_div = div_with_class("product-contents");

			if (this.state.mode == "level1") {

				// Don't add contents

			} else if (this.state.mode == "level2") {
				for (let content of product.contents) {
					this.add_product_content(content, product_contents_div);
				}


			} else if (this.state.mode == "level3") {

				for (let content of product.contents) {
					this.add_product_content(content, product_contents_div);
				}

			} else {
				console.log("illegal mode: " + this.state.mode);
			}



			videos_product_div.append(product_contents_div);
			container.append(videos_product_div);
		}



	}

	add_product_content(content, container, override_target) {
		let product_content_div = div_with_class("product-content");

		if (content === "_BLANK_LINE_") {
			let blank = $("<span/>").html("&nbsp;");
			container.append(blank);
			return;
		}

		// let text = ( content.type == "course_lesson" ) ? (
		// 	content.name.split(" ")[0] + " " + (content.time || "")
		// ) : content.name;


		// let text = null;

		let text = content.display_name || content.name || "<Unknown>";

		if (this.args["showfilename"] && (this.args.mode=="level3") && (this.args.level2_type=="course")) {
			text += ` | Lesson ID: ${content.id} | File ID: ${content.file_id} | Orig fname: ${content.orig_filename}`;
		}

		// if (content.type == "course_lesson") {
		// 	let filename = content.time;
		// 	let filename_split = filename.split("_");
		// 	console.log(filename_split);
		// 	if (filename_split.length < 4) {
		// 		text = filename;
		// 	} else {
		// 		let date = content.name.split(" ")[0];
		// 		let time = filename_split[3].split("-");
		// 		while(time.length > 2) {time.pop();}
		// 		time = time.join(":");
		// 		if (filename_split.length > 4) {
		// 			text = date + " " + time + " " + filename_split[4].split(".")[0];
					
		// 		} else {
		// 			text = date + " " + time + " " + filename;	
		// 		}
		// 	}
		// } else {
		// 	text = content.name;
		// }

		let last_watched = Cookies.get("last_video_id");

		let target = null;

		if (override_target) {
			target = override_target;
		} else {
			target =
				"/student/watch_my_video?"
				+ content.type + 
				"="
				+ content.id
				+ (content.file_id ? ("&file_id=" + content.file_id) : "");
			if (this.video_context) {
				target += ("&context=" + encodeURIComponent(this.video_context));
			}
		}

		let a = $("<a/>").attr("href", target).text(text);

		if (last_watched && (last_watched == (`${content.id}-${(content.file_id||"MODULE")}`))) {
			if (!this.have_added_last_watched) {
				$(a).addClass("lastWatched");
				this.have_added_last_watched = true;
			}
			
		}

		product_content_div.append(a);

		container.append(product_content_div);
	}






};