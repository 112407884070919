"use strict";

module.exports = function switch_old_route(pathname) {
    let page_handler_class;
    let is_public_page = false;

    switch(pathname) {
        case "/student/register_student":
            page_handler_class = require("./pages/student/RegisterStudentPage");
            break;
        case "/student/fees":
            page_handler_class = require("./pages/student/FeesPage");
            break;
        case "/student/fees_past":
            page_handler_class = require("./pages/student/FeesPastPage");
            break;
        case "/student/fees_past_receipt":
            page_handler_class = require("./pages/student/FeesPastReceiptPage");
            break;
        case "/student/calendar":
            page_handler_class = require("./pages/student/CalendarPage");
            break;
        case "/student/profile":
            page_handler_class = require("./pages/student/ProfilePage");
            break;
        case "/student/profile_details":
            page_handler_class = require("./pages/student/ProfileDetailsPage");
            break;
        case "/student/courses":
            page_handler_class = require("./pages/student/CoursesPage");
            break;
        case "/student/lessons":
            page_handler_class = require("./pages/student/LessonsPage");
            break;
        case "/student/lesson_detail":
            page_handler_class = require("./pages/student/LessonDetailPage");
            break;
        case "/student/makeups":
            page_handler_class = require("./pages/student/MakeupsPage");
            break;
        case "/student/makeups_info":
            page_handler_class = require("./pages/student/MakeupsInfoPage");
            break;
        case "/student/makeup_confirm":
            page_handler_class = require("./pages/student/MakeupConfirmPage");
            break;
        case "/student/my_videos":
            page_handler_class = require("./pages/student/MyVideosPage");
            break;
        case "/student/watch_my_video":
            page_handler_class = require("./pages/student/WatchMyVideoPage");
            break;

        case "/student/progress":
            page_handler_class = require("./pages/student/ProgressPage");
            break;

        case "/student/request_document":
            page_handler_class = require("./pages/student/RequestDocumentPage");
            break;

        case "/student/records":
            page_handler_class = require("./pages/student/RecordsPage");
            break;

        case "/student/photos":
            page_handler_class = require("./pages/student/PhotosPage");
            break;

        case "/student/comms":
            page_handler_class = require("./pages/student/CommsPage");
            break;

        case "/video/course_enroll":
            page_handler_class = require("./pages/video/CourseEnrollPage");
            break;

        case "/video/course_search":
            page_handler_class = require("./pages/video/SearchCoursesPage");
            is_public_page = true;
            break;

        case "/video/course_detail":
            page_handler_class = require("./pages/video/ViewCoursePage");
            is_public_page = true;
            break;

        case "/login":
            page_handler_class = require("./pages/logged_out/LoginPage");
            is_public_page = true;
            break;

        case "/create_account":
            page_handler_class = require("./pages/logged_out/CreateAccountPage");
            is_public_page = true;
            break;

        case "/request_password_reset":
            page_handler_class = require("./pages/logged_out/RequestPasswordResetPage");
            is_public_page = true;
            break;

        case "/activate":
            page_handler_class = require("./pages/logged_out/ActivatePage");
            is_public_page = true;
            break;

        case "/terms":
            page_handler_class = require("./pages/logged_out/TermsPage");
            is_public_page = true;
            break;

        case "/buyterms":
            page_handler_class = require("./pages/logged_out/BuyTermsPage");
            is_public_page = true;
            break;
    }

    return [page_handler_class, is_public_page];
}