"use strict";

var common = require("../common");
var $ = require("jquery");
var moment = require("moment");




var _class = class {
	constructor(jdp) {
		this.jdp = jdp;
	}

	get_basic_info() {
		return {
			title_sid: "101:title_checkout_package_thank_you",
			// record_type_plural: ""
		};
	}


	on_layout() {
	}

	async on_load() {
		// let data = this.data;
		let url = `/student/package/get_templated_message?type=purchase_thank_you&pu_id=${this.jdp.args.pu_id}`

		let message = await this.jdp.api_user.fetch_data_promise(url, null);
		common.add_elem("p", {}, "", this.jdp.main_elem).html(message.message);
	}




};

module.exports = _class;
window.register_jdp((require('path')).basename(__filename, ".js"), _class);