"use strict";

// var jQuery = require("jquery");
require("jquery-ui");
require("fullcalendar");
var jQuery = require("jquery");
var $ = jQuery;
var moment = require("moment");

window.m = moment;

function CalendarPage(frontend) {
	this.state = {
		frontend: frontend
	};
}

CalendarPage.prototype.load = function(cb) {
	var api_user = this.state.frontend.state.api_user;

	if (this.state.frontend.state.feature_config.features.includes("HideCalendar")) {
		window.location = "/student/lessons";
		return;
	}

	if (!jQuery().fullCalendar) {
	    return;
	}

	var date = new Date();
	var d = date.getDate();
	var m = date.getMonth();
	var y = date.getFullYear();

	$('#calendar').removeClass("mobile");

	var calendar_header = {
		left: 'title',
		center: '',
		right: 'prev,next,month,agendaWeek,agendaDay'
	};


	function add_date_and_time(date, time) {
		var new_date = new Date(date);
		var [hours, mins, secs] = time.split(":").map(c => {return parseInt(c);});

		new_date.setHours   ( new_date.getHours()   + hours );
		new_date.setMinutes ( new_date.getMinutes() + mins  );
		new_date.setSeconds ( new_date.getSeconds() + secs  );

		// console.log(new_date);

		return new_date;
	}

	api_user.fetch_data("/student/course/get_lesson_all?include_sessions=true", null, function(err, lessons) {

		if (err) {
			console.log("CAL ERROR: " + err);
		}

		console.log(lessons);

		var calendar_events = [];

		// let test_start = moment("2017-06-20 18:00").toDate();
		// let test_end = moment("2017-06-20 20:00").toDate();


			// calendar_events.push({
			// 	title:           "Test",
			// 	// start:           add_date_and_time(lesson.cl_realdate, lesson.cl_starttime),
			// 	// end:             add_date_and_time(lesson.cl_realdate, lesson.cl_endtime),
			// 	start: test_start,
			// 	end: test_end,
			// 	backgroundColor: "white",
			// 	textColor:       "#20845C",
			// 	url:             "/student/lesson_detail?lesson="
			// });


		for (let lesson of lessons) {

			// let start_date_string = lesson.cl_realdate.split("T")[0];
			let format_string = "YYYY-MM-DD HH:mm:ss";



			let start_time = moment(lesson.formatted_start, format_string); 
			// console.log(start_time);
			let end_time   = moment(lesson.formatted_end,   format_string); 


			calendar_events.push({
				title:           fix_html_ents(lesson.cou_name) + (lesson.was_skipped ? " (Skipped)" : ""),
				// start:           add_date_and_time(lesson.cl_realdate, lesson.cl_starttime),
				// end:             add_date_and_time(lesson.cl_realdate, lesson.cl_endtime),
				start: start_time.toDate(),
				end: end_time.toDate(),
				backgroundColor: "white",
				textColor:       lesson.was_skipped ? "#798882" : "#20845C",
				url:             (lesson.is_session) ? ("/jdp?page=my_bookings&highlight=" + lesson.cl_id) : ("/student/lesson_detail?lesson=" + lesson.cl_id)
			});
		}

		$('#calendar').fullCalendar('destroy');

		$('#calendar').fullCalendar({
		    header: calendar_header,
		    defaultView: 'month',
			timeFormat: 'h(:mm) a',
		    slotMinutes: 15,
		    events: calendar_events
		});

		cb();
	});
};

module.exports = CalendarPage;