"use strict";

var $ = require("jquery");

var BootstrapDialog = require("bootstrap3-dialog");

var common = require("../../common");


module.exports = class ProgressPage {

	constructor (frontend) {
		this.state = {
			frontend: frontend
		};
	}

	load (cb) {
		var api_user = this.state.frontend.state.api_user;
		let get_string = this.state.frontend.get_string.bind(this.state.frontend);

		let data = null;

		let filter_and_make_table = () => {
			let selected_year = $("#year-control").val();
			let selected_records = selected_year ? data.rows.filter(r => { return r.filterable_year == selected_year }) : data.rows;
			common.build_table($("#progress-table"), data.legend, selected_records);
		};


		api_user.fetch_data_promise("/student/progress/get_all_progress_reports", null)
		.catch(e => {})
		.then(_reports => {
			console.log(_reports);
			data = _reports;

			
			if (data.rows.length < 1) {
				$(".msg-no-results").removeClass("hide");
				cb();
				return;
			}

			let years = new Set();
			data.rows.forEach(r => {
				// r._row_link_url =;
				years.add(r.filterable_year);
			});
			let today_year = (new Date()).getFullYear();

			for (let year of years) {
				let val = year;
				let opt = $("<option/>").val(val).text(val);
				if (val == today_year) {
					$(opt).attr("selected", "selected");
				}

				$("#year-control").append(opt);
			}

			filter_and_make_table();

			$("#year-control").change(filter_and_make_table);

			cb();
		});	


	}



};