"use strict";

var common = require("../common");
var $ = require("jquery");
var moment = require("moment");




var _class = class {
	constructor(jdp) {
		this.jdp = jdp;
		common.add_elem("p", {}, "", jdp.main_elem).text("Buy this package?");
	}

	get_basic_info() {
		return {
			title_sid: "101:title_checkout_package",
			// record_type_plural: ""
		};
	}


	on_layout() {
	}

	make_abs_url(rel_url) {
		let a = document.createElement("a");
		a.href = rel_url;
		return a.href;
	}

	async _removed_buy_package_paypal() {
		try {
			let result = await this.jdp.api_user.post_data_promise(`/student/package_iec/buy_package`, {
				packId: this.jdp.args.pk_id,
				returnUrl: this.make_abs_url(common.build_jdp_url("checkout_package_return", {pk_id: this.jdp.args.pk_id})),
				cancelUrl: this.make_abs_url(common.build_jdp_url("checkout_package_cancel"))
			});

			console.log(result);

			if ((!result) || (!result.href) || (!result.method) || (result.method != "REDIRECT")) {
				throw "bad_buy_package_response";
			}

			window.location = result.href;


		} catch (e) {
			console.log(e);
			this.jdp.simple_show_error(e);
		}
	}

	async buy_package_via_fees() {
		try {
			let result = await this.jdp.api_user.post_data_promise(`/student/package_iec/fees_buy_package`, {
				packId: this.jdp.args.pk_id,
			});

			console.log(result);

			if ((!result) || (!result.puid)) {
				throw "bad_buy_package_response";
			}

			window.location = "/student/fees";


		} catch (e) {
			console.log(e);
			this.jdp.simple_show_error(e);
		}
	}

	async on_load() {
		let packages = await this.jdp.api_user.fetch_data_promise("/student/package_iec/get_all_available_packages", null);
		let _package = packages.rows.find(p => p.id == this.jdp.args.pk_id);

		let dom = common.add_elem("div", `
			margin-bottom: 10px;
			border: 1px solid #828282;
			padding-left: 5px;
			padding-right: 5px;
			border-radius: 4px;
		`, "package-dom");
		dom.append(`<h4><strong>${_package.name}</strong></h4>`);
		dom.append(`<h4><span>${window.currency}${_package.price}</span></h4>`);
		dom.append($("<p/>").text(_package.desc));
		dom.append(`<p><strong>Validity:</strong> ${_package.validity} days</p>`);
		dom.append(`<p><strong>No. of credits:</strong> ${_package.credits}</p>`);


		this.jdp.main_elem.append(dom);

		let confirm_block = $("<div/>");

		let confirm = $("<input/>").attr("type", "checkbox").attr("name", "agree_terms");
		$(confirm_block).append(confirm);

		let confirm_label = $("<label/>").attr("for", "agree_terms").css("margin-left", "10px");
		$(confirm_label).on("click", ev => {
			// ev.stopPropagation();
			// $(confirm).prop("checked", !$(confirm).prop("checked"));
			$(confirm).click();
		});
		$(confirm_label).append(`I agree to the <a href="/buyterms" target="_blank">Terms and Conditions</a>`);
		$(confirm_label).find("a").click(e => e.stopPropagation());

		$(confirm_block).append(confirm);
		$(confirm_block).append(confirm_label);


		this.jdp.main_elem.append(confirm_block);

		let button = $("<a/>").text("Confirm").addClass("btn green-haze disabled").attr("href", "#");
		button.click(e => {
			if ($(button).hasClass("disabled")) return;
			e.preventDefault();
			button.addClass("disabled");
			$(".jdp-spinner").show();
			// this.buy_package();
			this.buy_package_via_fees();
		});
		this.jdp.main_elem.append(button);

		$(confirm).on("change", _ => {
			if ($(button).hasClass("disabled")) {
				$(button).removeClass("disabled")
			} else {
				$(button).addClass("disabled")
			}
		});


	}




};

module.exports = _class;
window.register_jdp((require('path')).basename(__filename, ".js"), _class);