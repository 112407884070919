"use strict";

var $ = require("jquery");
require("blueimp-file-upload");
require("bootstrap-datepicker");
var async = require("async");

var Cookies = require("cookies-js");

var field_types = require("../../../../../student-views-common/fields/field_types");




function ProfilePage(frontend) {
	this.state = {
		frontend: frontend,
		fields_info: null
	};
}



ProfilePage.prototype.update = function(fields, cb) {
	var state = this.state;
	var fe_state = this.state.frontend.state;
	var api_user = this.state.frontend.state.api_user;

	api_user.post_data("/student/profile/update", {fields: fields}, (err, res) => {
		if (err) {
			console.log("Profile update err: " + err);
			cb(err, null);
		} else {
			console.log("Profile updated");
			console.log(res);
			cb(null, null);
		}
	});
};

ProfilePage.prototype.set_password = function(pw, cb) {
	var state = this.state;
	var fe_state = this.state.frontend.state;
	var api_user = this.state.frontend.state.api_user;

	api_user.post_data("/auth/set_password", {password: pw}, (err, res) => {
		if (err) {
			console.log("Password update err: " + err);
			cb(err, null);
		} else {
			console.log("Password updated");
			console.log(res);
			cb(null, null);
		}
	});
};

var field_res = null;

ProfilePage.prototype.load = function(cb) {
	var me = this;
	var api_user = this.state.frontend.state.api_user;

	$("#upload_photo").attr("data-url", me.state.frontend.state.api_base + "/student/profile/set_photo");

	$('#upload_photo_label').css("display", "none");

	var photo_cachebust = Cookies.get("photo_cachebust");
	if (!photo_cachebust) {
		photo_cachebust = Date.now();
		Cookies.set("photo_cachebust", photo_cachebust,  {expires: new Date("9999-09-09")});
	}

	function add_form(button) {
		let form = $("<form/>").addClass("file_upload_form");
		form.attr("action", me.state.frontend.state.api_base + "/student/profile_rewrite/upload_file");
		form.attr("method", "post");
		form.attr("enctype", "multipart/form-data");

		if (document.location.host == "localhost:1234") {
			form.attr("target", "_asdf");
		}

		function discard_form() {
			$(form).remove();
			// show the main profile page again
			$("#profile-form, .page-title").removeClass("hide");
		}

		let cancel_btn = $("<a/>").addClass("btn green-haze").css("margin-top", "10px").attr("href", "#").text(nss("103:btn_cancel_upload"));
		$(cancel_btn).on("click", ev => {
			ev.preventDefault();
			ev.stopPropagation();
			discard_form();
		});
		form.append(cancel_btn);

		form.append($("<h1/>").text(button.RecordTitle));


		let btn_index_field = $("<input/>").attr("type", "hidden").attr("name", "button_index").val(button.Index);
		form.append(btn_index_field);

		let redirect_field = $("<input/>").attr("type", "hidden").attr("name", "redirect_uri").val(document.location.href);
		form.append(redirect_field);



		


		let file_field = $("<input/>").attr("type", "file").attr("name", "upload_file");
		let accept_exts = ".jpg,.jpeg,.png";
		if (!button.RecordTitle.toLowerCase().includes("nric")) {
			accept_exts += ",.gif,.pdf";
		}
		file_field.attr("accept", accept_exts);
		form.append(file_field);



		let submit_btn = $("<input/>").addClass("btn green-haze disabled").css("margin-top", "10px").attr("type", "submit").val(nss("103:btn_upload"));


		let maybe_show_or_hide_submit = _ => {
			let ok = file_field.val();
			if (ok) {
				submit_btn.removeClass("disabled");
			} else {
				submit_btn.addClass("disabled");
			}
		};

		file_field.on("change", _=>{
			maybe_show_or_hide_submit();
		})

		$(form).on("submit", ev => {
			if ($(submit_btn).hasClass("disabled")) {
				ev.preventDefault();
				ev.stopPropagation();
				return;
			}



			// discard_form();
		});
		form.append(submit_btn);



		// add form to page
		$("#profile-form").after(form);

		// hide the main profile page
		$("#profile-form, .page-title").addClass("hide");




		

	}

	api_user.fetch_data("/student/profile/get_my_profile", null, function(err, res) {
		field_res = res;
		console.log(res);

		for (let upload of res.upload_buttons) {
			let btn = $("<a/>").addClass("btn green-haze").css("margin-top", "10px").attr("href", "#").text(upload.ButtonLabel);
			$(btn).on("click", ev => {
				ev.preventDefault();
				ev.stopPropagation();

				add_form(upload);

			});
			$("#upload_buttons").append(btn);
			// <a style="margin-top: 10px;" href="/student/profile_details" class="btn green-haze" id="btn-details">Edit my Details</a>
		}

		me.state.fields_info = res.fields_info;
		//data-student-name
		//$(".data-student-name, #profile-qa-block > .qa-heading").text(res.fields_data.name);

		$("#welcome-name").text(res.fields_data.name ? res.fields_data.name : res.fields_data.user_id);

		$("#btn-password").attr("href", "/request_password_reset?mode=reset&userid=" + res.fields_data.user_id);


		$("#user-id").val(res.fields_data.user_id);
		if (res.fields_info.user_id.writable === false) {
			$("#user-id").attr("disabled", "disabled");
		}


		$("#student-id").text(res.fields_data.student_id);
		// if (res.fields_info.student_id.writable === false) {
		// 	$("#student-id").attr("disabled", "disabled");
		// }



		if (res.profile_pic) {
			$("#photo").css("background-image",
				"url('" + res.profile_pic + "?cachebust=" + photo_cachebust + "')"
			);
		} else {
			$("#photo").css("background-image",
				"url('/static/img/default-avatar.png')"
			);
		}


		$('#upload_photo_label').css("display", "inline-block");

	    $('#upload_photo').fileupload({
	        dataType: 'json',
	        headers: {
	        	// TODO: Would be nice to have ApiUser handle this.
	        	"Authorization": "Token " + Cookies.get("token")
	        },
	        submit: function() {
	        	$('#upload_photo_label').css("display", "none");

    				$("#photo").css("background-image",
						"url('/static/img/ajax-loading.gif')"
					);

	        	return true;
	        },
	        done: function (e, data) {
	        	let result = data.result;
	        	if (result && result.status && result.status == "ok") {
	        		console.log(result.data);

    				photo_cachebust = Date.now();
					Cookies.set("photo_cachebust", photo_cachebust,  {expires: new Date("9999-09-09")});
	        		location = location;



	        	} else {
	        		alert("Error changing your profile photo. Please make sure you have selected a valid image, and try again.");
	        	}

				$("#photo").css("background-image",
					"url('" + res.profile_pic + "?cachebust=" + photo_cachebust + "')"
				);

				$('#upload_photo_label').css("display", "inline-block");


	            // $.each(data.result.files, function (index, file) {
	            //     $('<p/>').text(file.name).appendTo(document.body);
	            // });
	        }
	    });



		var fields_to_add = [];

		for (var field_name in res.fields_info) {

			if (["id", "user_id", "student_id"].indexOf(field_name) != -1) {
				continue;
			}

			let field_value = res.fields_data[field_name];
			let field_info = res.fields_info[field_name];

			if (!field_info.hide) {
				let to_add = field_types[field_info.type].render(field_name, field_value, field_info);

				$(to_add).find(".form-control").on("change select keyup blur", function(){
					let val_for_validation = field_types[field_info.type].get(to_add, field_info);
					let validation_result = field_types[field_info.type].validate(val_for_validation, field_info);
					console.log("V: " + validation_result);
				});


				fields_to_add.push(to_add);

			}





			
		}

		var chunk_size = $(".profile-form-column").length;

		// Need to strip out multiline fields, and add seperately.
		// First collect their indices, then remove them
		var multi_indices = [];
		for (let fa_i in fields_to_add) {
			if ($(fields_to_add[fa_i]).attr("data-field-type") == "multiline") {
				multi_indices.push(fa_i);
			}
		}

		var multi_rows = [];

		for (var i of multi_indices) {
			let row = $("<div/>").addClass("row");
			$(row).append(fields_to_add.splice(i, 1));
			multi_rows.push(row);
		}

		var rows = [];

		while (fields_to_add.length > 0) {
			let row = $("<div/>").addClass("row");
			let	for_row = fields_to_add.splice(0, chunk_size);
			
			for (let f of for_row) {
				$(f).addClass("col-lg-4");
			}

			$(row).append(for_row);

			rows.push(row);
		}

		var insertion_point = Math.min(rows.length, 1);

		function insertArrayAt(array, index, arrayToInsert) {
			Array.prototype.splice.apply(array, [index, 0].concat(arrayToInsert));
			return array;
		}

		insertArrayAt(rows, insertion_point, multi_rows);

		// $("#profile-form-fields").append(rows);


		cb();


	});

	var password_was_changed = false;

	function do_set_password(cb) {
		var new_pw = $("#password").val();
		if (new_pw && new_pw != "") {
			if ($("#password-confirm").val() != new_pw) {
				// TODO: Modal dialog, not alert()
				cb("Passwords do not match.");
			} else {
				me.set_password(new_pw, err => {
					if (err) {
						cb("Error updating password.");
					} else {
						password_was_changed = true;
						cb(null);
					}
				});
			}
		} else {
			cb(null);
		}
	}

	function do_update_fields(cb) {
		var to_update = {};

		$("#profile-form-fields .form-group").not("[data-disabled]").each((_, field) => {
			let field_name = $(field).attr("data-field-name");
			let field_type = $(field).attr("data-field-type");
			let field_info = me.state.fields_info[field_type];

			let field_value = field_types[field_type].get(field, field_info);
			to_update[field_name] = field_value;
		});

		if (field_res.fields_info.user_id.writable === true) {
			// $("#user-id").attr("disabled", "disabled");
			to_update["user_id"] = $("#user-id").val();
		}

		

		console.log(to_update);

		me.update(to_update, err => {
			cb(err);
		});
	}

	$("#btn-save").click( () => {

		async.series([do_set_password, do_update_fields], err => {
			if (err) {
				console.log(err);
				alert("Sorry, an error occurred: " + err);
			} else {
				if (password_was_changed) {
					// state.token = null;
					Cookies.set("token", null);
					// window.location = "/login?return=" + encodeURIComponent(document.location.pathname);
					window.location = "/login?return=" + encodeURIComponent(document.location.pathname + document.location.search);
				} else {
					alert("Profile updated.");
					window.location = window.location;
				}
			}
		});



	});
};

module.exports = ProfilePage;
