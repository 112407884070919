"use strict";

var $ = require("jquery");
// require("bootstrap-datepicker");

var common = require("./common");

var moment = require("moment");

if (typeof(window) != "undefined" ) {
	window.moment = moment;
}


module.exports = {

	render: function(field_name, field_value, field_info) {

		let f_g = common.render_group(
			field_name,
			"date",
			field_value,
			field_info
		);

		let inter_div = $("<div/>").addClass(common.control_class).addClass("datepicker-container");//.css("display", "inline-block");

		let label = common.render_label(field_info);

		let day_val = null;
		let month_val = null;
		let year_val = null;

		if (field_value) {
			let field_value_split = field_value.split("-");
			if (field_value_split.length == 3) {
				year_val = parseInt(field_value_split[0]);
				month_val = parseInt(field_value_split[1]);
				day_val = parseInt(field_value_split[2]);
			}
		}

		// console.log("M: " + month_val);
		// console.log("D: " + day_val);

		if (month_val === null && field_info.date_default_current_month) {
			month_val = moment().month()+1;
		}

		if (day_val === null && field_info.date_default_current_day) {
			day_val = moment().date();
		}

		let day = $("<select/>")
			.addClass("form-control form-control-inline input-date-component input-date-day force-inline") // form-control-inline
			// .attr("type", "text")
			// .attr("size", "2")
			// .val("")
		;

		let add_days_for_month = (month_n, year_n) => {
			console.log(year_n);
			console.log(month_n);
			let should_null_day = false;


			let date_as_chosen = $(day).find("option").filter(":selected").first().val();


			if (day_val !== null) {
				date_as_chosen = day_val;
				day_val = null;
			} else {

			}
			

			$(day).empty();
			let days_in_month = moment().year(year_n).month(month_n).endOf("month").date();

			for (let d = 0; d < days_in_month; d++) {
				let option = $("<option/>").val(d).text(d + 1);
				// console.log(date_as_chosen + " vs " + d)
				if (date_as_chosen == (d+1)) {
					option.attr("selected", "selected");
				}
				day.append(option);
			}

			if (should_null_day) {
				day_val = null;
			}

		};



		if (day_val !== null) {
			day.val(day_val);
		}

		let month = $("<select/>")
			.addClass("form-control form-control-inline input-date-component input-date-month force-inline") // form-control-inline
			// .attr("type", "text")
			// .attr("size", "16")
		;

		// let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
		let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

		for (let m = 0; m < 12; m++) {
			let option = $("<option/>").val(m).text(months[m]);
			if (month_val !== null && (month_val-1) == m) {
				option.attr("selected", "selected");
			}
			month.append(option);
		}

		let year = $("<select/>")
			.addClass("form-control form-control-inline input-date-component input-date-year force-inline") // form-control-inline
			// .attr("type", "text")
			// .attr("size", "4")
			// .val("")
			// .attr("onkeypress", 'return event.charCode >= 48 && event.charCode <= 57')
		;

		console.log(year_val);
		if (year_val === null) {
			year_val = moment().year();
		}


		let add_year = (year_to_add, make_selected) => {
			let option = $("<option/>").val(year_to_add).text(year_to_add);
			if (make_selected) {
				option.attr("selected", "selected");
			}
			year.append(option);
		};

		if (field_info.date_manual_years) {
			let the_current_year = moment().year();

			for (
				let prev_y = (the_current_year - 1);
				prev_y >= (the_current_year - field_info.date_prev_years);
				prev_y--
			) {
				add_year(prev_y, false);
			}

			add_year(the_current_year, true);

			for (
				let next_y = (the_current_year + 1);
				next_y >= (the_current_year + field_info.date_next_years);
				next_y--
			) {
				add_year(next_y, false);
			}



		} else {
			for (let y = 1905; y <= moment().year(); y++) {
				add_year(y, (year_val !== null && (year_val) == y));
			}
		}




		console.log(year);


		let add_days_wrapper = _ => {

			add_days_for_month(
				$(month).find("option").filter(":selected").first().val() ,
				$(year).find("option").filter(":selected").first().val() 
			);

		};


		$(year).on("change", _ => {
			// let year_val_temp = $(year).val();
			// if (!year_val_temp) {
			// 	$(year).val("2000");
			// } else if (year_val_temp.length > 4) {
			// 	$(year).val(year_val_temp.substr(0,4));
			// } else if (year_val_temp.length < 4) {
			// 	$(year).val("2" + "" + year_val_temp);
			// }

			add_days_wrapper();

			
		});



		

		$(month).on("change", _ => {
			add_days_wrapper();
		});
		add_days_wrapper();



		inter_div.append(day);
		inter_div.append(month);
		inter_div.append(year);

		day_val = null;
		month_val = null;
		year_val = null;

		// inter_div.append($("<span/>").addClass("help-block").text("Select date"));

		if (!field_info.writable) {
			$(inter_div).find(".input-date-component").attr("disabled", "disabled");
		}

		$(f_g).append(label);
		$(f_g).append(inter_div);


		return f_g;



	},

	validate: function(field_value, field_info) {
		var date_regex = /^\d\d\d\d-\d\d-\d\d$/;
		if ((field_value == "") && (!field_info.nullable)) {
			return false;
		}

		return date_regex.test(field_value);
	},

	get: function(field_dom, field_info) {
		console.log(field_dom);
		let day = parseInt($(field_dom).find(".input-date-day option").filter(":selected").val()) + 1;
		let month = parseInt($(field_dom).find(".input-date-month option").filter(":selected").val()) + 1;
		let year = parseInt($(field_dom).find(".input-date-year option").filter(":selected").val());

		return year + "-" + (month < 10 ? "0" : "") + month + "-" + (day < 10 ? "0" : "") + day;
	}


};

