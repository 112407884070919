"use strict";

var common = require("../common");
var $ = require("jquery");
var moment = require("moment");




var _class = class {
	constructor(jdp) {
		this.jdp = jdp;
		this.credits = [];

		let filter_controls_container = common.add_elem("div", {}, "row", this.jdp.main_elem);

		this.package_select = common.add_elem("select", {}, "form-control");
		// this.package_select.append($(`<option/>`).val("").text("All"));
		// for (let foo of ["test1", "test2"]) {
		// 	this.year_select.append($(`<option/>`).text(foo));
		// }
		let filter_group = common.add_field_group("", this.package_select, filter_controls_container);
		filter_group.css("margin-bottom", "5px");

		this.misc_container = common.add_elem("div", {}, "", this.jdp.main_elem);
	}

	get_basic_info() {
		return {
			title_sid: "101:title_my_credits",
			record_type_plural: nss("106:record_type_plural_credits")
		};
	}

	fill_table() {

		$(this.table).find("tbody tr").remove();

		let credit_rows_to_use = this.credits.rows;

		if (credit_rows_to_use.length < 1) {
			$(".msg-no-results").removeClass("hide");
			return;
		} else {
			$(".msg-no-results").addClass("hide");
		}

		common.build_table(this.table, this.credits.legend, credit_rows_to_use);





	}

	on_layout() {
		

	}

	async load_credits_for_package(package_id) {

		this.credits = await this.jdp.api_user.fetch_data_promise("/student/package_iec/get_my_credits/" + package_id, null);

		// for (let credit of this.credits.rows) {
		// 	for (let date_field of ["Date_Issued", "Expiry_Date", "Valid_Till"]) {
		// 		if (credit[date_field]) {
		// 			credit[date_field] = moment(credit[date_field]).format("DD MMM YYYY").replace(/ /g, '\u00a0');
		// 		}
		// 	}

		// 	for (let time_field of ["Lesson_Start_Time", "Clock_In_Time"]) {
		// 		if (credit[time_field]) {
		// 			credit[time_field] = moment(credit[time_field]).format("HH:mm").replace(/ /g, '\u00a0');
		// 		}
		// 	}

		// }
	}

	async on_load() {

		let packages = await this.jdp.api_user.fetch_data_promise("/student/package_iec/get_my_packages", null);

		for (let _package of packages.rows) {
			let formatted_name = `${_package.Package_Name}${"\u00A0\u00A0\u00A0"}${_package.Date_Issued} - ${_package.Valid_Till}`;

			let option = $("<option/>");
			option.val(_package.pu_id);
			option.text(formatted_name);
			if (this.jdp.args.pu_id && (this.jdp.args.pu_id == _package.pu_id)) {
				option.attr("selected", "selected")
			}
			this.package_select.append(option);
		}

		this.package_select.change(_=>{
			window.location = common.build_jdp_url("my_credits", {pu_id: this.package_select.val()});
			// this.fill_table();
		});

		let package_id = this.package_select.val();
		if (!package_id) {
			throw "Package not found.";
		}

		let _package = packages.rows.find(p => p.pu_id == package_id);
		if (_package) {
			console.log(_package);
			this.misc_container.append($("<p/>").text(nss("106:my_credits_total_credits", [_package.Total_Credits])).css({"margin-bottom": "5px", "margin-left": "9px"}));
			this.misc_container.append($("<p/>").text(nss("106:my_credits_avail_credits", [_package.Credits_Balance])).css({"margin-left": "9px"}));
		}

		await this.load_credits_for_package(package_id);
		this.table = common.make_empty_table();
		this.fill_table();
		this.jdp.main_elem.append(this.table);

	}




};

module.exports = _class;
window.register_jdp((require('path')).basename(__filename, ".js"), _class);