"use strict";

var $ = require("jquery");
var common = require("../../common");
var BootstrapDialog = require("bootstrap3-dialog");

var FeeToRowMapper = ["item", "type", "month", "duration", "amount", "paid", "due", "remark"];

function mapRecordToRow(record, mapper_array, transformer) {
	console.log(record);
	var tr = $("<tr/>");
	for (var field of mapper_array) {
		console.log(field);
		var result = transformer ? transformer(field, record[field]) : record[field];
		var cell = $("<td/>").text(result);
		$(tr).append(cell);
	}

	return tr;
}


function FeesPage(frontend) {
	this.state = {
		frontend: frontend
	};
}

FeesPage.prototype.load = function(cb) {
	var api_user = this.state.frontend.state.api_user;
	let strings = this.state.frontend.state.res.fees.en;


	let args = common.get_args();

	let mode = args["mode"];

	if (!mode) {
		api_user.fetch_data("/student/fees_rewrite/get_not_paid_full", null, (err, res) => {

			if (!res) {
				res = {};
			}

			if (res.show_pay_button || res.show_payment_portal_button) {
				if (res.show_payment_portal_button) {
					$("#pay-btn").text(res.payment_portal_button_text);
				}
				$("#pay-btn").removeClass("hide");
				if (!res.show_payment_portal_button && res.stripe_settings.enabled && res.paypal_enabled) {
					$("#payment-method-select").removeClass("hide");
				}
			}

			if (res.template) {
				$("#fees-instructions").html(res.template);
			}

			if (!res.total_amount) {
				console.log("Nothing to pay.")
				$("#oustanding-string").text(strings.none_due);
				$("#fees-table").addClass("hide")

				$("#invoice-amount-due, #invoice-amount-tax, #invoice-amount-total")
					.text(window.currency + "0.00");




				cb();
				return;
			}

			$("#invoice-amount-due").text(  window.currency + res.total_due.toFixed(2)   );
			$("#invoice-amount-tax").text(  window.currency + res.total_tax.toFixed(2)   );
			$("#invoice-amount-total, #fees-qa-block .qa-heading").text(window.currency + res.total_amount.toFixed(2));


			var records = res.records;


			for (let record of records.rows) {
				for (let currency_field of ["amount", "paid", "due"]) {
					record[currency_field] = window.currency + record[currency_field].toFixed(2);
				}
			}

			common.build_table($("#fees-table"), records.legend, records.rows, null, {
				on_extra_action_callback: (extra_actions_field, action_id, payload) => {
					if ((extra_actions_field == "extra_item_actions") && (action_id == "delete_package")) {
						console.log(payload);

						api_user.post_data(
							"/student/package_iec/fees_delete_package",
							{
								packId: payload,
							}, (remove_err, remove_res) => {
								if (remove_err) {

									let err_msg = nss("106:delete_package_err_" + remove_err) || "Unknown error.";

									BootstrapDialog.show({
										message: err_msg,
										title: "Details",
										cssClass: "very-high-zindex",
										type: BootstrapDialog.TYPE_INFO,
										buttons: [
											{
												label: "Close",
												action: function(dialogItself){
													dialogItself.close();
													window.location.reload();
												}
											}
										]
									});

								} else {
									window.location.reload();
								}
							});

					}
				}
			});

			let detail_links = $(".fee-item-link a");

			for (let detail_link of detail_links) {

				let expand_button = $("<div/>").addClass("fee-item-expand-button");
				$(detail_link).prepend(expand_button);
				$(expand_button).text("+");

				let _detail_tables = [];
				let should_add = true;

				let was_clicked = false;
				$(detail_link).click(function(e){
					e.preventDefault();
					e.stopPropagation();
					if (was_clicked) {
						was_clicked = false;
						should_add = false;
						$(detail_link).css("color", "");
						$(expand_button).text("+");
						for (let tbl of _detail_tables) {
							$(tbl).remove();
						}

						return;
					}
					should_add = true;
					was_clicked = true;
					$(detail_link).css("color", "gray");
					$(expand_button).text("-");

					let href_parts = $(this).attr("href").replace("#", "").split(",").map(p => p.split("=").map(pp=>pp.trim()));
					let fee_id = href_parts.find(hp => hp[0]==="id")[1];
					console.log(fee_id);

					api_user.fetch_data("/student/fees_rewrite/get_payment_detail?fee_id=" + fee_id, null, (err, details) => {
						if (!should_add) return;
						let details_table = $.parseHTML(`<table class="table fee-details-table table-striped table-hover"><thead><tr></tr></thead><tbody></tbody></table>`);
						common.build_table(details_table, details.legend, details.rows);


						if ($(window)[0].outerWidth > 1160) {
							$(details_table).addClass("desktop-only");
							let tr = $(this).parent().parent().parent();

							let new_tr = $("<tr/>");
							let new_td = $("<td/>");
							$(new_td).css({
							    "padding-top": 0,
							});
							$(new_td).append(details_table);
							$(new_tr).append(new_td);

							$(tr).after(new_tr);

							$(new_tr).css({
								"height": $(details_table).height() + 20
							});

							_detail_tables.push(new_tr);
						} else {
							was_clicked = false;
							$(detail_link).css("color", "");
							BootstrapDialog.show({
								message: details_table,
								title: "Details",
								cssClass: "very-high-zindex",
								type: BootstrapDialog.TYPE_INFO,
								buttons: [
									{
										label: "Close",
										action: function(dialogItself){
											dialogItself.close();
										}
									}
								]
							});
						}


					});




					return true;
				});
			}


			$("#pay-btn").click(e => {
				$("#pay-btn").addClass("disabled");
				$("#pay-btn,#payment-method-select,#fees-instructions,#past-btn").addClass("hide");
				e.preventDefault();

				if (res.show_payment_portal_button) {
					api_user.post_data(
						"/student/fees_rewrite/create_payment_portal_link",
						{},
						(err, payment_link) => {
							console.log(payment_link);
							if (payment_link) {
								window.location = payment_link;
							}
						}
					);
					return;
				} else if (res.stripe_settings && res.stripe_settings.enabled && ($("[name=payment-method]:checked").val() == "payment-method-stripe")) {
					window.location = "/jdp?page=credit_card&is_pay_flow=true";
					return;
				} else if (res.paypal_enabled) {
					// paypal
	        		let base_url = window.location.href.split("/").splice(0,3).join("/");

					api_user.post_data(
					"/student/fees_rewrite/collectOutstandingFee",
					{
						returnUrl: base_url + "/student/fees?mode=paypal_return",
						cancelUrl: base_url + "/student/fees?mode=paypal_cancel",
					},
					(err, payment_req_res) => {
						console.log(err);
						console.log(payment_req_res);

						if (err) {
							BootstrapDialog.show({
								message: this.state.frontend.get_string("payment_error_message") + "\n\n" + "Error code: " + err,
								title: this.state.frontend.get_string("payment_error"),
								cssClass: "very-high-zindex",
								type: BootstrapDialog.TYPE_WARNING,
								closable: false,
								buttons: [
									{
										label: this.state.frontend.get_string("ok"),
										action: (dialogItself) => {
										dialogItself.close();
										window.location = window.location;
										}
									},
								]
							});
						} else {
							window.location = payment_req_res.href;
						}
					}
					);
				} else {
					// no payment methods
					BootstrapDialog.show({
						message: this.state.frontend.get_string("payment_error_message") + "\n\n" + "Error code: " + "no_payment_methods",
						title: this.state.frontend.get_string("payment_error"),
						cssClass: "very-high-zindex",
						type: BootstrapDialog.TYPE_WARNING,
						closable: false,
						buttons: [
							{
								label: this.state.frontend.get_string("ok"),
								action: (dialogItself) => {
								dialogItself.close();
								window.location = window.location;
								}
							},
						]
					});
				}
			}).removeClass("disabled");



			cb();

		});

	} else if (mode == "paypal_return") {
		$(".page-title").text(strings.paypal_wait);
		$("#enroll-btn").addClass("hide");

		let order_no = args["orderNo"];
		let payment_id = args["paymentId"];
		let payer_id = args["PayerID"];

		api_user.fetch_data(
			"/student/fees_rewrite/executePaypalCheckout_outstanding/" + order_no + "/" + payment_id + "/" + payer_id,
			{
				cachebust: true,
				empty_data_ok: true
			},
			(err, payment_execute_res) => {
				console.log(err);
				console.log(payment_execute_res);

				if (err) {
					$(".page-title").text(strings.payment_error);

					let error_btn = $("<button/>").addClass("btn-fake-link").text(nss("103:btn_show_error_detail")).on("click", _=>{
						error_btn.remove();
						$("#error_info").append($("<p/>").addClass("error-info-param").text(err));
						if (order_no) {
							$("#error_info").append($("<p/>").addClass("error-info-param").text("Order no.: " + order_no));
						}
						if (payment_id) {
							$("#error_info").append($("<p/>").addClass("error-info-param").text("Payment ID: " + payment_id));
						}
						if (payer_id) {
							$("#error_info").append($("<p/>").addClass("error-info-param").text("Payer ID: " + payer_id));
						}


					});

					$("#error_info").append(error_btn);

					$(".invoice-block").addClass("hide");

				} else {
					$(".page-title").text(strings.thank_you);
					window.location = "/student/fees";
				}

				cb();

			}
		);


		

	} else if (mode == "paypal_cancel") {
		$(".page-title").text(strings.paypal_cancel);
		$("#enroll-btn").addClass("hide");






		let order_no = args["orderNo"];

		api_user.fetch_data(
			"/student/fees_rewrite/cancelPaypalCheckout_outstanding/" + order_no,
			{
				cachebust: true,
				empty_data_ok: true
			},
			(err, payment_execute_res) => {
				console.log(err);
				console.log(payment_execute_res);

				if (err) {
					$(".page-title").text(strings.payment_error);
				} else {
					$(".page-title").text(strings.paypal_cancel);
				}

				cb();

			}
		);









	} else {
		// ?
	}
	//
};

module.exports = FeesPage;