"use strict";

var common = require("../common");
var $ = require("jquery");
var moment = require("moment");

const base64url = require('base64-url');


var _class = class {
	constructor(jdp) {
		this.jdp = jdp;
		this.historystack = [];
		window.onpopstate = async e => {
			let state = e.state;
			await this.process_state(state);
		};

		window._this = this;
		// console.log(jdp.args.data);
		// this.data = JSON.parse(jdp.args.data);
	}

	get_basic_info() {
		return {
			title_sid: "101:My Videos",
			// record_type_plural: ""
		};
	}

	/*

	state: {
		stage: "home/view_module/choose_month/view_month"
	}

	*/

	clear_contents() {
		$(this.jdp.main_elem).empty();
	}

	async push_and_process_state(fragment, state) {
		history.pushState(state, "", "#" + encodeURIComponent(JSON.stringify(state)));
		this.process_state(state);
	}

	show_not_found_msg() {
		this.jdp.main_elem.append(`
			<div class="alert alert-warning msg-no-results">
				<strong>${window.nss("106:no_results")}</strong>
				<br>
				<span>${window.nss("106:generic_none_found", ["videos"])}</span>
			</div>
		`);
	}

	async process_state(state) {

		this.clear_contents();

		if (!state) {
			state = {
				stage: "home"
			}
		}

		switch (state.stage) {
			case "home":

				if ((!this.all_videos.courses || this.all_videos.courses.length < 1) && (!this.all_videos.modules || this.all_videos.modules.length < 1)) {
					this.show_not_found_msg();
					return;
				}

				if (this.all_videos.courses && (this.all_videos.courses.length > 0)) {
					let courses_container_outer = common.add_elem("div", {}, "", this.jdp.main_elem);
					common.add_elem("h1", {}, "courses_container_outer", courses_container_outer).text("Course Videos");

					let courses_container = common.add_elem("div", {}, "courses_container", courses_container_outer);

					for (let course of this.all_videos.courses) {
						// let course = this.all_videos.courses[course_i];
						let course_div = common.add_elem("div", {}, "course", courses_container);
						common.add_elem("span", {}, "course_code_list_entry", course_div).text("Course code: " + fix_html_ents(course.cou_code));
						common.add_elem("span", {}, "course_name_list_entry", course_div).text("Course name: " + fix_html_ents(course.cou_name));
						// common.add_elem("span", {}, "course_name_list_entry", course_div).text("id: " + course.cou_id);

						$(course_div).click(async _ => {

							let next_state = {
								stage: "choose_month",
								course: course.cou_id
							};
							console.log("Next state: ", next_state);

							await this.push_and_process_state("choose_month", next_state);
						});
					}
				}



				if (this.all_videos.modules && (this.all_videos.modules.length > 0)) {
					let modules_container_outer = common.add_elem("div", {}, "", this.jdp.main_elem);
					common.add_elem("h1", {}, "modules_container_outer", modules_container_outer).text("Module Videos");

					let modules_container = common.add_elem("div", {}, "modules_container", modules_container_outer);

					for (let module_i in this.all_videos.modules) {
						let module = this.all_videos.modules[module_i];
						let module_div = common.add_elem("div", {}, "module", modules_container);
						common.add_elem("span", {}, "module_title_list_entry", module_div).text(module.package_name);

						$(module_div).click(async _ => {
							await this.push_and_process_state("view_module", {
								stage: "view_module",
								module_index: module_i
							});
						});
					}
				}
			break;

			case "view_module":
				let module = this.all_videos.modules[state.module_index];
				common.add_elem("h2", {}, "module_title_standalone", this.jdp.main_elem)
					.text("Module: " + module.package_name);

				let contents_div = common.add_elem("div", {}, "module_lessons", this.jdp.main_elem);

				for (let lesson of module.lessons) {
					let lesson_div = common.add_elem("a", {}, "module_lessons_lesson", contents_div);
					$(lesson_div).text(lesson.title).attr("href", "/student/watch_my_video?module_lesson=" + lesson.lesson_id);
				}

			break;

			case "choose_month":
			{
				$(".jdp-spinner").show();

				let course = this.all_videos.courses.find(c => c.cou_id == state.course);

				if (!course) {
					this.show_not_found_msg();
					return;
				}

				console.log(course);


				let months = await this.jdp.api_user.fetch_data_promise(
					"/student/my_videos_rewrite/get_months_for_course_rewrite?cou_id=" + course.cou_id,
					null
				);

				if (!months) {
					this.show_not_found_msg();
					return;
				}


				let months_container_outer = common.add_elem("div", {}, "", this.jdp.main_elem);

				common.add_elem("span", {}, "course_code_standalone", months_container_outer).text("Course code: " + fix_html_ents(course.cou_code));
				common.add_elem("span", {}, "course_name_standalone", months_container_outer).text("Course name: " + fix_html_ents(course.cou_name));


				let months_container = common.add_elem("div", {}, "months_container", months_container_outer);






				$(".jdp-spinner").hide();

				console.log("Months:", months);

				for (let month_i in months) {
					let month = months[month_i];
					let month_div = common.add_elem("div", {}, "month", months_container);
					common.add_elem("span", {}, "month_name", month_div).text(month.month_name);

					$(month_div).click(async _ => {
						await this.push_and_process_state("view_month", {
							stage: "view_month",
							course: state.course,
							month: month.month_code,
							// month_name: month.month_name
						});
					});
				}

			}



			break;

			case "view_month":
			{

				let last_watched = Cookies.get("last_video_id");
				let have_added_last_watched = false;

				$(".jdp-spinner").show();
				
				let videos = await this.jdp.api_user.fetch_data_promise(
					"/student/my_videos_rewrite/get_lessons_for_course_month_rewrite?cou_id=" + state.course + "&month=" + state.month,
					null
				);

				$(".jdp-spinner").hide();

				console.log(videos);
				
				// let flat = videos
				// 	.map(sc => sc.lessons).flat().map(l => l.videos).flat();
				// 	// .map(f => { return {
				// 	// 	cl_id: f.cl_id,
				// 	// 	f_id: f.f_id
				// 	// }; });

				// window._videos = videos;

				let flat = videos.map(v => v.lessons).flat().map( l => l.videos.map(lv => ({ f_id: lv.f_id, cl_id: lv.cl_id, name: lv.name })) ).flat();

				console.log("Flat: ", flat);
				let course = this.all_videos.courses.find(c => c.cou_id == state.course);

				let base_watch_url = "/student/watch_my_video?course_video_data=";

				let source_courses_container_outer = common.add_elem("div", {}, "", this.jdp.main_elem);

				common.add_elem("span", {}, "course_code_standalone", source_courses_container_outer).text("Course code: " + fix_html_ents(course.cou_code));
				common.add_elem("span", {}, "course_name_standalone", source_courses_container_outer).text("Course name: " + fix_html_ents(course.cou_name));
				
				let month_name = moment(state.month).format("MMMM YYYY")
				common.add_elem("h2", {}, "month_name_standalone", source_courses_container_outer).text(month_name);


        for (let source_course of videos) {

         let source_course_container_outer = common.add_elem("div", {}, "source_course_container_outer", source_courses_container_outer);
          
         if (!source_course.is_native) {
           common.add_elem("h3", {}, "", source_course_container_outer).text("Videos from another course");
           common.add_elem("span", {}, "course_code_standalone", source_course_container_outer).text("Course code: " + fix_html_ents(source_course.original_course_code));
           common.add_elem("span", {}, "course_name_standalone", source_course_container_outer).text("Course name: " + fix_html_ents(source_course.original_course_name));
         }

         let lessons_container_outer = common.add_elem("div", {}, "lessons_container_outer", source_course_container_outer);

         for (let lesson of source_course.lessons) {
           let lessons_container = common.add_elem("div", {}, "lesson_container", lessons_container_outer);

           common.add_elem("span", {}, "lesson_date", lessons_container).text("Lesson: " + lesson.lesson_date_human);
           common.add_elem("span", {}, "lesson_remark", lessons_container).text(lesson.remarks || "(No remarks)");


           let videos_container = common.add_elem("div", {}, "videos_container", lessons_container);

           console.log(lesson.videos);
           for (let video of lesson.videos) {

             // let clid = lesson.cl_id;
             // let file_id = 

             let vid = common.add_elem("a", {}, "video_link", videos_container);

             let data = {
               flat: flat,
               index_into_flat: flat.findIndex(f => { return (f.cl_id == video.cl_id) && (f.f_id == video.f_id); }),
               return_url: document.location.href
             };

             let b64 = base64url.encode(JSON.stringify(data));

             let url = base_watch_url + b64;

             console.log(data);

						if (last_watched && (last_watched == (`${video.cl_id}-${video.f_id}`))) {
							if (!this.have_added_last_watched) {
								$(vid).addClass("lastWatched");
								this.have_added_last_watched = true;
							}
							
						}

						// \u25B6

             $(vid).attr("href", url).text("\u00A0\u00A0\u00A0" + video.name/* + " , clid=" + video.cl_id + ", fid=" + video.f_id*/);

           }


         }
        }
			}
			break;
		}


	}


	on_layout() {
	}

	async on_load() {


		common.add_css(`

			.page-container {
				min-height: calc(100vh - 60px);
			}

			.module, .course, .month {
				border: solid 1px gray;
				padding: 10px;
				margin-left: 2px;
				margin-top: 5px;
				background-image: url(/static/img/video_group.png?v=2);
				background-repeat: no-repeat;
				background-position-x: 7px;
				background-position-y: 8px;
				padding-left: 54px;
				min-height: 58px;
				display: block;
				cursor: default;
			}

			.module_lessons_lesson {
				display: block;
			}

			.course_code_list_entry, .course_name_list_entry, course_code_standalone, .course_name_standalone {
				display: block;
			}


			.month_name_standalone {
				font-size: 19px;
				margin-top: 10px;
			}

			.source_course_container_outer {
				// border: solid 1px red;
				margin-bottom: 10px;
				margin-left: 10px;
			  //border-top: dashed 1px black;
			  //padding-top: 10px;
			}

			.lesson_container {
				border-left: solid 1px black;
				padding-left: 10px;
				margin-top: 10px;
				margin-left: 10px;
			}

			.lesson_date, .lesson_remark, .video_link {
				display: block;
			}

			.lesson_remark {
				white-space: pre-line;
			}

			.lastWatched:before {
		content: " ";
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 0 7px 13.9px;
    border-color: transparent transparent transparent #7026e0;
    margin-left: -10px;
    margin-top: 2px;
    opacity: 0.8;
			}


		`);


		let data = this.data;
		let url = `/student/my_videos_rewrite/get_my_videos_all_rewrite`

		let videos = await this.jdp.api_user.fetch_data_promise(url, null);
		// common.add_elem("p", {}, "", this.jdp.main_elem).html(message.message);

		this.all_videos = videos;

		if (window.location.hash) {
			let state = JSON.parse(decodeURIComponent(window.location.hash.substr(1)));
			await this.process_state(state);
		} else {
			await this.process_state(null);
		}

		

		// let level1 = common.add_elem("div", {}, "", this.jdp.main_elem);

		// let btn1 = common.add_elem("button", {}, "", level1);

		// $(btn1).text("Test1").click(e => {
		// 	console.log("btn1");
		// });

	}




};

module.exports = _class;
window.register_jdp((require('path')).basename(__filename, ".js"), _class);