"use strict";

var common = require("../common");
var $ = require("jquery");
var moment = require("moment");

var _class = class {
	constructor(jdp) {
		this.jdp = jdp;
		this.packages = [];
		console.log("my_packages");

		let filter_controls_container = common.add_elem("div", {}, "row", this.jdp.main_elem);

		this.year_select = common.add_elem("select", {}, "form-control");
		this.year_select.append($(`<option/>`).val("").text(nss("103:label_all")));
		common.add_field_group(nss("103:label_year"), this.year_select, filter_controls_container);

		let search_input = common.add_elem("input", "width: 150px; margin-right: 40px;", "form-control");
		search_input.val(this.jdp.args.search || "")
		let search_group = common.add_field_group(nss("103:label_search"), search_input, filter_controls_container);

		let search_btn = common.add_elem("div", `
			width: 34px;
			height: 34px;
			position: absolute;
			right: -38px;
			bottom: 0px;
			border-radius: 4px;
			font-size: 28px;
			line-height: 24px;
			text-align: center;
		`, "bg-color-btn", search_group);

		search_btn.append($("<i/>").addClass("fa fa-search").css({"color": "white", "font-size": "20px"}));


		let on_search_clicked = _=>{
			let opts = [];
			let year = this.year_select.val();
			if (year) {
				opts.push(`year=${year}`);
			}
			let search = search_input.val();
			if (search) {
				opts.push(`search=${encodeURIComponent(search)}`);
			}
			let url = `/jdp?page=my_packages${opts.map(o => ("&" + o)).join("")}`;
			window.location = url;
		};

		search_btn.click(_=>{
			on_search_clicked();
		});

		search_input.on("keypress", e => {
			if (e.which === 13) {
				on_search_clicked();
			}
		});

	}

	get_basic_info() {
		return {
			title_sid: "101:title_my_packages",
			record_type_plural: "packages"
		};
	}

	fill_table() {

		$(this.table).find("tbody tr").remove();

		let package_rows_to_use = this.packages.rows.filter(r => {
			let year = this.year_select.val();
			if (year && (moment(r.Date_Issued_Internal).year() != year)) return false;
			return true;
		});

		for (let pr of package_rows_to_use) {
			if (pr["Receipt_No"] && Array.isArray(pr["Receipt_No"])) {
				pr["Receipt_No"] = pr["Receipt_No"].join(", ");
			}
		}

		if (package_rows_to_use.length < 1) {
			$(".msg-no-results").removeClass("hide");
			return;
		} else {
			$(".msg-no-results").addClass("hide");
		}

		console.log(package_rows_to_use);

		common.build_table(this.table, this.packages.legend, package_rows_to_use, "pu_id");

		// Other pages use link info supplied over the API's legend.
		// But none of the fields are guranteed to be there, so instead
		// substitute the contents of the "first" (leftmost) column with a link.

		let rows = $(this.table).find("tbody tr");
		for (let row of rows) {
			let first_col = $(row).find("td").first().find(".data-span");
			let first_col_contents = first_col.html();
			let a = $("<a/>").attr("href", common.build_jdp_url("my_credits", {pu_id: $(row).attr("data-row-id")}));
			a.html(first_col_contents);
			$(first_col).empty().append(a);
			console.log(first_col_contents);
		}

	}

	on_layout() {

	}

	async on_load() {
		let url = "/student/package_iec/get_my_packages";
		url  += (this.jdp.args.search ? ("?keyword=" + this.jdp.args.search ) : "");

		this.packages = await this.jdp.api_user.fetch_data_promise(url, null);
		console.log(this.packages);

		let years = [];
		for (let _package of this.packages.rows) {
			if (_package.Date_Issued_Internal) {
				let m = moment(_package.Date_Issued_Internal);
				let year = m.year();
				if (years.indexOf(year) == -1) years.push(year);
			}
			
		}

		for (let year of years) {
			let option = $("<option/>");
			option.val(year);
			option.text(year);
			if (this.jdp.args.year && (this.jdp.args.year == year)) {
				option.attr("selected", "selected")
			}
			this.year_select.append(option);
		}

		this.year_select.change(_=>{
			this.fill_table();
		});

		this.table = common.make_empty_table();

		this.fill_table();
		
		this.jdp.main_elem.append(this.table);
	}


};

module.exports = _class;
window.register_jdp((require('path')).basename(__filename, ".js"), _class);