"use strict";

var common = require("../common");
var $ = require("jquery");
var moment = require("moment");




var _class = class {
	constructor(jdp) {
		this.jdp = jdp;
		// common.add_elem("p", {}, "", jdp.main_elem).text("Buy this package?");
	}

	get_basic_info() {
		return {
			title_sid: "101:title_confirm_sessions",
			// record_type_plural: ""
		};
	}


	on_layout() {
	}

	make_abs_url(rel_url) {
		let a = document.createElement("a");
		a.href = rel_url;
		return a.href;
	}

	async book_lesson() {
		try {
			let clids = JSON.parse(this.jdp.args.datas).map(d => d.cl_id);

			let data = {
				cl_ids: clids,
				pu_id: this.jdp.args.pu_id
			};

			let result = await this.jdp.api_user.post_data_promise(`/student/package_iec/book_lesson`, {
				url_receipt: this.jdp.args.receipt_url,
				clids: clids,
				puid: parseInt(this.jdp.args.pu_id),
			});

			console.log(result);



			window.location = common.build_jdp_url("sessions_thank_you", {data: JSON.stringify(data)});



		} catch (e) {
			console.log(e);
			if (["no_credit_available", "no_credits_available", "no_enough_credits", "not_enough_credits"].indexOf(e) != -1) {
				this.jdp.simple_show_error(e, "You do not have enough credits to book these sessions.");
			} else if (["student_already_in_lesson"].indexOf(e) != -1) {
				this.jdp.simple_show_error(e, "You are already in one or more of these lessons.");
			} else if ([
"max_lesson_capacity",
"max_venue_capacity",
"package_not_paid",
"package_not_allow_online_purchase",
"package_expired",
"no_credit_available",
"credit_type_not_match",
"unsupported_credit_type"
			].indexOf(e) != -1) {
				this.jdp.simple_show_error(e, e);
			} else {
				this.jdp.simple_show_error(e);
			}


			
		}
	}

	async on_load() {

		let datas = JSON.parse(this.jdp.args.datas);

		for (let data of datas) {
			let dom = common.add_elem("div", `
				margin-bottom: 10px;
				border: 1px solid #828282;
				padding-left: 5px;
				padding-right: 5px;
				border-radius: 4px;
			`, "package-dom");

			let name = "";
			if (data.Course_Name) name += data.Course_Name;
			if (data.Course_Code) name += (" (" + data.Course_Code + ")");

			dom.append(`<h4><strong>${name}</strong></h4>`);
			dom.append(`<h4><span>${data.Date}</span></h4>`);
			dom.append(`<h4><span>${data.Time}</span></h4>`);


			this.jdp.main_elem.append(dom);
		}



		let button = $("<a/>").text("Confirm").addClass("btn green-haze").attr("href", "#");
		button.click(e => {
			e.preventDefault();
			button.addClass("disabled");
			$(".jdp-spinner").show();
			this.book_lesson();
		});
		this.jdp.main_elem.append(button);


	}




};

module.exports = _class;
window.register_jdp((require('path')).basename(__filename, ".js"), _class);