import React from 'react';

export function CardDetailsTable(props) {
  const saved = props.saved;
  return <table className="table table-striped" style={{maxWidth: 400}}><tbody>
    <tr><td><strong>{nss("106:ccard_details_name")}</strong>:&nbsp;</td><td>{saved.name}</td></tr>
    <tr><td><strong>{nss("106:ccard_details_no")}</strong>:&nbsp;</td><td>{saved.no}</td></tr>
    <tr><td><strong>{nss("106:ccard_details_expiry")}</strong>:&nbsp;</td><td>{saved.expiry}</td></tr>
    <tr><td><strong>{nss("106:ccard_details_brand")}</strong>:&nbsp;</td><td>{saved.brand}</td></tr>
    <tr><td><strong>{nss("106:ccard_details_country")}</strong>:&nbsp;</td><td>{saved.country}</td></tr>
    <tr><td><strong>{nss("106:ccard_details_register_date")}</strong>:&nbsp;</td><td>{saved.register_date}</td></tr>
  </tbody></table>
}