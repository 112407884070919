"use strict";

var common = require("../common");
var $ = require("jquery");
var moment = require("moment");




var _class = class {
	constructor(jdp) {
		this.jdp = jdp;
	}

	get_basic_info() {
		return {
			title_sid: "106:please_wait",
			// record_type_plural: ""
		};
	}


	on_layout() {
	}

	async on_load() {
		let url = "/student/package_iec/cancelPaypalCheckout/" + this.jdp.args.orderNo;
		let _ = this.jdp.api_user.post_data_promise(url);


		$("title, .page-title").text("Checkout cancelled.");

		// window.location = common.build_jdp_url("checkout_package_thank_you");
	}




};

module.exports = _class;
window.register_jdp((require('path')).basename(__filename, ".js"), _class);