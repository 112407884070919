"use strict";
var React = require("react");

function exists(thing) {
  return (thing !== null) && (typeof(thing) != "undefined");
}

module.exports = class DropDownField extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const show_empty = (this.props.dropdown_options.findIndex(ut => ut.id == this.props.value) == -1);
    // const show_empty = true;
    
    return <React.Fragment>
      <select id={this.props.fieldKey} value={exists(this.props.value) ? this.props.value : ""} className="form-control" onChange={ev => this.props.onChange(ev.target.value)}>
        {show_empty && <option key={this.props.fieldKey + "_default"} value={this.props.value}></option> }
        {this.props.dropdown_options.map(o => <option key={o.id} value={o.id}>{o.name}</option> )}
      </select>
      {this.props.error && <p style={{color: "red", marginTop: 5,}}>{this.props.error}</p>}
    </React.Fragment>
    
  }
}