"use strict";
var React = require("react");

module.exports = class TextField extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {

    return <React.Fragment>
    {this.props.multi
      && <textarea className="form-control" id={this.props.fieldKey} value={this.props.value||""} onChange={ev => this.props.onChange(ev.target.value)} />
      || <input    className="form-control" id={this.props.fieldKey} value={this.props.value||""} onChange={ev => this.props.onChange(ev.target.value)} />
    }
      
      {this.props.error && <p style={{color: "red", marginTop: 5,}}>{this.props.error}</p>}
    </React.Fragment>;
  }
}