"use strict";

var $ = require("jquery");

var common = require("./common");

module.exports = {

	render: function(field_name, field_value, field_info) {

		let f_g = common.render_group(
			field_name,
			"dropdown",
			field_value,
			field_info
		);

		let label = common.render_label(field_info);

		let field = $("<select/>")
			.addClass("form-control").addClass(common.control_class)
		;

		if (field_info.options) {
			if (field_info.options[0] && (typeof (field_info.options[0]) == "object")) {
				let default_fallback = null;
				let value_present = false;
				for (let option of field_info.options) {
					let to_append = $("<option/>").val(option.id).text(option.name);
					if (option.default) {
						default_fallback = to_append;
					}
					if (option.id == field_value) {
						$(to_append).attr("selected", "selected");
						value_present = true;
					}
					$(field).append(
						to_append
					);
				}
				if (default_fallback && !value_present) {
					$(default_fallback).attr("selected", "selected");
				}
				

			} else {

				for (let option in field_info.options) {
					let to_append = $("<option/>").val(option).text(field_info.options[option]);
					if (option == field_value) {
						$(to_append).attr("selected", "selected");
					}
					$(field).append(
						to_append
					);
				}

			}

		}
		

		if (!field_info.writable) {
			$(field).attr("disabled", "disabled");
		}

		$(f_g).append(label);
		$(f_g).append(field);


		return f_g;



	},

	validate: function(field_value, field_info) {
		if ((field_value === null) || (typeof field_value == "undefined")) {
			return false;
		}

		if (!field_info.options || field_info.options.length < 1) {
			return false;
		}

		if (typeof field_info.options[0] == "object") {
			let opt = field_info.options.find(o => (o.id == field_value));
			return !((field_value === null) || (typeof field_value == "undefined"));
		} else {
			return ((typeof field_info.options[field_value] != "undefined"));
		}

		
	},

	get: function(field_dom, field_info) {
		return $(field_dom).find(".form-control").first().val();
	}


};

