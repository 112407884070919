"use strict";

var common = require("../common");
var $ = require("jquery");
var moment = require("moment");
var Cookies = require("cookies-js");



var _class = class {
  constructor(jdp) {
    this.jdp = jdp;
  }

  get_basic_info() {
    return {
      title_sid: "106:please_wait",
      // record_type_plural: ""
    };
  }


  on_layout() {
  }

  async on_load() {
    let token = this.jdp.args.token;
    if (!token) {
      throw "no_token";
    }

    console.log(token);

    let message_data = await this.jdp.api_user.fetch_data_promise("/auth/get_login_details");

    // let url = "/auth/token_login" 
    //+ this.jdp.args.orderNo + "/" + this.jdp.args.paymentId + "/" + this.jdp.args.PayerID;
    let data = await this.jdp.api_user.post_data_promise("/auth/token_login", {
      token: token,
    });
    console.log(data);

    // TODO don't duplicate this from LoginPage.js
    if (data.is_new) {
      Cookies.set("is_new", true,  {expires: new Date("9999-09-09")});
    } else {
      Cookies.expire("is_new");
    }

    Cookies.set("token", data.token, {expires: 60*60*24*7 });
    Cookies.set("user_id", data.username);
    Cookies.expire("did_recalc");
    Cookies.expire("last_video_id");

    let link_makers = {
      Payment_Advice: () => {
        
      },
      Lesson_Reminder: () => {
        
      },
      Communication: () => {
        
      },
      Attendance_Notification: () => {
        
      },
      Makeup_Notification: () => {
        
      },
      Registration: () => {
        
      },
      Fee_Reminder: () => {
        
      },
      Course_AutoStart_Reminder: () => {
        
      },
      Course_AutoTermBreak_Reminder: () => {
        
      },
      Student_Birthday_Alert: () => {
        
      },
      Announcement: () => {
        
      },
    };

    let feature = this.jdp.args.feature || "";
    let feature_id = this.jdp.args.feature_id || -1;

    let link = "";
    if (data.is_new) {
      link = message_data.new_user_url;
    } else {
      link = message_data.existing_user_url;
    }

    let link_maker = link_makers[feature];

    if (link_maker) {
      let madeLink = link_maker();
      if (madeLink) {
        link = madeLink;
      }
    }

    window.location = link;
  }




};

module.exports = _class;
window.register_jdp((require('path')).basename(__filename, ".js"), _class);