"use strict";

var common = require("../common");
var $ = require("jquery");
var moment = require("moment");




var _class = class {
	constructor(jdp) {
		this.jdp = jdp;
		console.log(jdp.args.data);
	}

	get_basic_info() {
		return {
			title_sid: "101:Course Search",
			// record_type_plural: ""
		};
	}


	on_layout() {
	}

	async on_load() {
		let data = await this.jdp.api_user.fetch_data_promise(
			`/video/courses/get_enrollment_types`, null
		);

		this.types = data.types;

		if (!data.show_stage1) {
			document.location = "/video/course_search?stage2=true";
			return;
		}

		console.log(this.types);

		// common.add_elem("p", {}, "", this.jdp.main_elem).html(message.message);

		let message = common.add_elem("p", {}, "explain_msg", this.jdp.main_elem);
		$(message).text(`Please select the type of lessons you would like to attend:`);


		let container = common.add_elem("div", {}, "types_contain", this.jdp.main_elem);

		let is_first = true;

		let btn = null;

		for (let type_i in this.types) {

			let type = this.types[type_i];

			let sub_container = common.add_elem("div", {}, "type_subcontain", container);

			let label = common.add_elem("label", {}, "type_label", sub_container);
			let input = common.add_elem("input", {}, "type_checkbox", sub_container);
			let field_name = "checkbox_" + type.id;
			$(input).attr("type", "radio").val(type.id).attr("id", field_name).attr("name", "type_radios");
			
			if (type.name.toLocaleLowerCase().indexOf("normal") != -1) {
				$(input).attr("checked", "checked");
			}


			$(label).text(type.name).attr("for", field_name);

			$(input).on("change", _ => {
				// this.types[type_i]["_selected"] = $(input).is(":checked");

				let count = $(".type_checkbox:checked").length;
				if (count > 0) {
					$(btn).removeClass("disabled");
				} else {
					$(btn).addClass("disabled");
				}
			});
			// if (input)


			is_first = false;
		}

		btn = common.add_elem("button", {}, "btn btn-lg green-haze btn-next disabled", this.jdp.main_elem).text("Next");
		
		if ($(".type_checkbox:checked").length > 0) {
			$(btn).removeClass("disabled");
		}

		$(btn).on("click", _ => {
			// let selected = this.types.filter(t => t._selected).map(t => t.id);
			let selected = $(".type_checkbox:checked").val();
			let url = "/video/course_search?stage2=true&ents=" + selected;
			console.log(url);
			document.location = url;
		});

		common.add_css(`
			.page-container {
				min-height: calc(100vh - 60px);
			}

			.types_contain {
				//border: solid 1px black;
				margin-bottom: 7px;
			}

			.type_checkbox {
		    float: left;
		    margin-right: 5px !important;
			}

		`);

	}




};

module.exports = _class;
window.register_jdp((require('path')).basename(__filename, ".js"), _class);