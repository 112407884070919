"use strict";

var $ = require("jquery");

function generate_linked_cell(text, href) {
	return $("<td/>").append(
		$("<a/>").attr("href", href).text(
			fix_html_ents(text)
		)
	);
}

module.exports = class MakeupsPage {
	constructor(frontend) {
		this.state = {
			frontend: frontend
		};
	}

	load(cb) {
		const api_user = this.state.frontend.state.api_user;

		api_user.fetch_data("/student/attendance/get_makeup_all", null, function (err, makeups) {
			if (makeups.length < 1) {
				$(".msg-no-results").removeClass("hide");
			} else {
				for (const makeup of makeups) {
					const tr = $("<tr/>");
					var target = "/student/lesson_detail?lesson=" + makeup.madeup_id;
					[
						makeup.skipped_name,
						makeup.skipped_date_fmt,
						makeup.madeup_name,
						makeup.madeup_date_fmt,
						makeup.remark,
						makeup.date_added_fmt
					].forEach(to_add => {
						$(tr).append(generate_linked_cell(to_add, target));
					});

					$("#makeups-table tbody").append(tr);
				}
			}

			api_user.fetch_data("/student/course/get_skippable_lessons_api", null, function (err, skippable) {
				if (skippable && skippable.lessons && skippable.lessons.length > 0) {
					$("#add_makeup_btn").removeClass("hide2");
				}
				cb();
			});
		});
	}
}
