"use strict";

var $ = require("jquery");

var is_node = (require('detect-node'));

if (!is_node) {
	require("bootstrap-datepicker");
}

var common = require("./common");


module.exports = {
	"text": require("./text"),

	"multiline": require("./multiline"),

	"date": require("./date"),

	"dropdown": require("./dropdown"),



};