"use strict";

var $ = require("jquery");

var BootstrapDialog = require("bootstrap3-dialog");

var common = require("../../common");


module.exports = class RequestDocumentPage {

	constructor (frontend) {
		this.state = {
			frontend: frontend
		};
	}

	load (cb) {
		var api_user = this.state.frontend.state.api_user;
		let get_string = this.state.frontend.get_string.bind(this.state.frontend);
		let args = common.get_args();

		let has_finished = false;

		let request_body = JSON.parse(decodeURIComponent(args["body"]));  

		let request = api_user.post_data_promise("/student/document/request_document", request_body);

		request.catch(e => {
			has_finished = true;
			$(".generating").addClass("hide");
			BootstrapDialog.show({
				message: "There was an error requesting this document. Please try again later, or contact an administrator if necessary.",
				title: "Error",
				cssClass: "very-high-zindex",
				type: BootstrapDialog.TYPE_WARNING,
				buttons: [

					{
						label: "Close",
						action: function(dialogItself){
							dialogItself.close();
							window.location = "/student/progress";
						}
					}
				]
			});
		});

		request.then(result => {
			has_finished = true;
			$(".generating").addClass("hide");
			window.location = result.url;
		});

		setTimeout(_ => {
			// 300ms after the request starts and hasn't yet finished, show the waiting text
			if (!has_finished) {
				$(".generating").removeClass("hide");
			}
			
		}, 300);



	}



};