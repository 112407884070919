"use strict";

var common = require("../common");
var $ = require("jquery");
var moment = require("moment");

function _closest (num, arr) {
    var curr = 0;
    var diff = Math.abs (num - arr[curr]);
    for (var val = 0; val < arr.length; val++) {
        var newdiff = Math.abs (num - arr[val]);
        if (newdiff < diff) {
            diff = newdiff;
            curr = val;
        }
    }
    return curr;
}


var _class = class {
	constructor(jdp) {
		this.jdp = jdp;
		this.bookings = [];
		this.sort_asc = false;

		let filter_controls_container = common.add_elem("div", {}, "row", this.jdp.main_elem);

		this.month_select = common.add_elem("select", {}, "form-control");
		this.month_select.append($(`<option/>`).val("").text(nss("103:label_all")));
		common.add_field_group(nss("103:label_month"), this.month_select, filter_controls_container);

	}

	get_basic_info() {
		return {
			title_sid: "101:title_my_bookings",
			record_type_plural: nss("106:record_type_plural_bookings")
		};
	}

	fill_table() {
		$(this.table).find("tbody tr").remove();

		if (this.bookings.rows.length < 1) {
			$(".msg-no-results").removeClass("hide");
			return;
		} else {
			$(".msg-no-results").addClass("hide");
		}

		let filtered = this.bookings.rows;
		if ((this.jdp.args.month) && (this.jdp.args.month != "all")) {
			filtered = filtered.filter(r => {
				// if (!r.Lesson_Date) return false;
				let m = moment(r.Lesson_Date_Raw).format("YYYY-MM");
				return (m == this.jdp.args.month);
			});
		}

		common.build_table(this.table, this.bookings.legend, this.bookings.rows, "cl_id", {
			sort_by_field: "Lesson_Date",
			should_reverse: this.sort_asc,
			is_descending: !this.sort_asc,
			on_sort_order_changed: _=>{
				this.sort_asc = !this.sort_asc;
				this.fill_table();
			}

		});
	}

	on_layout() {

	}

	async on_load() {
		this.bookings = await this.jdp.api_user.fetch_data_promise("/student/package_iec/get_my_bookings", null);

		let months = [];

		for (let entry of this.bookings.rows) {
			if (months.indexOf(entry.Lesson_YearMonth_Preformatted) == -1) {
				months.push(entry.Lesson_YearMonth_Preformatted);
			}
		}

		let selected_month = -1;

		let months_sorted = months.sort();
		console.log(months_sorted);

		if (this.jdp.args.month) {
			if (this.jdp.args.month == "all") {
				selected_month = -2;
			} else {
				selected_month = this.jdp.args.month;
			}
		}
		
		for (let month of months_sorted) {
			let opt = $(`<option/>`).val(month).text(moment(month).format("MMMM YYYY"));
			if (month == selected_month) {
				$(opt).attr("selected", "selected");
			}
			this.month_select.append(opt);
		}

		this.month_select.change(_=>{
			let month = this.month_select.val();
			if (!month) {
				month = "all";
			}
			this.jdp.args["month"] = month;
			window.location = common.build_jdp_url("my_bookings", this.jdp.args);
		});

		this.bookings.rows = this.bookings.rows.filter(b => {
			if (selected_month === -1) return true;
			if (selected_month === -2) return true;
			if (b.Lesson_YearMonth_Preformatted != selected_month) return false;
			return true;
		});

		this.table = common.make_empty_table();
		this.fill_table();

		if (this.jdp.args.highlight) {
			for (let row of $(this.table).find("tbody tr")) {
				if ($(row).attr("data-row-id") == this.jdp.args.highlight) {
					$(row).css({
						// "background-color": "#a6e7dd"
						"border": "solid 2px #a6e7dd"
					});
				}
			}
		}

		this.jdp.main_elem.append(this.table);

		let button = $("<a/>").text(nss("103:btn_my_bookings_make_new")).addClass("btn green-haze").attr("href", common.build_jdp_url("book_sessions"));
		this.jdp.main_elem.append(button);
		common.add_elem("p", `
			margin-top: 10px;
			font-style: italic;
			color: #676767;
		`, "", this.jdp.main_elem).text(nss("106:my_bookings_please_contact"));


	}


};

module.exports = _class;
window.register_jdp((require('path')).basename(__filename, ".js"), _class);
