"use strict";

var uppercase_regex = /[A-Z]/;
var lowercase_regex = /[a-z]/;
var number_regex = /[0-9]/;

var checks = [
	p => p.length >= 6,
	// p => uppercase_regex.test(p),
	// p => lowercase_regex.test(p),
	// p => number_regex.test(p)
];

module.exports = {
	is_password_ok: function (pw) {
		let is_ok = true;

		checks.forEach(c => {
			if (!c(pw)) {
				is_ok = false;
			}
		});

		return is_ok;
	},

	// password_requirement: "Your password must be at least 6 characters long and contain at least one uppercase letter, lowercase letter, and number."
	password_requirement: "Your password must be at least 6 characters long."
};