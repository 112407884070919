"use strict";

// RFC requirement
var email_regex = require("./email_regex");

// Alpha-only in the TLD
var alpha_only_regex = /@.+?\.([a-z]|[A-Z]|\.)+$/;

var checks = [
	e => email_regex.test(e),
	e => alpha_only_regex.test(e)
];

module.exports = {
	is_email_valid: function (email) {
		let is_ok = true;

		checks.forEach(c => {
			if (!c(email)) {
				is_ok = false;
			}
		});

		return is_ok;
	},

	email_error: "That is not considered a valid email address."
};