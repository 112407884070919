var React = require('react');
var moment = require('moment');

class DatePicker extends React.Component {
  constructor(props) {
    super(props);
    const { dayLabel, monthLabel, yearLabel, defaultDate } = props;

    const pad = (n) => {
      return (n < 10 ? '0' + n : n );
    };

    this.state = {
      day: null,
      month: null,
      year: null,
      selectDay: defaultDate ? pad(moment(defaultDate).date()) : (props.mode === 'TH' ? 'วันที่' : dayLabel),
      selectMonth: defaultDate ? moment(defaultDate).month() + 1 : (props.mode === 'TH' ? 'เดือน' :monthLabel),
      selectYear: defaultDate ? moment(defaultDate).year() : (props.mode === 'TH' ? 'ปี' : yearLabel),
    };
    console.log(this.state);
  }

  shouldComponentUpdate(_nextProps, nextState) {
    return this.state.selectDay !== nextState.selectDay || this.state.selectMonth !== nextState.selectMonth || this.state.selectYear !== nextState.selectYear;
  }

  componentWillMount() {
    let day = [], month = [], year = [];

    const pad = (n) => {
      return (n < 10 ? '0' + n : n );
    };

    for (let i=1; i<=31; i++) {
      day.push(this.props.padDay ? pad(i) : i);
    }

    let monthIndex = 1;
    for (const monthName of moment.localeData().months()) {
      month.push({
        text: this.props.useMonthNames ? monthName : this.props.padMonth ? pad(monthIndex) : monthIndex,
        value: monthIndex
      });
      monthIndex++;
    }

    for (let i=this.props.maxYear; i>=this.props.minYear; i--) {
      year.push(i);
    }

    this.setState({
      day: day,
      month: month,
      year: year
    });
  }

  changeDate(e, type) {
    this.setState({
      [type]: e.target.value
    });
    this.checkDate(e.target.value, type);
  }

  getDate(date) {
    if(moment(date).isValid()) {
      return moment(date).format("YYYY-MM-DD");
    } else {
      return "E_INVALID_DATE";
    }
  }

  checkDate(value, type) {
    let { selectDay, selectMonth, selectYear } = this.state;

    if (type === 'selectDay') {
      selectDay = value;
    } else if (type === 'selectMonth') {
      selectMonth = value;
    } else if (type === 'selectYear') {
      selectYear = value;
    }

    if (this.isSelectedAllDropdowns(selectDay, selectMonth, selectYear)) {
      const dateObject = {
        year :selectYear,
        month :selectMonth - 1,
        day :selectDay
      };
      this.props.dateChange(this.getDate(dateObject));
    } else {
      this.props.dateChange(undefined);
    }
  }

  isSelectedAllDropdowns(selectDay, selectMonth, selectYear) {
    if (selectDay === '' || selectMonth === '' || selectYear === '') {
      return false;
    }
    return this.props.mode === 'TH' ?
      selectDay !== 'วันที่' && selectMonth !== 'เดือน' && selectYear !== 'ปี'
      :
      (selectDay !== this.props.dayLabel) && (selectMonth !== this.props.monthLabel) && (selectYear !== this.props.yearLabel);
  }

  render() {
    const dayElement = this.state.day.map((day, id) => {
      return <option value={ day } key={ id }>{ day }</option>;
    });
    const monthElement = this.state.month.map((month, id) => {
      return <option value={ month.value } key={ id }>{ month.text }</option>;
    });
    const yearElement = this.state.year.map((year, id) => {
      return <option value={ year } key={ id }>{ year }</option>;
    });

    // console.log(this.state.selectDay);

    return (
      <div>
        <select style={{display: "inline", width: "auto",}} className={this.props.className} value={this.state.selectDay||""} onChange={(e) => this.changeDate(e, 'selectDay')}>
          <option value="">{this.props.mode === 'TH' ? 'วันที่' : this.props.dayLabel}</option>
          { dayElement }
        </select>
        <select style={{display: "inline", width: "auto", marginLeft: 10,}} className={this.props.className} value={this.state.selectMonth||""} onChange={(e) => this.changeDate(e, 'selectMonth')}>
          <option value="">{this.props.mode === 'TH' ? 'เดือน' : this.props.monthLabel}</option>
          { monthElement }
        </select>
        <select style={{display: "inline", width: "auto", marginLeft: 10,}} className={this.props.className} value={this.state.selectYear||""} onChange={(e) => this.changeDate(e, 'selectYear')}>
          <option value="">{this.props.mode === 'TH' ? 'ปี' : this.props.yearLabel}</option>
          { yearElement }
        </select>
      </div>
    );
  }
}

// DatePicker.propTypes = {
//   className: React.PropTypes.string,
//   dateChange: React.PropTypes.func,
//   dayLabel: React.PropTypes.string,
//   defaultDate: React.PropTypes.string,
//   maxYear: React.PropTypes.number,
//   minYear: React.PropTypes.number,
//   mode: React.PropTypes.string,
//   monthLabel: React.PropTypes.string,
//   padDay: React.PropTypes.bool,
//   padMonth: React.PropTypes.bool,
//   selectDay: React.PropTypes.string,
//   selectMonth: React.PropTypes.string,
//   selectYear: React.PropTypes.string,
//   useMonthNames: React.PropTypes.bool,
//   yearLabel: React.PropTypes.string
// };

DatePicker.defaultProps = {
  dayLabel: 'Day',
  minYear: 1916,
  maxYear: moment().year(),
  monthLabel: 'Month',
  padDay: true,
  padMonth: true,
  selectDay: '',
  selectMonth: '',
  selectYear: '',
  yearLabel: 'Year',
  useMonthNames: true
};

module.exports = DatePicker;