"use strict";

var $ = require("jquery");
var ApiUser = require("../../ApiUser");
var Cookies = require("cookies-js");
var common = require("../../common");

var dateFormat = require('dateformat');

var field_types = require("../../../../../student-views-common/fields/field_types");

var BootstrapDialog = require("bootstrap3-dialog");

var moment = require("moment");

function SearchCoursesPage(frontend) {
	this.state = {
		frontend: frontend
	};
}

function generate_cell(text) {
	return $("<td/>").append(
		$("<span/>").text(
			text
		)
	);
}


function set_results_container_height() {


}

SearchCoursesPage.prototype.generate_linked_cell = function(text, href) {
	let a = $("<a/>").text(text);

	let is_guest = (!this.state.frontend.state.api_user.state.token);
	
	if (is_guest) {
		$(a).attr("href", "#need-login");
		$(a).click(e=>{
			e.preventDefault();
			this.show_need_login_popup();
		});
	} else {
		$(a).attr("href", href);
	}


	return $("<td/>").append(a);
};



SearchCoursesPage.prototype.show_need_login_popup = function(prevent_cancel) {



	// if (this.state.has_shown_message) return;
	this.state.has_shown_message = true;

	let btns_array = [
		{
			label: this.state.frontend.get_string("need_login_btn_login"),
			action: function(dialogItself){
				dialogItself.close();
				window.location = "/login?return=" + encodeURIComponent(document.location.pathname + document.location.search);
			}
		},
		{
			label: this.state.frontend.get_string("need_login_btn_create"),
			action: function(dialogItself) {
				dialogItself.close();
				window.location = "/create_account"
			}
		}
	];

	if (!prevent_cancel) {
		btns_array.push({
			label: this.state.frontend.get_string("cancel"),
			action: function(dialogItself) {
				dialogItself.close();
			}
		});
	}

	BootstrapDialog.show({
		message: this.state.frontend.get_string("need_login"),
		title: this.state.frontend.get_string("need_login_title"),
		cssClass: "very-high-zindex",
		type: BootstrapDialog.TYPE_INFO,
		closable: false,
		buttons: btns_array
	});


};

SearchCoursesPage.prototype.load = function(cb) {
	this.state.has_shown_message = false;

	let hash = document.location.hash.replace("#", "");
	if (hash == "need-login") {
		this.show_need_login_popup(true);
	}

	let get_string = id => this.state.frontend.state.res.course_search.en[id];

	const NO_RESULTS_DUMMY = `
		<tr>
			<td>
				<span>${ get_string("no_results") }</span>
			</td>
		</tr>
		`
	;

	var api_user = this.state.frontend.state.api_user;


	if (Cookies.get("token")) {
		api_user.fetch_data("/video/courses/check_is_student", null, (err, res2) => {
			if (err) {
				// check_is_student is an authed endpoint, so we will assume any error
				// means an auth failure
				$("#must-register").removeClass("hide");
			} else {
				if (res2 === "yes") {

				} else {
					$("#must-register").removeClass("hide");
				}
			}
		});
	}




	$("#quick-access").hide();
	$(".page-content").removeClass("col-sm-8").addClass("col-sm-12");

	// $(".page-content").append($("<a>").attr("href", "/video/course_detail?id=42").text("View test course"));


	var args = common.get_args();

	// let filters = "CourseSearchFilters":["Branch","Level","Subject","LessonStartingFrom","DayOfWeek","EnrollmentType"]
	// let filters = ["Branch","Level","Subject","LessonStartingFrom","DayOfWeek","EnrollmentType"];
	
	// api_user.get

	// api_user.fetch_data("/video/courses/get_filters", null, (err, res) => {

	// });

	let show_ents = args["ents"];
	// if (!show_ents) {
	// 	document.location = "/jdp?page=course_search_ent";
	// 	return;
	// }

	if (typeof show_ents != "undefined") {
		show_ents = show_ents.split(",").map(e => parseInt(e));
	}

	if (!args["stage2"]) {
		document.location = "/jdp?page=course_search_ent";
		return;
	}

	


	api_user.fetch_data("/video/courses/get_search_criteria", null, (err, res) => {
		console.log(res);

		res.forEach(cf => {
			


			if (cf.name == "ent") {

				if (show_ents) {
					let replacement_options = [];
					for (let option_i in cf.options) {
						let _id = cf.options[option_i]["id"];
						if (show_ents.indexOf(_id) != -1) {
							replacement_options.push(cf.options[option_i]);
						}

					}
					cf.options = replacement_options;
				}

			}

			let to_add = field_types[cf.type].render(cf.name, null, cf);

			// If there is only one course type, hide the field.
			// It still gets added because it still needs to be sent.
			if (cf.name == "ent" && cf.options.length < 2) {
				console.log("Few options");
				$(to_add).addClass("hide");
			}

			$("#course-search-form").append(to_add);
		});

		$("[data-field-name=first_lesson_date] .date-picker").val(
			dateFormat(new Date(), "yyyy-mm-dd")
		);

		$("#do-course-search-btn").click(e => {
			e.preventDefault();

			$("#do-course-search-btn").addClass("disabled");
			$("#course-search-results-table tbody").empty();

			let criteria = {};

			$("[data-field-name]").each((_, f) => {
				let field_type = $(f).attr("data-field-type");
				let getter = field_types[field_type].get;
				let val =  getter(f, null);
				if (val !== null && (typeof(val) != "undefined") && val != -1) {
					criteria[$(f).attr("data-field-name")] = val;
				}
				
			});


			let ent = $("[data-field-name=ent] .form-control").val();

			$(".course-search-loading-bg").removeClass("hide");

			// let old_course_search = ((typeof(GLOBAL_USE_OLD_COURSE_SEARCH) != "undefined") ? GLOBAL_USE_OLD_COURSE_SEARCH : null   )

			if (!criteria.start_date) {
				criteria.start_date = moment().format("YYYY-MM-DD");
			}

			api_user.post_data(
				// (  old_course_search ? "/video/courses/find_courses" : "/video/courses/find_courses_new")
				// args["guest"] ? "/video/courses/find_courses_new" : "/video/courses/find_courses_new_authed"
				"/video/courses/find_courses_new"
				,
				criteria,
				(err, res) => {
					$("#course-search-results-container").removeClass("hide");
					$(".course-search-loading-bg").addClass("hide");
					console.log(res);
					$("#do-course-search-btn").removeClass("disabled");
					if (!res || res.rows.length < 1) {
						$("#course-search-results-table tbody").append(NO_RESULTS_DUMMY);
					} else {

						let ents_which_ignore_vacancies = [2,3];
						console.log("ENT: " + ent);
						let ignore_vacancies = (ents_which_ignore_vacancies.indexOf(parseInt(ent)) != -1);
						console.log("Ignore vacancies? " + ignore_vacancies);

						console.log(res.length + " courses found");


						res.rows.forEach(course => {

							let vacancies_value = "";
							if (ignore_vacancies) {
								vacancies_value = " - ";
							} else if (course.vacancies < 0) {
								vacancies_value = "Available";
							} else {
								vacancies_value = course.vacancies;
							}

							course.vacancies = vacancies_value;


							let url = "/video/course_enroll?id=" + course.cou_id + "&ent=" + ent + "&date=" + criteria.start_date;
							course._row_link_url = url;


						});

						common.build_table($("#course-search-results-table"), res.legend, res.rows);

					}
				}

			);	

		});

		cb();

	});






};

module.exports = SearchCoursesPage;
