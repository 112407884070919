"use strict";

var $ = require("jquery");
var ApiUser = require("../../ApiUser");
var Cookies = require("cookies-js");
var common = require("../../common");

function ViewCoursePage(frontend) {
	this.state = {
		frontend: frontend
	};
}


ViewCoursePage.prototype.load = function(cb) {
	var api_user = this.state.frontend.state.api_user;

	$("#quick-access").hide();
	$(".page-content").removeClass("col-sm-8").addClass("col-sm-12");

	var args = common.get_args();
	let cou_id = args["id"];
	
	api_user.fetch_data("/video/courses/get_course_info/" + cou_id, null, function(err, res) {
		console.log(res);

		cb();
	});


};

module.exports = ViewCoursePage;
