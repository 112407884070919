"use strict";

// Polyfill is in a seperate JS file for some obscure reasons.
// require("babel-polyfill");

var $ = require("jquery");
var jQuery = $;

// Expose jQuery. Not needed, but useful for using Chrome devtools and similar.
window.jQuery = jQuery;
window.$ = $;

// Hack for IE crossdomain.
if ( window.XDomainRequest ) {
	jQuery.ajaxTransport(function( s ) {
		if ( s.crossDomain && s.async ) {
			if ( s.timeout ) {
				s.xdrTimeout = s.timeout;
				delete s.timeout;
			}
			var xdr;
			return {
				send: function( _, complete ) {
					function callback( status, statusText, responses, responseHeaders ) {
						xdr.onload = xdr.onerror = xdr.ontimeout = jQuery.noop;
						xdr = undefined;
						complete( status, statusText, responses, responseHeaders );
					}
					xdr = new XDomainRequest();
					xdr.onload = function() {
						callback( 200, "OK", { text: xdr.responseText }, "Content-Type: " + xdr.contentType );
					};
					xdr.onerror = function() {
						callback( 404, "Not Found" );
					};
					xdr.onprogress = jQuery.noop;
					xdr.ontimeout = function() {
						callback( 0, "timeout" );
					};
					xdr.timeout = s.xdrTimeout || Number.MAX_VALUE;
					xdr.open( s.type, s.url );
					xdr.send( ( s.hasContent && s.data ) || null );
				},
				abort: function() {
					if ( xdr ) {
						xdr.onerror = jQuery.noop;
						xdr.abort();
					}
				}
			};
		}
	});
}

jQuery.support.cors = true;
// End of IE crossdomain hack.

$(document).ready(function() {     

});


var MetronicFrontend = require("./MetronicFrontend");
var frontend = new MetronicFrontend();


window["fix_html_ents"] = function(str) {
	if (typeof str != "string") {
		console.log("Warning: fix_html_ents was passed:");
		console.log(str);
		str = "";
	}
	return str.replace(/&amp;/g, '&')
		        .replace(/&gt;/g, '>')
		        .replace(/&lt;/g, '<')
};

(async _ => {
	// stage 1 is the constructor
	await frontend.init_stage_2();
	frontend.init_stage_3();
})();