"use strict";
var React = require("react");
var DatePicker = require("./DatePicker");

var DropDownField = require("./DropDownField");
var TextField = require("./TextField");
var DateField = require("./DateField");

function exists(thing) {
  return (thing !== null) && (typeof(thing) != "undefined");
}

module.exports =  class Field extends React.Component {
  constructor(props) {
    super(props);
  }

  _getActualField() {
    const value = this.props.currentValues[this.props.fieldKey];
    const _default = this.props.defaultValues[this.props.fieldKey];
    switch (this.props.fieldConfig.type) {
      case "DropDown": {
        return <DropDownField
          fieldKey={this.props.fieldKey}
          value={exists(value) ? value : _default}
          dropdown_options={this.props.fieldConfig.dropdown_options}
          error={this.props.error}
          onChange={newVal => this.props.onChange(newVal)}
        />
        break;
      }

      case "Line":
      case "MultiLine":
      {
        return <TextField
          fieldKey={this.props.fieldKey}
          value={exists(value) ? value : _default}
          multi={this.props.fieldConfig.type == "MultiLine"}
          error={this.props.error}
          onChange={newVal => this.props.onChange(newVal)}
        />;
        break;
      }
      case "Date":
        return <DateField
          fieldKey={this.props.fieldKey}
          value={exists(value) ? value : _default}
          error={this.props.error}
          onChange={newVal => this.props.onChange(newVal)}
        />
      default: return null;
    }
  }

  render() {

      // <input className="form-control" id={this.props.fieldKey} value={this.props.value} placeholder={this.props.fieldConfig.Label} onChange={ev => this.props.onChange(ev.target.value)} />
      // {this.props.error && <p style={{color: "red"}}>{this.props.error}</p>}

    return <div className="form-group" style={{
      paddingLeft: 10,
      // backgroundColor: "yellow",
    }}>
      <label className="control-label" htmlFor={this.props.fieldKey} style={{paddingLeft: 12}}>
        {this.props.fieldConfig.required && <span style={{color: "red", position: "absolute", marginLeft: -12, marginTop: 2}}>*</span>}
        {this.props.fieldConfig.label}
      </label>
      { this._getActualField() }
    </div>
  }
}