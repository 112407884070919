"use strict";

var $ = require("jquery");
var common = require("../../common");
// var b64 = require("urlsafe-base64");

var BootstrapDialog = require("bootstrap3-dialog");

var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

function div_with_class(cls) {
	return $("<div/>").addClass(cls);
}

var DataGrouper = (function() {
    var has = function(obj, target) {
        return _.any(obj, function(value) {
            return _.isEqual(value, target);
        });
    };

    var keys = function(data, names) {
        return _.reduce(data, function(memo, item) {
            var key = _.pick(item, names);
            if (!has(memo, key)) {
                memo.push(key);
            }
            return memo;
        }, []);
    };

    var group = function(data, names) {
        var stems = keys(data, names);
        return _.map(stems, function(stem) {
            return {
                key: stem,
                vals:_.map(_.where(data, stem), function(item) {
                    return _.omit(item, names);
                })
            };
        });
    };

    group.register = function(name, converter) {
        return group[name] = function(data, names) {
            return _.map(group(data, names), converter);
        };
    };

    return group;
}());

function group_by(arr, prop) {
	let results = {};

	for (let e of arr) {
		let the_val = e[prop];
		if (typeof results[the_val] == "undefined") {
			results[the_val]  = [];
		}
		results[the_val].push(e);
	}

	return results;



}


module.exports = class PhotosPage {
	constructor(frontend) {
		this.state = {
			frontend: frontend
		};
	}

	load(cb) {
		let args = common.get_args();
		this.state.mode = args.mode || "level1";
		let api_user = this.state.frontend.state.api_user;

		api_user.fetch_data("/student/photos/get_all_photos", null, (err, videos) => {

			console.log(videos);

			if (this.state.mode == "level1") {
				let items_count = 0;
				for (let product_type of videos.product_types) {
					for (let product of product_type.products) {
						items_count += product.contents.length;
					}
				}

				if (items_count < videos.dont_group_if_total_under) {
					let all_contents = [];

					for (let product_type of videos.product_types) {
						for (let product of product_type.products) {
							for (let content of product.contents) {
								all_contents.push(content);
							}
						}
					}

					if (all_contents.length < 1) {
						$(".msg-no-results").removeClass("hide");
						if (cb) {
							cb();
						}
						return;
					}

					all_contents.sort((a,b) => {  a.datetime < b.datetime ? -1 : 1 });
					// console.log(all_contents.map(c => c.datetime))

					let product_contents_div = div_with_class("product-contents").css("margin-top", "20px");

					for (let content of all_contents) {
						this.add_product_content(content, product_contents_div, null);
					}

					$(".page-content").append(product_contents_div);
					// $(".page-title").removeClass("hide");

				} else {
					this.add_product_types(videos.product_types);
				}

				

			} else if (this.state.mode == "level2") {

				let the_product = videos.product_types.filter(t => t.id == args.level2_type)[0].products.filter(p => p.id == args.level2_id)[0];
				console.log(the_product);

				if (!the_product || !the_product.info) {
					$(".msg-no-results").removeClass("hide");
					if (cb) {
						cb();
					}
					return;
				}

				let product_info_div = $("<div/>");

				for (let info of the_product.info) {
					let to_append = $("<span/>").addClass("product-info").text(info);
					product_info_div.append(to_append);
				}

				$(".page-content").append(product_info_div);

				let product_contents_div = div_with_class("product-contents").css("margin-top", "20px");


				let grouped = group_by(the_product.contents, "month");
				let num_groups = 0;
				for (let _ in grouped) { num_groups++; }
				console.log(num_groups);

				if (num_groups < videos.dont_group_if_total_under ){//|| the_product.contents.length < (videos.dont_group_videos_if_total_under)) {
					// If there are few enough, just render them now- done
					if (args.level2_type == "course") {
						the_product.contents = the_product.contents.reverse();
					}

					if (the_product.contents.length < 1) {
						$(".msg-no-results").removeClass("hide");
						if (cb) {
							cb();
						}
						return;
					}


					
					for (let content of the_product.contents) {
						this.add_product_content(content, product_contents_div, null);
					}
				} else {
					// More than that
					
					if (grouped.length < 1) {
						$(".msg-no-results").removeClass("hide");
						if (cb) {
							cb();
						}
						return;
					}


					for (let grouped_i in grouped) {
						let grouped_i_split = grouped_i.split(",");
						let month_i = parseInt(grouped_i_split[0]);
						let month_name = grouped_i_split[1];
						//let grouped_v = grouped[grouped_i];

						// this.add_product_content(content, product_contents_div, "#asdf");

						let target = "?mode=level3&level2_type=" + args.level2_type + "&level2_id=" + args.level2_id + "&level3_index=" + month_i;

						product_contents_div.append($("<a/>").addClass("month videos-product videos-product-with-icon").attr("href", target).text(month_name));
						product_contents_div.append($("<br/>"));

						// for (let content of grouped_v) {
						// 	this.add_product_content(content, product_contents_div, false);
						// }

					}

				}



				$(".page-content").append(product_contents_div);


				// this.add_product_types(videos.product_types);

			} else if (this.state.mode == "level3") {


				let the_product = videos.product_types.filter(t => t.id == args.level2_type)[0].products.filter(p => p.id == args.level2_id)[0];
				console.log(the_product);

				let product_info_div = $("<div/>");

				for (let info of the_product.info) {
					let to_append = $("<span/>").addClass("product-info").text(info);
					product_info_div.append(to_append);
				}

				$(".page-content").append(product_info_div);

				$(".page-content").append($("<h4/>").text(months[args.level3_index]));

				let product_contents_div = div_with_class("product-contents").css("margin-top", "10px");

				let the_product_contents = the_product.contents.filter(c => parseInt(c.month.split(',')[0]) == args.level3_index);

				if (args.level2_type == "course") {
					the_product_contents = the_product_contents.reverse();
				}
				
					if (the_product_contents.length < 1) {
						$(".msg-no-results").removeClass("hide");
						if (cb) {
							cb();
						}
						return;
					}


				for (let content of the_product_contents) {
					this.add_product_content(content, product_contents_div, null);
				}

				$(".page-content").append(product_contents_div);

				// console.log(the_product_contents);


			} 

			$(".full-screen-exit").click(e => {
				e.preventDefault();
				$(".full-screen-image").css("background-image", "none");
				$(".full-screen-blackout").css("display", "none");
				$(".full-screen-info").empty();
			});

			

			cb();
		});
	}


	add_product_types(product_types) {
		for (let product_type of product_types) {

			let products_section_container_div = div_with_class("products-section-container");
			// products_section_container_div.append($("<h1/>").text(product_type.name));

			let products_section_div = div_with_class("products-section");
			let videos_product_container_div = div_with_class("videos-product-container")

			this.add_products(product_type.products, videos_product_container_div);

			products_section_div.append(videos_product_container_div);
			products_section_container_div.append(products_section_div);
			$(".page-content").append(products_section_container_div);
		}
	}

	add_products(products, container) {



		for (let product of products) {


			let should_add_level1_video_links = (this.state.mode == "level1");


			let videos_product_div = div_with_class("videos-product");

			if (should_add_level1_video_links) {
				let target = "?mode=level2&level2_type=" + product.type + "&level2_id=" + product.id;
				videos_product_div = $("<a/>").attr("href", target).addClass("videos-product");
			}

			if (should_add_level1_video_links) {
				$(videos_product_div).addClass("videos-product-with-icon");
			}

			for (let info of product.info) {
				let to_append = $("<span/>").addClass("product-info").text(info);
				if (should_add_level1_video_links) {

					

					// to_append = $("<a/>").attr("href", target).append(to_append);
				}
				videos_product_div.append(to_append);
			}

			let product_contents_div = div_with_class("product-contents");

			if (this.state.mode == "level1") {

				// Don't add contents

			} else if (this.state.mode == "level2") {
				for (let content of product.contents) {
					this.add_product_content(content, product_contents_div);
				}


			} else if (this.state.mode == "level3") {

				for (let content of product.contents) {
					this.add_product_content(content, product_contents_div);
				}

			} else {
				console.log("illegal mode: " + this.state.mode);
			}



			videos_product_div.append(product_contents_div);
			container.append(videos_product_div);
		}



	}

	add_product_content(content, container, override_target) {
		let product_content_div = div_with_class("product-content");

		let text = ( content.type == "course_lesson" ) ? (
			content.name.split(" ")[0] + " " + (content.time || "")
		) : content.name;


		let target = null;
		let thumb = null;

		if (override_target) {
			target = override_target;
		} else {
			target = this.state.frontend.state.api_base + "/student/course/download_course_attachment?clid=" + content.lesson_id + "&file_id=" + content.attachment_id + "&inline=true";
		}

		let id_b64 = btoa(""+content.attachment_id).replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
		thumb = target;//this.state.frontend.state.api_base + "/student/course/download_thumbnail?clid=" + content.lesson_id + "&id=" + id_b64;


		let a = $("<a/>").attr("href", target);//.text(text)
		let img = $("<img/>").attr("alt", "").attr("src", thumb);
		$(a).append(img);
		$(a).append($("<span/>").text(content.name));
		$(a).click(function(e) {
			e.preventDefault();

			let cont_div = $("<div/>").addClass("photo-popup-contain");
			let img_div = $("<div/>").addClass("photo-popup-image").css("background-image", "url(" + thumb  + ")");

			let loading_spinner = $("<img/>").attr("alt", "Loading").attr("src", "/static/img/ajax-loading.gif");
			$(img_div).append(loading_spinner);

			let full_bg_image = new Image();

			let info_div = $("<div/>").addClass("photo-popup-info");
			for (let detail of content.details) {
				let detail_dom = $("<p/>").text(detail);
				$(info_div).append(detail_dom);
				// $(".full-screen-info").append(detail_dom);
			}

			$(cont_div).append(img_div);
			$(cont_div).append(info_div);

			let dlg = BootstrapDialog.show({
				message: cont_div,
				title: content.name,
				cssClass: "very-high-zindex",
				type: BootstrapDialog.TYPE_INFO,
				buttons: [

					{
						label: "Full Screen",
						id: "full-screen-btn",
						action: function(dialogItself){
							dialogItself.close();
							
							$(".full-screen-image").css("background-image", "url(" + target  + ")");
							$(".full-screen-blackout").css("display", "flex");

							$(".full-screen-info").empty();
							for (let detail of content.details) {
								let detail_dom = $("<p/>").text(detail);
								$(info_div).append(detail_dom);
								$(".full-screen-info").append(detail_dom);
							}


							// $(".modal-dialog").addClass("modal-dialog-full");
							// $(this).hide();
						}
					},

					{
						label: "Close",
						action: function(dialogItself){
							dialogItself.close();
						}
					}
				]
			});
			dlg.getButton("full-screen-btn").disable();

			full_bg_image.onload = _ => {
				loading_spinner.hide();
				img_div.css("background-image", "url(" + target  + ")");
				dlg.getButton("full-screen-btn").enable();
			};
			full_bg_image.src = target;


			// $(img_div).css("background-image", "url(" + target  + ")");

		
		});
		product_content_div.append(a);


		container.append(product_content_div);
	}






};